import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Natural number */
  Count: number;
  /** Datetime, in ISO-8601 format */
  Time: Date;
};

export type ActiveTagGroup = {
  __typename?: 'ActiveTagGroup';
  tagGroup: TagGroup;
  tagGroupId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
  tags: Array<Tag>;
};

export type ActiveTagGroupInput = {
  tagGroupId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};

export type AdminLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AdminLoginResult = AdminLoginSuccess | LoginError;

export type AdminLoginSuccess = {
  __typename?: 'AdminLoginSuccess';
  adminUser: AdminUser;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['Time']>;
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type Allergen = {
  __typename?: 'Allergen';
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};

export type AllergenInput = {
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type AllergenList = {
  __typename?: 'AllergenList';
  items: Array<Allergen>;
  total: Scalars['Count'];
};

export type ContentBlock = {
  __typename?: 'ContentBlock';
  content?: Maybe<Scalars['String']>;
  kind: ContentKindEnum;
  meta?: Maybe<ContentMeta>;
  name: Scalars['String'];
  seasonFrom?: Maybe<Scalars['String']>;
  seasonTo?: Maybe<Scalars['String']>;
};

export type ContentBlockInput = {
  content?: InputMaybe<Scalars['String']>;
  kind: ContentKindEnum;
  meta?: InputMaybe<ContentMetaInput>;
  name: Scalars['String'];
  seasonFrom?: InputMaybe<Scalars['String']>;
  seasonTo?: InputMaybe<Scalars['String']>;
};

export enum ContentKindEnum {
  Season = 'SEASON',
  Simple = 'SIMPLE'
}

export type ContentMeta = {
  __typename?: 'ContentMeta';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContentMetaInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CookingTime = {
  __typename?: 'CookingTime';
  minutesMax?: Maybe<Scalars['Float']>;
  minutesMin?: Maybe<Scalars['Float']>;
};

export type CookingTimeInput = {
  minutesMax?: InputMaybe<Scalars['Float']>;
  minutesMin?: InputMaybe<Scalars['Float']>;
};

export enum DirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  /** App version. May contain first 6 char of released mobile app version, or a semver. May show that there is a newer mobile app version. */
  appLatestVersion: Scalars['String'];
  /** Server version. May contain first 6 char of release git version. */
  backendVersion: Scalars['String'];
  /** Determines whether some debug options are turned on. Should not provide any extra confidental data. */
  debugEnabled: Scalars['Boolean'];
  /** Information email for app. Currently info@greenfridge.hu */
  email: Scalars['String'];
  /** dev/prod version string for server. */
  environment: Scalars['String'];
  /** Static content pages for mobile app. Like FAQ, ToC. */
  staticContent: StaticContent;
};

export type GroupIngredient = {
  __typename?: 'GroupIngredient';
  key: Scalars['Int'];
  name: Scalars['String'];
  subgroups: Array<GroupSublist>;
};

export type GroupIngredientInput = {
  key: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  subgroups?: InputMaybe<Array<GroupSublistInput>>;
};

export type GroupSublist = {
  __typename?: 'GroupSublist';
  ingredients: Array<RecipeIngredient>;
  key: Scalars['Int'];
  media: Array<Media>;
  name: Scalars['String'];
  tagGroups: Array<ActiveTagGroup>;
};


export type GroupSublistMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type GroupSublistInput = {
  ingredients?: InputMaybe<Array<RecipeIngredientInput>>;
  key: Scalars['Int'];
  media?: InputMaybe<Array<MediaInput>>;
  name?: InputMaybe<Scalars['String']>;
  tagGroups?: InputMaybe<Array<ActiveTagGroupInput>>;
};

export type Ingredient = {
  __typename?: 'Ingredient';
  allergens: Array<Allergen>;
  availableShoppingUnits: Array<Unit>;
  calculatedStats: IngredientStats;
  contentBlocks: Array<ContentBlock>;
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  defaultRecipeUnit: Unit;
  defaultRecipeUnitId: Scalars['ID'];
  defaultShoppingUnit: Unit;
  defaultShoppingUnitId: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Time']>;
  displayOrder: Scalars['Float'];
  group: IngredientGroup;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  ignoreOrder: Scalars['Float'];
  implicitlyAvailable: Scalars['Boolean'];
  initialIngredient: Scalars['Boolean'];
  media: Array<Media>;
  name: Scalars['String'];
  pricePerGram?: Maybe<Scalars['Float']>;
  priceSpread?: Maybe<Scalars['Float']>;
  unitQuantities: Array<UnitQuantity>;
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
  visible: Scalars['Boolean'];
};


export type IngredientMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type IngredientGroup = {
  __typename?: 'IngredientGroup';
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  displayOrder: Scalars['Float'];
  id: Scalars['ID'];
  ingredients: Array<Ingredient>;
  media: Array<Media>;
  name: Scalars['String'];
  parent?: Maybe<IngredientGroup>;
  parentId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};


export type IngredientGroupMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type IngredientGroupInput = {
  displayOrder?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
};

export type IngredientGroupList = {
  __typename?: 'IngredientGroupList';
  items: Array<IngredientGroup>;
  total: Scalars['Count'];
};

export type IngredientInput = {
  allergens: Array<Scalars['ID']>;
  availableShoppingUnitIds: Array<Scalars['ID']>;
  calculatedStats: IngredientStatsInput;
  contentBlocks: Array<ContentBlockInput>;
  defaultRecipeUnitId: Scalars['ID'];
  defaultShoppingUnitId: Scalars['ID'];
  groupId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  implicitlyAvailable: Scalars['Boolean'];
  initialIngredient: Scalars['Boolean'];
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String'];
  pricePerGram?: InputMaybe<Scalars['Float']>;
  priceSpread?: InputMaybe<Scalars['Float']>;
  unitQuantities: Array<UnitQuantityInput>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type IngredientKeyValueFloatsInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['Float']>;
};

export type IngredientList = {
  __typename?: 'IngredientList';
  items: Array<Ingredient>;
  total: Scalars['Count'];
};

/** Statistics for a given ingredient */
export type IngredientStats = {
  __typename?: 'IngredientStats';
  /** g/100g */
  carbon?: Maybe<Scalars['Float']>;
  /** Measured in kjoul/100g */
  energy?: Maybe<Scalars['Float']>;
  /** g/100g */
  fat?: Maybe<Scalars['Float']>;
  /** Extra ingredient stats, like vitamins. measured in g/100g. */
  other?: Maybe<Array<KeyValueFloats>>;
  /** g/100g */
  protein?: Maybe<Scalars['Float']>;
};

export type IngredientStatsInput = {
  carbon?: InputMaybe<Scalars['Float']>;
  energy?: InputMaybe<Scalars['Float']>;
  fat?: InputMaybe<Scalars['Float']>;
  other?: InputMaybe<Array<IngredientKeyValueFloatsInput>>;
  protein?: InputMaybe<Scalars['Float']>;
};

export type IntoleranceGroup = {
  __typename?: 'IntoleranceGroup';
  allergens: Array<Allergen>;
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
  visible: Scalars['Boolean'];
};

export type IntoleranceGroupInput = {
  allergens: Array<Scalars['ID']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  visible: Scalars['Boolean'];
};

export type IntoleranceGroupList = {
  __typename?: 'IntoleranceGroupList';
  items: Array<IntoleranceGroup>;
  total: Scalars['Count'];
};

export type KeyValueFloats = {
  __typename?: 'KeyValueFloats';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type LoginError = {
  __typename?: 'LoginError';
  error: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  /**  original filename (not used for mobile)  */
  name: Scalars['String'];
  /**  size in bytes (not used for mobile)  */
  size: Scalars['Int'];
  /**  extension (not used for mobile)  */
  type: Scalars['String'];
  /**  reference to use in regarding to aws s3 (not used for mobile)  */
  uid: Scalars['String'];
  /**  url/path to access the file. Should dynamically change from MediaQueryInput.  */
  url: Scalars['String'];
};

export type MediaInput = {
  /** base64 encoded payload can be used to change/upload a new media. */
  base64?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
  /** url/thumb can be provided to not change/upload a media. */
  uid: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type MediaQueryInput = {
  /**
   * resize to this height.
   * 0 = auto/default.
   * if only height/width are provided, it resizes by keeping the aspect ratio.
   * if both height and width are provided, it resizes with `cover`.
   */
  height?: InputMaybe<Scalars['Int']>;
  /**  0 = all entries  */
  limit?: InputMaybe<Scalars['Int']>;
  /**  0 = first page  */
  page?: InputMaybe<Scalars['Int']>;
  /**
   * resize to this width.
   * 0 = auto/default.
   * if only height/width are provided, it resizes by keeping the aspect ratio.
   * if both height and width are provided, it resizes with `cover`.
   */
  width?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  admin_allergen_delete: Scalars['Boolean'];
  admin_allergen_upsert: Allergen;
  admin_ingredient_delete: Scalars['Boolean'];
  admin_ingredient_group_delete: Scalars['Boolean'];
  admin_ingredient_group_upsert: IngredientGroup;
  admin_ingredient_upsert: Ingredient;
  admin_intolerance_group_delete: Scalars['Boolean'];
  admin_intolerance_group_upsert: IntoleranceGroup;
  /** Logs in an admin with email+password. */
  admin_login: AdminLoginResult;
  /** Logs out current admin user. */
  admin_logout: Scalars['Boolean'];
  admin_recipe_delete: Scalars['Boolean'];
  admin_recipe_suggestion_delete: Scalars['Boolean'];
  admin_recipe_suggestion_upsert: RecipeSuggestion;
  /**  Update all recipe's generated versions. Called mainly manually. */
  admin_recipe_update_all: Scalars['Boolean'];
  admin_recipe_upsert: Recipe;
  admin_substitution_group_delete: Scalars['Boolean'];
  admin_substitution_group_upsert: SubstitutionGroup;
  admin_tag_delete: Scalars['Boolean'];
  admin_tag_group_delete: Scalars['Boolean'];
  admin_tag_group_upsert: TagGroup;
  admin_tag_upsert: Tag;
  admin_unit_delete: Scalars['Boolean'];
  admin_unit_upsert: Unit;
  /** DONT IMPLEMENT */
  dummyMutation?: Maybe<Scalars['Int']>;
};


export type MutationAdmin_Allergen_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Allergen_UpsertArgs = {
  input: AllergenInput;
};


export type MutationAdmin_Ingredient_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Ingredient_Group_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Ingredient_Group_UpsertArgs = {
  input: IngredientGroupInput;
};


export type MutationAdmin_Ingredient_UpsertArgs = {
  input: IngredientInput;
};


export type MutationAdmin_Intolerance_Group_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Intolerance_Group_UpsertArgs = {
  input: IntoleranceGroupInput;
};


export type MutationAdmin_LoginArgs = {
  input: AdminLoginInput;
};


export type MutationAdmin_Recipe_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Recipe_Suggestion_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Recipe_Suggestion_UpsertArgs = {
  input: RecipeSuggestionInput;
};


export type MutationAdmin_Recipe_UpsertArgs = {
  input: RecipeInput;
};


export type MutationAdmin_Substitution_Group_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Substitution_Group_UpsertArgs = {
  input: SubstitutionGroupInput;
};


export type MutationAdmin_Tag_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Tag_Group_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Tag_Group_UpsertArgs = {
  input: TagGroupInput;
};


export type MutationAdmin_Tag_UpsertArgs = {
  input: TagInput;
};


export type MutationAdmin_Unit_DeleteArgs = {
  id: Scalars['ID'];
};


export type MutationAdmin_Unit_UpsertArgs = {
  input: UnitInput;
};

export type OauthInitResult = {
  __typename?: 'OauthInitResult';
  appId: Scalars['String'];
  clientId: Scalars['String'];
  redirect: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  admin_allergen_get: Allergen;
  admin_allergen_list: AllergenList;
  /** Currently authenticated and authorized administrator user. Usable for displaying current profile. */
  admin_get_user: AdminUser;
  admin_ingredient_get: Ingredient;
  admin_ingredient_group_get: IngredientGroup;
  admin_ingredient_group_list: IngredientGroupList;
  admin_ingredient_list: IngredientList;
  admin_intolerance_group_get: IntoleranceGroup;
  admin_intolerance_group_list: IntoleranceGroupList;
  admin_recipe_get: Recipe;
  admin_recipe_list: RecipeList;
  admin_recipe_suggestion_get: RecipeSuggestion;
  admin_recipe_suggestion_list: RecipeSuggestionList;
  admin_substitution_group_get: SubstitutionGroup;
  admin_substitution_group_list: SubstitutionGroupList;
  admin_tag_get: Tag;
  admin_tag_group_get: TagGroup;
  admin_tag_group_list: TagGroupList;
  admin_tag_list: TagList;
  admin_unit_get: Unit;
  admin_unit_list: UnitList;
  /**
   * System-wide global settings. These are used to configure the mobile application, and even maybe the admin backend.
   * There shouldn't be any secret listed here, as it's available without authentication to anyone.
   */
  get_global_settings: GlobalSettings;
};


export type QueryAdmin_Allergen_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Allergen_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Ingredient_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Ingredient_Group_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Ingredient_Group_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Ingredient_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Intolerance_Group_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Intolerance_Group_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Recipe_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Recipe_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Recipe_Suggestion_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Recipe_Suggestion_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Substitution_Group_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Substitution_Group_ListArgs = {
  forIngredient?: InputMaybe<Scalars['ID']>;
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Tag_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Tag_Group_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Tag_Group_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Tag_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};


export type QueryAdmin_Unit_GetArgs = {
  id: Scalars['ID'];
};


export type QueryAdmin_Unit_ListArgs = {
  tableQuery?: InputMaybe<TableQuery>;
};

export type Recipe = {
  __typename?: 'Recipe';
  activeFrom?: Maybe<Scalars['Time']>;
  contentBlocks: Array<ContentBlock>;
  cookingTime: CookingTime;
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  dynamicIngredients: Array<GroupIngredient>;
  id: Scalars['ID'];
  ingredients: Array<RecipeIngredient>;
  isActive: Scalars['Boolean'];
  media: Array<Media>;
  name: Scalars['String'];
  nameDynamic: Scalars['String'];
  steps: Scalars['String'];
  tagGroups: Array<ActiveTagGroup>;
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};


export type RecipeMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  ingredient: Ingredient;
  ingredientId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  substitutions: Array<RecipeIngredientSubstitution>;
  unit: Unit;
  unitId: Scalars['ID'];
};

export type RecipeIngredientInput = {
  ingredientId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  substitutions?: InputMaybe<Array<RecipeIngredientSubstitutionInput>>;
  unitId: Scalars['ID'];
};

export type RecipeIngredientSubstitution = {
  __typename?: 'RecipeIngredientSubstitution';
  ingredient: Ingredient;
  ingredientId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit: Unit;
  unitId: Scalars['ID'];
};

export type RecipeIngredientSubstitutionInput = {
  ingredientId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitId: Scalars['ID'];
};

export type RecipeInput = {
  activeFrom?: InputMaybe<Scalars['Time']>;
  contentBlocks: Array<ContentBlockInput>;
  cookingTime: CookingTimeInput;
  dynamicIngredients: Array<GroupIngredientInput>;
  id?: InputMaybe<Scalars['ID']>;
  ingredients: Array<RecipeIngredientInput>;
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String'];
  nameDynamic: Scalars['String'];
  steps: Scalars['String'];
  tagGroups: Array<ActiveTagGroupInput>;
};

export type RecipeList = {
  __typename?: 'RecipeList';
  items: Array<Recipe>;
  total: Scalars['Count'];
};

export type RecipeSuggestion = {
  __typename?: 'RecipeSuggestion';
  activeRule?: Maybe<Scalars['String']>;
  allergens: Array<Allergen>;
  cachedActiveFrom?: Maybe<Scalars['Time']>;
  cachedActiveTo?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  media: Array<Media>;
  name: Scalars['String'];
  recipes: Array<Recipe>;
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};


export type RecipeSuggestionMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type RecipeSuggestionInput = {
  activeRule: Scalars['String'];
  allergens: Array<Scalars['ID']>;
  cachedActiveFrom?: InputMaybe<Scalars['Time']>;
  cachedActiveTo?: InputMaybe<Scalars['Time']>;
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String'];
  recipeIds: Array<Scalars['ID']>;
};

export type RecipeSuggestionList = {
  __typename?: 'RecipeSuggestionList';
  items: Array<RecipeSuggestion>;
  total: Scalars['Count'];
};

export type SortInput = {
  column: Scalars['String'];
  direction?: InputMaybe<DirectionEnum>;
};

export type Static = {
  __typename?: 'Static';
  /** Title of static page, like FAQ. */
  name: Scalars['String'];
  /** Type of static page, like privacy_policy,terms_and_conditions */
  type: Scalars['String'];
  /** URL for static page, like https://example.com */
  url: Scalars['String'];
};

export type StaticContent = {
  __typename?: 'StaticContent';
  privacyPolicy: Scalars['String'];
  termsAndConditions: Scalars['String'];
};

export type SubstitutionGroup = {
  __typename?: 'SubstitutionGroup';
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  ingredients: Array<Ingredient>;
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};

export type SubstitutionGroupInput = {
  id?: InputMaybe<Scalars['ID']>;
  ingredientIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type SubstitutionGroupList = {
  __typename?: 'SubstitutionGroupList';
  items: Array<SubstitutionGroup>;
  total: Scalars['Count'];
};

/** General query for sorting on listing endpoints */
export type TableQuery = {
  /**
   * 0 = unlimited
   * non-negative.
   */
  limit?: InputMaybe<Scalars['Int']>;
  /** Current page for pagination. 0=first `limit` items. 1=second `limit` items. must be non-negative. */
  page?: InputMaybe<Scalars['Int']>;
  /**
   * Free text, entity-specific search (ie. in name, description).
   * case insensitive. may ignore accents, special chars. subtext search. (x ILIKE %val%)
   */
  search?: InputMaybe<Scalars['String']>;
  /** Sort columns. Should be defined in gomodels. Must throw an error for sorting by unsortable column. */
  sort?: InputMaybe<Array<SortInput>>;
  /** Show deleted/trashed items too. */
  withTrashed?: InputMaybe<Scalars['Boolean']>;
};

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  displayOrder?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  media: Array<Media>;
  name: Scalars['String'];
  tagGroup: TagGroup;
  tagGroupId: Scalars['ID'];
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};


export type TagMediaArgs = {
  mediaQuery?: InputMaybe<MediaQueryInput>;
};

export type TagGroup = {
  __typename?: 'TagGroup';
  categorizable: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  description: Scalars['String'];
  filterable: Scalars['Boolean'];
  id: Scalars['ID'];
  multiselect: Scalars['Boolean'];
  name: Scalars['String'];
  tags: Array<Tag>;
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};

export type TagGroupInput = {
  categorizable: Scalars['Boolean'];
  description: Scalars['String'];
  filterable: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  multiselect: Scalars['Boolean'];
  name: Scalars['String'];
};

export type TagGroupList = {
  __typename?: 'TagGroupList';
  items: Array<TagGroup>;
  total: Scalars['Count'];
};

export type TagInput = {
  description: Scalars['String'];
  displayOrder?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<Array<MediaInput>>;
  name: Scalars['String'];
  tagGroupId: Scalars['ID'];
};

export type TagList = {
  __typename?: 'TagList';
  items: Array<Tag>;
  total: Scalars['Count'];
};

export type Unit = {
  __typename?: 'Unit';
  asGram?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Time'];
  createdByUser?: Maybe<AdminUser>;
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
  updatedByUser?: Maybe<AdminUser>;
};

export type UnitInput = {
  asGram?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type UnitList = {
  __typename?: 'UnitList';
  items: Array<Unit>;
  total: Scalars['Count'];
};

export type UnitQuantity = {
  __typename?: 'UnitQuantity';
  asGram?: Maybe<Scalars['Float']>;
  unit?: Maybe<Unit>;
  unitId: Scalars['ID'];
};

export type UnitQuantityInput = {
  asGram: Scalars['Float'];
  unitId: Scalars['ID'];
};

export type UserUnit = {
  __typename?: 'UserUnit';
  asGram?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RecipeEditHelpersQueryVariables = Exact<{ [key: string]: never; }>;


export type RecipeEditHelpersQuery = { __typename?: 'Query', admin_ingredient_list: { __typename?: 'IngredientList', items: Array<{ __typename?: 'Ingredient', id: string, name: string, defaultRecipeUnitId: string, visible: boolean }> }, admin_unit_list: { __typename?: 'UnitList', items: Array<{ __typename?: 'Unit', id: string, name: string, asGram?: number | null }> }, admin_tag_group_list: { __typename?: 'TagGroupList', items: Array<{ __typename?: 'TagGroup', id: string, name: string, multiselect: boolean, description: string, categorizable: boolean, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> } };

export type IngredientEditHelpersQueryVariables = Exact<{ [key: string]: never; }>;


export type IngredientEditHelpersQuery = { __typename?: 'Query', admin_unit_list: { __typename?: 'UnitList', items: Array<{ __typename?: 'Unit', id: string, name: string, asGram?: number | null }> }, admin_ingredient_group_list: { __typename?: 'IngredientGroupList', items: Array<{ __typename?: 'IngredientGroup', id: string, name: string }> }, admin_allergen_list: { __typename?: 'AllergenList', items: Array<{ __typename?: 'Allergen', id: string, name: string }> } };

export type IngredientListMiniQueryVariables = Exact<{ [key: string]: never; }>;


export type IngredientListMiniQuery = { __typename?: 'Query', admin_ingredient_list: { __typename?: 'IngredientList', items: Array<{ __typename?: 'Ingredient', id: string, name: string, defaultRecipeUnitId: string, visible: boolean }> } };

export type UnitListMiniQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitListMiniQuery = { __typename?: 'Query', admin_unit_list: { __typename?: 'UnitList', items: Array<{ __typename?: 'Unit', id: string, name: string, asGram?: number | null }> } };

export type TagListMiniQueryVariables = Exact<{ [key: string]: never; }>;


export type TagListMiniQuery = { __typename?: 'Query', admin_tag_group_list: { __typename?: 'TagGroupList', items: Array<{ __typename?: 'TagGroup', id: string, name: string, multiselect: boolean, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> } };

export type IngredientGroupListMiniQueryVariables = Exact<{ [key: string]: never; }>;


export type IngredientGroupListMiniQuery = { __typename?: 'Query', admin_ingredient_group_list: { __typename?: 'IngredientGroupList', items: Array<{ __typename?: 'IngredientGroup', id: string, name: string }> } };

export type AllergenListMiniQueryVariables = Exact<{ [key: string]: never; }>;


export type AllergenListMiniQuery = { __typename?: 'Query', admin_allergen_list: { __typename?: 'AllergenList', items: Array<{ __typename?: 'Allergen', id: string, name: string }> } };

export type Admin_Allergen_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Allergen_DeleteMutation = { __typename?: 'Mutation', admin_allergen_delete: boolean };

export type Admin_Allergen_UpsertMutationVariables = Exact<{
  input: AllergenInput;
}>;


export type Admin_Allergen_UpsertMutation = { __typename?: 'Mutation', admin_allergen_upsert: { __typename?: 'Allergen', id: string } };

export type Admin_Ingredient_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Ingredient_DeleteMutation = { __typename?: 'Mutation', admin_ingredient_delete: boolean };

export type Admin_Ingredient_Group_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Ingredient_Group_DeleteMutation = { __typename?: 'Mutation', admin_ingredient_group_delete: boolean };

export type Admin_Ingredient_Group_UpsertMutationVariables = Exact<{
  input: IngredientGroupInput;
}>;


export type Admin_Ingredient_Group_UpsertMutation = { __typename?: 'Mutation', admin_ingredient_group_upsert: { __typename?: 'IngredientGroup', id: string } };

export type Admin_Ingredient_UpsertMutationVariables = Exact<{
  input: IngredientInput;
}>;


export type Admin_Ingredient_UpsertMutation = { __typename?: 'Mutation', admin_ingredient_upsert: { __typename?: 'Ingredient', id: string } };

export type Admin_Intolerance_Group_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Intolerance_Group_DeleteMutation = { __typename?: 'Mutation', admin_intolerance_group_delete: boolean };

export type Admin_Intolerance_Group_UpsertMutationVariables = Exact<{
  input: IntoleranceGroupInput;
}>;


export type Admin_Intolerance_Group_UpsertMutation = { __typename?: 'Mutation', admin_intolerance_group_upsert: { __typename?: 'IntoleranceGroup', id: string } };

export type Admin_LoginMutationVariables = Exact<{
  input: AdminLoginInput;
}>;


export type Admin_LoginMutation = { __typename?: 'Mutation', admin_login: { __typename: 'AdminLoginSuccess', adminUser: { __typename?: 'AdminUser', id: string, email: string, name: string, lastLogin?: Date | null, createdAt: Date, updatedAt: Date, deletedAt?: Date | null } } | { __typename: 'LoginError', error: string } };

export type Admin_LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type Admin_LogoutMutation = { __typename?: 'Mutation', admin_logout: boolean };

export type Admin_Recipe_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Recipe_DeleteMutation = { __typename?: 'Mutation', admin_recipe_delete: boolean };

export type Admin_Recipe_Suggestion_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Recipe_Suggestion_DeleteMutation = { __typename?: 'Mutation', admin_recipe_suggestion_delete: boolean };

export type Admin_Recipe_Suggestion_UpsertMutationVariables = Exact<{
  input: RecipeSuggestionInput;
}>;


export type Admin_Recipe_Suggestion_UpsertMutation = { __typename?: 'Mutation', admin_recipe_suggestion_upsert: { __typename?: 'RecipeSuggestion', id: string } };

export type Admin_Recipe_UpsertMutationVariables = Exact<{
  input: RecipeInput;
}>;


export type Admin_Recipe_UpsertMutation = { __typename?: 'Mutation', admin_recipe_upsert: { __typename?: 'Recipe', id: string } };

export type Admin_Substitution_Group_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Substitution_Group_DeleteMutation = { __typename?: 'Mutation', admin_substitution_group_delete: boolean };

export type Admin_Substitution_Group_UpsertMutationVariables = Exact<{
  input: SubstitutionGroupInput;
}>;


export type Admin_Substitution_Group_UpsertMutation = { __typename?: 'Mutation', admin_substitution_group_upsert: { __typename?: 'SubstitutionGroup', id: string, name: string, createdAt: Date, updatedAt: Date, ingredients: Array<{ __typename?: 'Ingredient', id: string, name: string }>, updatedByUser?: { __typename?: 'AdminUser', name: string } | null, createdByUser?: { __typename?: 'AdminUser', name: string } | null } };

export type Admin_Tag_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Tag_DeleteMutation = { __typename?: 'Mutation', admin_tag_delete: boolean };

export type Admin_Tag_Group_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Tag_Group_DeleteMutation = { __typename?: 'Mutation', admin_tag_group_delete: boolean };

export type Admin_Tag_Group_UpsertMutationVariables = Exact<{
  input: TagGroupInput;
}>;


export type Admin_Tag_Group_UpsertMutation = { __typename?: 'Mutation', admin_tag_group_upsert: { __typename?: 'TagGroup', id: string } };

export type Admin_Tag_UpsertMutationVariables = Exact<{
  input: TagInput;
}>;


export type Admin_Tag_UpsertMutation = { __typename?: 'Mutation', admin_tag_upsert: { __typename?: 'Tag', id: string } };

export type Admin_Unit_DeleteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Unit_DeleteMutation = { __typename?: 'Mutation', admin_unit_delete: boolean };

export type Admin_Unit_UpsertMutationVariables = Exact<{
  input: UnitInput;
}>;


export type Admin_Unit_UpsertMutation = { __typename?: 'Mutation', admin_unit_upsert: { __typename?: 'Unit', id: string } };

export type Admin_Allergen_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Allergen_GetQuery = { __typename?: 'Query', admin_allergen_get: { __typename?: 'Allergen', id: string, name: string, description: string, slug: string } };

export type Admin_Allergen_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Allergen_ListQuery = { __typename?: 'Query', admin_allergen_list: { __typename?: 'AllergenList', total: number, items: Array<{ __typename?: 'Allergen', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, description: string, slug: string, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null }> } };

export type Admin_Get_UserQueryVariables = Exact<{ [key: string]: never; }>;


export type Admin_Get_UserQuery = { __typename?: 'Query', admin_get_user: { __typename?: 'AdminUser', id: string, email: string, name: string, lastLogin?: Date | null, createdAt: Date, updatedAt: Date, deletedAt?: Date | null } };

export type Admin_Ingredient_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Ingredient_GetQuery = { __typename?: 'Query', admin_ingredient_get: { __typename?: 'Ingredient', id: string, name: string, groupId: string, displayOrder: number, ignoreOrder: number, defaultRecipeUnitId: string, defaultShoppingUnitId: string, pricePerGram?: number | null, priceSpread?: number | null, implicitlyAvailable: boolean, initialIngredient: boolean, visible: boolean, calculatedStats: { __typename?: 'IngredientStats', energy?: number | null, protein?: number | null, fat?: number | null, carbon?: number | null }, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }>, allergens: Array<{ __typename?: 'Allergen', id: string }>, availableShoppingUnits: Array<{ __typename?: 'Unit', id: string }>, unitQuantities: Array<{ __typename?: 'UnitQuantity', unitId: string, asGram?: number | null }>, contentBlocks: Array<{ __typename?: 'ContentBlock', kind: ContentKindEnum, name: string, content?: string | null, seasonFrom?: string | null, seasonTo?: string | null }> } };

export type Admin_Ingredient_Group_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Ingredient_Group_GetQuery = { __typename?: 'Query', admin_ingredient_group_get: { __typename?: 'IngredientGroup', id: string, name: string, displayOrder: number, parentId?: string | null, parent?: { __typename?: 'IngredientGroup', id: string } | null, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }>, ingredients: Array<{ __typename?: 'Ingredient', id: string }> } };

export type Admin_Ingredient_Group_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Ingredient_Group_ListQuery = { __typename?: 'Query', admin_ingredient_group_list: { __typename?: 'IngredientGroupList', total: number, items: Array<{ __typename?: 'IngredientGroup', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, displayOrder: number, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, parent?: { __typename?: 'IngredientGroup', id: string, name: string } | null, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }> }> } };

export type Admin_Ingredient_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Ingredient_ListQuery = { __typename?: 'Query', admin_ingredient_list: { __typename?: 'IngredientList', total: number, items: Array<{ __typename?: 'Ingredient', id: string, name: string, createdAt: Date, updatedAt: Date, displayOrder: number, ignoreOrder: number, pricePerGram?: number | null, priceSpread?: number | null, implicitlyAvailable: boolean, initialIngredient: boolean, visible: boolean, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, calculatedStats: { __typename?: 'IngredientStats', energy?: number | null, protein?: number | null, fat?: number | null, carbon?: number | null }, group: { __typename?: 'IngredientGroup', name: string }, allergens: Array<{ __typename?: 'Allergen', id: string, name: string, description: string, slug: string }> }> } };

export type Admin_Intolerance_Group_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Intolerance_Group_GetQuery = { __typename?: 'Query', admin_intolerance_group_get: { __typename?: 'IntoleranceGroup', id: string, name: string, visible: boolean, description: string, allergens: Array<{ __typename?: 'Allergen', id: string }> } };

export type Admin_Intolerance_Group_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Intolerance_Group_ListQuery = { __typename?: 'Query', admin_intolerance_group_list: { __typename?: 'IntoleranceGroupList', total: number, items: Array<{ __typename?: 'IntoleranceGroup', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, visible: boolean, description: string, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, allergens: Array<{ __typename?: 'Allergen', id: string, name: string, description: string, slug: string }> }> } };

export type Admin_Recipe_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Recipe_GetQuery = { __typename?: 'Query', admin_recipe_get: { __typename?: 'Recipe', id: string, createdAt: Date, updatedAt: Date, name: string, nameDynamic: string, activeFrom?: Date | null, steps: string, cookingTime: { __typename?: 'CookingTime', minutesMin?: number | null, minutesMax?: number | null }, tagGroups: Array<{ __typename?: 'ActiveTagGroup', tagGroupId: string, tagIds: Array<string> }>, ingredients: Array<{ __typename?: 'RecipeIngredient', ingredientId: string, quantity: number, unitId: string, note?: string | null, substitutions: Array<{ __typename?: 'RecipeIngredientSubstitution', ingredientId: string, unitId: string, quantity: number, note?: string | null }> }>, dynamicIngredients: Array<{ __typename?: 'GroupIngredient', key: number, name: string, subgroups: Array<{ __typename?: 'GroupSublist', key: number, name: string, ingredients: Array<{ __typename?: 'RecipeIngredient', ingredientId: string, unitId: string, quantity: number, note?: string | null, substitutions: Array<{ __typename?: 'RecipeIngredientSubstitution', ingredientId: string, unitId: string, quantity: number, note?: string | null }> }>, tagGroups: Array<{ __typename?: 'ActiveTagGroup', tagGroupId: string, tagIds: Array<string> }>, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }> }> }>, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }>, contentBlocks: Array<{ __typename?: 'ContentBlock', kind: ContentKindEnum, name: string, content?: string | null, seasonFrom?: string | null, seasonTo?: string | null, meta?: { __typename?: 'ContentMeta', key?: string | null, value?: string | null } | null }> } };

export type Admin_Recipe_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Recipe_ListQuery = { __typename?: 'Query', admin_recipe_list: { __typename?: 'RecipeList', total: number, items: Array<{ __typename?: 'Recipe', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, activeFrom?: Date | null, isActive: boolean, name: string, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, ingredients: Array<{ __typename?: 'RecipeIngredient', ingredient: { __typename?: 'Ingredient', id: string, name: string } }>, tagGroups: Array<{ __typename?: 'ActiveTagGroup', tagGroup: { __typename?: 'TagGroup', id: string, name: string, description: string }, tags: Array<{ __typename?: 'Tag', id: string, name: string, description: string }> }>, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }> }> } };

export type Admin_Recipe_Suggestion_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Recipe_Suggestion_GetQuery = { __typename?: 'Query', admin_recipe_suggestion_get: { __typename?: 'RecipeSuggestion', id: string, name: string, description: string, displayOrder?: number | null, activeRule?: string | null, cachedActiveFrom?: Date | null, cachedActiveTo?: Date | null, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }>, recipes: Array<{ __typename?: 'Recipe', id: string }> } };

export type Admin_Recipe_Suggestion_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Recipe_Suggestion_ListQuery = { __typename?: 'Query', admin_recipe_suggestion_list: { __typename?: 'RecipeSuggestionList', total: number, items: Array<{ __typename?: 'RecipeSuggestion', id: string, createdAt: Date, updatedAt: Date, name: string, description: string, displayOrder?: number | null, activeRule?: string | null, cachedActiveFrom?: Date | null, cachedActiveTo?: Date | null, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }>, recipes: Array<{ __typename?: 'Recipe', id: string, name: string }> }> } };

export type Admin_Substitution_Group_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Substitution_Group_GetQuery = { __typename?: 'Query', admin_substitution_group_get: { __typename?: 'SubstitutionGroup', id: string, name: string, createdAt: Date, updatedAt: Date, ingredients: Array<{ __typename?: 'Ingredient', id: string, name: string }>, updatedByUser?: { __typename?: 'AdminUser', name: string } | null, createdByUser?: { __typename?: 'AdminUser', name: string } | null } };

export type Admin_Substitution_Group_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
  forIngredient?: InputMaybe<Scalars['ID']>;
}>;


export type Admin_Substitution_Group_ListQuery = { __typename?: 'Query', admin_substitution_group_list: { __typename?: 'SubstitutionGroupList', total: number, items: Array<{ __typename?: 'SubstitutionGroup', id: string, name: string, updatedAt: Date, createdAt: Date, ingredients: Array<{ __typename?: 'Ingredient', id: string, name: string }>, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null }> } };

export type Admin_Tag_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Tag_GetQuery = { __typename?: 'Query', admin_tag_get: { __typename?: 'Tag', id: string, name: string, tagGroupId: string, description: string, displayOrder?: number | null, tagGroup: { __typename?: 'TagGroup', id: string }, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }> } };

export type Admin_Tag_Group_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Tag_Group_GetQuery = { __typename?: 'Query', admin_tag_group_get: { __typename?: 'TagGroup', id: string, name: string, description: string, filterable: boolean, multiselect: boolean, categorizable: boolean, tags: Array<{ __typename?: 'Tag', id: string, name: string }> } };

export type Admin_Tag_Group_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Tag_Group_ListQuery = { __typename?: 'Query', admin_tag_group_list: { __typename?: 'TagGroupList', total: number, items: Array<{ __typename?: 'TagGroup', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, description: string, filterable: boolean, multiselect: boolean, categorizable: boolean, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> } };

export type Admin_Tag_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Tag_ListQuery = { __typename?: 'Query', admin_tag_list: { __typename?: 'TagList', total: number, items: Array<{ __typename?: 'Tag', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, description: string, displayOrder?: number | null, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, tagGroup: { __typename?: 'TagGroup', id: string, name: string }, media: Array<{ __typename?: 'Media', uid: string, url: string, name: string, size: number, type: string }> }> } };

export type Admin_Unit_GetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Admin_Unit_GetQuery = { __typename?: 'Query', admin_unit_get: { __typename?: 'Unit', id: string, name: string, asGram?: number | null } };

export type Admin_Unit_ListQueryVariables = Exact<{
  tableQuery?: InputMaybe<TableQuery>;
}>;


export type Admin_Unit_ListQuery = { __typename?: 'Query', admin_unit_list: { __typename?: 'UnitList', total: number, items: Array<{ __typename?: 'Unit', id: string, createdAt: Date, updatedAt: Date, deletedAt?: Date | null, name: string, asGram?: number | null, createdByUser?: { __typename?: 'AdminUser', id: string, name: string } | null, updatedByUser?: { __typename?: 'AdminUser', id: string, name: string } | null }> } };

export type Get_Global_SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Global_SettingsQuery = { __typename?: 'Query', get_global_settings: { __typename?: 'GlobalSettings', backendVersion: string, appLatestVersion: string, environment: string, debugEnabled: boolean, staticContent: { __typename?: 'StaticContent', privacyPolicy: string, termsAndConditions: string } } };

export const namedOperations = {
  Query: {
    recipeEditHelpers: 'recipeEditHelpers' as const,
    ingredientEditHelpers: 'ingredientEditHelpers' as const,
    IngredientListMini: 'IngredientListMini' as const,
    UnitListMini: 'UnitListMini' as const,
    TagListMini: 'TagListMini' as const,
    IngredientGroupListMini: 'IngredientGroupListMini' as const,
    AllergenListMini: 'AllergenListMini' as const,
    admin_allergen_get: 'admin_allergen_get' as const,
    admin_allergen_list: 'admin_allergen_list' as const,
    admin_get_user: 'admin_get_user' as const,
    admin_ingredient_get: 'admin_ingredient_get' as const,
    admin_ingredient_group_get: 'admin_ingredient_group_get' as const,
    admin_ingredient_group_list: 'admin_ingredient_group_list' as const,
    admin_ingredient_list: 'admin_ingredient_list' as const,
    admin_intolerance_group_get: 'admin_intolerance_group_get' as const,
    admin_intolerance_group_list: 'admin_intolerance_group_list' as const,
    admin_recipe_get: 'admin_recipe_get' as const,
    admin_recipe_list: 'admin_recipe_list' as const,
    admin_recipe_suggestion_get: 'admin_recipe_suggestion_get' as const,
    admin_recipe_suggestion_list: 'admin_recipe_suggestion_list' as const,
    admin_substitution_group_get: 'admin_substitution_group_get' as const,
    admin_substitution_group_list: 'admin_substitution_group_list' as const,
    admin_tag_get: 'admin_tag_get' as const,
    admin_tag_group_get: 'admin_tag_group_get' as const,
    admin_tag_group_list: 'admin_tag_group_list' as const,
    admin_tag_list: 'admin_tag_list' as const,
    admin_unit_get: 'admin_unit_get' as const,
    admin_unit_list: 'admin_unit_list' as const,
    get_global_settings: 'get_global_settings' as const
  },
  Mutation: {
    admin_allergen_delete: 'admin_allergen_delete' as const,
    admin_allergen_upsert: 'admin_allergen_upsert' as const,
    admin_ingredient_delete: 'admin_ingredient_delete' as const,
    admin_ingredient_group_delete: 'admin_ingredient_group_delete' as const,
    admin_ingredient_group_upsert: 'admin_ingredient_group_upsert' as const,
    admin_ingredient_upsert: 'admin_ingredient_upsert' as const,
    admin_intolerance_group_delete: 'admin_intolerance_group_delete' as const,
    admin_intolerance_group_upsert: 'admin_intolerance_group_upsert' as const,
    admin_login: 'admin_login' as const,
    admin_logout: 'admin_logout' as const,
    admin_recipe_delete: 'admin_recipe_delete' as const,
    admin_recipe_suggestion_delete: 'admin_recipe_suggestion_delete' as const,
    admin_recipe_suggestion_upsert: 'admin_recipe_suggestion_upsert' as const,
    admin_recipe_upsert: 'admin_recipe_upsert' as const,
    admin_substitution_group_delete: 'admin_substitution_group_delete' as const,
    admin_substitution_group_upsert: 'admin_substitution_group_upsert' as const,
    admin_tag_delete: 'admin_tag_delete' as const,
    admin_tag_group_delete: 'admin_tag_group_delete' as const,
    admin_tag_group_upsert: 'admin_tag_group_upsert' as const,
    admin_tag_upsert: 'admin_tag_upsert' as const,
    admin_unit_delete: 'admin_unit_delete' as const,
    admin_unit_upsert: 'admin_unit_upsert' as const
  }
}

export const RecipeEditHelpersDocument = gql`
    query recipeEditHelpers {
  admin_ingredient_list(
    tableQuery: {limit: 0, sort: [{column: "DisplayOrder", direction: ASC}]}
  ) {
    items {
      id
      name
      defaultRecipeUnitId
      visible
    }
  }
  admin_unit_list(tableQuery: {limit: 0}) {
    items {
      id
      name
      asGram
    }
  }
  admin_tag_group_list(tableQuery: {limit: 0}) {
    items {
      id
      name
      multiselect
      description
      categorizable
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRecipeEditHelpersQuery__
 *
 * To run a query within a React component, call `useRecipeEditHelpersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeEditHelpersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeEditHelpersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecipeEditHelpersQuery(baseOptions?: Apollo.QueryHookOptions<RecipeEditHelpersQuery, RecipeEditHelpersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeEditHelpersQuery, RecipeEditHelpersQueryVariables>(RecipeEditHelpersDocument, options);
      }
export function useRecipeEditHelpersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeEditHelpersQuery, RecipeEditHelpersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeEditHelpersQuery, RecipeEditHelpersQueryVariables>(RecipeEditHelpersDocument, options);
        }
export type RecipeEditHelpersQueryHookResult = ReturnType<typeof useRecipeEditHelpersQuery>;
export type RecipeEditHelpersLazyQueryHookResult = ReturnType<typeof useRecipeEditHelpersLazyQuery>;
export type RecipeEditHelpersQueryResult = Apollo.QueryResult<RecipeEditHelpersQuery, RecipeEditHelpersQueryVariables>;
export const IngredientEditHelpersDocument = gql`
    query ingredientEditHelpers {
  admin_unit_list(tableQuery: {limit: 0}) {
    items {
      id
      name
      asGram
    }
  }
  admin_ingredient_group_list(tableQuery: {limit: 0}) {
    items {
      id
      name
    }
  }
  admin_allergen_list(tableQuery: {limit: 0}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useIngredientEditHelpersQuery__
 *
 * To run a query within a React component, call `useIngredientEditHelpersQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientEditHelpersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientEditHelpersQuery({
 *   variables: {
 *   },
 * });
 */
export function useIngredientEditHelpersQuery(baseOptions?: Apollo.QueryHookOptions<IngredientEditHelpersQuery, IngredientEditHelpersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IngredientEditHelpersQuery, IngredientEditHelpersQueryVariables>(IngredientEditHelpersDocument, options);
      }
export function useIngredientEditHelpersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IngredientEditHelpersQuery, IngredientEditHelpersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IngredientEditHelpersQuery, IngredientEditHelpersQueryVariables>(IngredientEditHelpersDocument, options);
        }
export type IngredientEditHelpersQueryHookResult = ReturnType<typeof useIngredientEditHelpersQuery>;
export type IngredientEditHelpersLazyQueryHookResult = ReturnType<typeof useIngredientEditHelpersLazyQuery>;
export type IngredientEditHelpersQueryResult = Apollo.QueryResult<IngredientEditHelpersQuery, IngredientEditHelpersQueryVariables>;
export const IngredientListMiniDocument = gql`
    query IngredientListMini {
  admin_ingredient_list(
    tableQuery: {limit: 0, sort: [{column: "DisplayOrder", direction: ASC}]}
  ) {
    items {
      id
      name
      defaultRecipeUnitId
      visible
    }
  }
}
    `;

/**
 * __useIngredientListMiniQuery__
 *
 * To run a query within a React component, call `useIngredientListMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientListMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientListMiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useIngredientListMiniQuery(baseOptions?: Apollo.QueryHookOptions<IngredientListMiniQuery, IngredientListMiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IngredientListMiniQuery, IngredientListMiniQueryVariables>(IngredientListMiniDocument, options);
      }
export function useIngredientListMiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IngredientListMiniQuery, IngredientListMiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IngredientListMiniQuery, IngredientListMiniQueryVariables>(IngredientListMiniDocument, options);
        }
export type IngredientListMiniQueryHookResult = ReturnType<typeof useIngredientListMiniQuery>;
export type IngredientListMiniLazyQueryHookResult = ReturnType<typeof useIngredientListMiniLazyQuery>;
export type IngredientListMiniQueryResult = Apollo.QueryResult<IngredientListMiniQuery, IngredientListMiniQueryVariables>;
export const UnitListMiniDocument = gql`
    query UnitListMini {
  admin_unit_list(tableQuery: {limit: 0}) {
    items {
      id
      name
      asGram
    }
  }
}
    `;

/**
 * __useUnitListMiniQuery__
 *
 * To run a query within a React component, call `useUnitListMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitListMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitListMiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitListMiniQuery(baseOptions?: Apollo.QueryHookOptions<UnitListMiniQuery, UnitListMiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitListMiniQuery, UnitListMiniQueryVariables>(UnitListMiniDocument, options);
      }
export function useUnitListMiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitListMiniQuery, UnitListMiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitListMiniQuery, UnitListMiniQueryVariables>(UnitListMiniDocument, options);
        }
export type UnitListMiniQueryHookResult = ReturnType<typeof useUnitListMiniQuery>;
export type UnitListMiniLazyQueryHookResult = ReturnType<typeof useUnitListMiniLazyQuery>;
export type UnitListMiniQueryResult = Apollo.QueryResult<UnitListMiniQuery, UnitListMiniQueryVariables>;
export const TagListMiniDocument = gql`
    query TagListMini {
  admin_tag_group_list(tableQuery: {limit: 0}) {
    items {
      id
      name
      multiselect
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTagListMiniQuery__
 *
 * To run a query within a React component, call `useTagListMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListMiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagListMiniQuery(baseOptions?: Apollo.QueryHookOptions<TagListMiniQuery, TagListMiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagListMiniQuery, TagListMiniQueryVariables>(TagListMiniDocument, options);
      }
export function useTagListMiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagListMiniQuery, TagListMiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagListMiniQuery, TagListMiniQueryVariables>(TagListMiniDocument, options);
        }
export type TagListMiniQueryHookResult = ReturnType<typeof useTagListMiniQuery>;
export type TagListMiniLazyQueryHookResult = ReturnType<typeof useTagListMiniLazyQuery>;
export type TagListMiniQueryResult = Apollo.QueryResult<TagListMiniQuery, TagListMiniQueryVariables>;
export const IngredientGroupListMiniDocument = gql`
    query IngredientGroupListMini {
  admin_ingredient_group_list(tableQuery: {limit: 0}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useIngredientGroupListMiniQuery__
 *
 * To run a query within a React component, call `useIngredientGroupListMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useIngredientGroupListMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIngredientGroupListMiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useIngredientGroupListMiniQuery(baseOptions?: Apollo.QueryHookOptions<IngredientGroupListMiniQuery, IngredientGroupListMiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IngredientGroupListMiniQuery, IngredientGroupListMiniQueryVariables>(IngredientGroupListMiniDocument, options);
      }
export function useIngredientGroupListMiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IngredientGroupListMiniQuery, IngredientGroupListMiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IngredientGroupListMiniQuery, IngredientGroupListMiniQueryVariables>(IngredientGroupListMiniDocument, options);
        }
export type IngredientGroupListMiniQueryHookResult = ReturnType<typeof useIngredientGroupListMiniQuery>;
export type IngredientGroupListMiniLazyQueryHookResult = ReturnType<typeof useIngredientGroupListMiniLazyQuery>;
export type IngredientGroupListMiniQueryResult = Apollo.QueryResult<IngredientGroupListMiniQuery, IngredientGroupListMiniQueryVariables>;
export const AllergenListMiniDocument = gql`
    query AllergenListMini {
  admin_allergen_list(tableQuery: {limit: 0}) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useAllergenListMiniQuery__
 *
 * To run a query within a React component, call `useAllergenListMiniQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllergenListMiniQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllergenListMiniQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllergenListMiniQuery(baseOptions?: Apollo.QueryHookOptions<AllergenListMiniQuery, AllergenListMiniQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllergenListMiniQuery, AllergenListMiniQueryVariables>(AllergenListMiniDocument, options);
      }
export function useAllergenListMiniLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllergenListMiniQuery, AllergenListMiniQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllergenListMiniQuery, AllergenListMiniQueryVariables>(AllergenListMiniDocument, options);
        }
export type AllergenListMiniQueryHookResult = ReturnType<typeof useAllergenListMiniQuery>;
export type AllergenListMiniLazyQueryHookResult = ReturnType<typeof useAllergenListMiniLazyQuery>;
export type AllergenListMiniQueryResult = Apollo.QueryResult<AllergenListMiniQuery, AllergenListMiniQueryVariables>;
export const Admin_Allergen_DeleteDocument = gql`
    mutation admin_allergen_delete($id: ID!) {
  admin_allergen_delete(id: $id)
}
    `;
export type Admin_Allergen_DeleteMutationFn = Apollo.MutationFunction<Admin_Allergen_DeleteMutation, Admin_Allergen_DeleteMutationVariables>;

/**
 * __useAdmin_Allergen_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Allergen_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Allergen_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAllergenDeleteMutation, { data, loading, error }] = useAdmin_Allergen_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Allergen_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Allergen_DeleteMutation, Admin_Allergen_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Allergen_DeleteMutation, Admin_Allergen_DeleteMutationVariables>(Admin_Allergen_DeleteDocument, options);
      }
export type Admin_Allergen_DeleteMutationHookResult = ReturnType<typeof useAdmin_Allergen_DeleteMutation>;
export type Admin_Allergen_DeleteMutationResult = Apollo.MutationResult<Admin_Allergen_DeleteMutation>;
export type Admin_Allergen_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Allergen_DeleteMutation, Admin_Allergen_DeleteMutationVariables>;
export const Admin_Allergen_UpsertDocument = gql`
    mutation admin_allergen_upsert($input: AllergenInput!) {
  admin_allergen_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Allergen_UpsertMutationFn = Apollo.MutationFunction<Admin_Allergen_UpsertMutation, Admin_Allergen_UpsertMutationVariables>;

/**
 * __useAdmin_Allergen_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Allergen_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Allergen_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAllergenUpsertMutation, { data, loading, error }] = useAdmin_Allergen_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Allergen_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Allergen_UpsertMutation, Admin_Allergen_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Allergen_UpsertMutation, Admin_Allergen_UpsertMutationVariables>(Admin_Allergen_UpsertDocument, options);
      }
export type Admin_Allergen_UpsertMutationHookResult = ReturnType<typeof useAdmin_Allergen_UpsertMutation>;
export type Admin_Allergen_UpsertMutationResult = Apollo.MutationResult<Admin_Allergen_UpsertMutation>;
export type Admin_Allergen_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Allergen_UpsertMutation, Admin_Allergen_UpsertMutationVariables>;
export const Admin_Ingredient_DeleteDocument = gql`
    mutation admin_ingredient_delete($id: ID!) {
  admin_ingredient_delete(id: $id)
}
    `;
export type Admin_Ingredient_DeleteMutationFn = Apollo.MutationFunction<Admin_Ingredient_DeleteMutation, Admin_Ingredient_DeleteMutationVariables>;

/**
 * __useAdmin_Ingredient_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Ingredient_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIngredientDeleteMutation, { data, loading, error }] = useAdmin_Ingredient_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Ingredient_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Ingredient_DeleteMutation, Admin_Ingredient_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Ingredient_DeleteMutation, Admin_Ingredient_DeleteMutationVariables>(Admin_Ingredient_DeleteDocument, options);
      }
export type Admin_Ingredient_DeleteMutationHookResult = ReturnType<typeof useAdmin_Ingredient_DeleteMutation>;
export type Admin_Ingredient_DeleteMutationResult = Apollo.MutationResult<Admin_Ingredient_DeleteMutation>;
export type Admin_Ingredient_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Ingredient_DeleteMutation, Admin_Ingredient_DeleteMutationVariables>;
export const Admin_Ingredient_Group_DeleteDocument = gql`
    mutation admin_ingredient_group_delete($id: ID!) {
  admin_ingredient_group_delete(id: $id)
}
    `;
export type Admin_Ingredient_Group_DeleteMutationFn = Apollo.MutationFunction<Admin_Ingredient_Group_DeleteMutation, Admin_Ingredient_Group_DeleteMutationVariables>;

/**
 * __useAdmin_Ingredient_Group_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Ingredient_Group_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_Group_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIngredientGroupDeleteMutation, { data, loading, error }] = useAdmin_Ingredient_Group_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Ingredient_Group_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Ingredient_Group_DeleteMutation, Admin_Ingredient_Group_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Ingredient_Group_DeleteMutation, Admin_Ingredient_Group_DeleteMutationVariables>(Admin_Ingredient_Group_DeleteDocument, options);
      }
export type Admin_Ingredient_Group_DeleteMutationHookResult = ReturnType<typeof useAdmin_Ingredient_Group_DeleteMutation>;
export type Admin_Ingredient_Group_DeleteMutationResult = Apollo.MutationResult<Admin_Ingredient_Group_DeleteMutation>;
export type Admin_Ingredient_Group_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Ingredient_Group_DeleteMutation, Admin_Ingredient_Group_DeleteMutationVariables>;
export const Admin_Ingredient_Group_UpsertDocument = gql`
    mutation admin_ingredient_group_upsert($input: IngredientGroupInput!) {
  admin_ingredient_group_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Ingredient_Group_UpsertMutationFn = Apollo.MutationFunction<Admin_Ingredient_Group_UpsertMutation, Admin_Ingredient_Group_UpsertMutationVariables>;

/**
 * __useAdmin_Ingredient_Group_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Ingredient_Group_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_Group_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIngredientGroupUpsertMutation, { data, loading, error }] = useAdmin_Ingredient_Group_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Ingredient_Group_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Ingredient_Group_UpsertMutation, Admin_Ingredient_Group_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Ingredient_Group_UpsertMutation, Admin_Ingredient_Group_UpsertMutationVariables>(Admin_Ingredient_Group_UpsertDocument, options);
      }
export type Admin_Ingredient_Group_UpsertMutationHookResult = ReturnType<typeof useAdmin_Ingredient_Group_UpsertMutation>;
export type Admin_Ingredient_Group_UpsertMutationResult = Apollo.MutationResult<Admin_Ingredient_Group_UpsertMutation>;
export type Admin_Ingredient_Group_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Ingredient_Group_UpsertMutation, Admin_Ingredient_Group_UpsertMutationVariables>;
export const Admin_Ingredient_UpsertDocument = gql`
    mutation admin_ingredient_upsert($input: IngredientInput!) {
  admin_ingredient_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Ingredient_UpsertMutationFn = Apollo.MutationFunction<Admin_Ingredient_UpsertMutation, Admin_Ingredient_UpsertMutationVariables>;

/**
 * __useAdmin_Ingredient_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Ingredient_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIngredientUpsertMutation, { data, loading, error }] = useAdmin_Ingredient_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Ingredient_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Ingredient_UpsertMutation, Admin_Ingredient_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Ingredient_UpsertMutation, Admin_Ingredient_UpsertMutationVariables>(Admin_Ingredient_UpsertDocument, options);
      }
export type Admin_Ingredient_UpsertMutationHookResult = ReturnType<typeof useAdmin_Ingredient_UpsertMutation>;
export type Admin_Ingredient_UpsertMutationResult = Apollo.MutationResult<Admin_Ingredient_UpsertMutation>;
export type Admin_Ingredient_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Ingredient_UpsertMutation, Admin_Ingredient_UpsertMutationVariables>;
export const Admin_Intolerance_Group_DeleteDocument = gql`
    mutation admin_intolerance_group_delete($id: ID!) {
  admin_intolerance_group_delete(id: $id)
}
    `;
export type Admin_Intolerance_Group_DeleteMutationFn = Apollo.MutationFunction<Admin_Intolerance_Group_DeleteMutation, Admin_Intolerance_Group_DeleteMutationVariables>;

/**
 * __useAdmin_Intolerance_Group_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Intolerance_Group_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Intolerance_Group_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIntoleranceGroupDeleteMutation, { data, loading, error }] = useAdmin_Intolerance_Group_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Intolerance_Group_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Intolerance_Group_DeleteMutation, Admin_Intolerance_Group_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Intolerance_Group_DeleteMutation, Admin_Intolerance_Group_DeleteMutationVariables>(Admin_Intolerance_Group_DeleteDocument, options);
      }
export type Admin_Intolerance_Group_DeleteMutationHookResult = ReturnType<typeof useAdmin_Intolerance_Group_DeleteMutation>;
export type Admin_Intolerance_Group_DeleteMutationResult = Apollo.MutationResult<Admin_Intolerance_Group_DeleteMutation>;
export type Admin_Intolerance_Group_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Intolerance_Group_DeleteMutation, Admin_Intolerance_Group_DeleteMutationVariables>;
export const Admin_Intolerance_Group_UpsertDocument = gql`
    mutation admin_intolerance_group_upsert($input: IntoleranceGroupInput!) {
  admin_intolerance_group_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Intolerance_Group_UpsertMutationFn = Apollo.MutationFunction<Admin_Intolerance_Group_UpsertMutation, Admin_Intolerance_Group_UpsertMutationVariables>;

/**
 * __useAdmin_Intolerance_Group_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Intolerance_Group_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Intolerance_Group_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminIntoleranceGroupUpsertMutation, { data, loading, error }] = useAdmin_Intolerance_Group_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Intolerance_Group_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Intolerance_Group_UpsertMutation, Admin_Intolerance_Group_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Intolerance_Group_UpsertMutation, Admin_Intolerance_Group_UpsertMutationVariables>(Admin_Intolerance_Group_UpsertDocument, options);
      }
export type Admin_Intolerance_Group_UpsertMutationHookResult = ReturnType<typeof useAdmin_Intolerance_Group_UpsertMutation>;
export type Admin_Intolerance_Group_UpsertMutationResult = Apollo.MutationResult<Admin_Intolerance_Group_UpsertMutation>;
export type Admin_Intolerance_Group_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Intolerance_Group_UpsertMutation, Admin_Intolerance_Group_UpsertMutationVariables>;
export const Admin_LoginDocument = gql`
    mutation admin_login($input: AdminLoginInput!) {
  admin_login(input: $input) {
    ... on AdminLoginSuccess {
      __typename
      adminUser {
        id
        email
        name
        lastLogin
        createdAt
        updatedAt
        deletedAt
      }
    }
    ... on LoginError {
      __typename
      error
    }
  }
}
    `;
export type Admin_LoginMutationFn = Apollo.MutationFunction<Admin_LoginMutation, Admin_LoginMutationVariables>;

/**
 * __useAdmin_LoginMutation__
 *
 * To run a mutation, you first call `useAdmin_LoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_LoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdmin_LoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_LoginMutation(baseOptions?: Apollo.MutationHookOptions<Admin_LoginMutation, Admin_LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_LoginMutation, Admin_LoginMutationVariables>(Admin_LoginDocument, options);
      }
export type Admin_LoginMutationHookResult = ReturnType<typeof useAdmin_LoginMutation>;
export type Admin_LoginMutationResult = Apollo.MutationResult<Admin_LoginMutation>;
export type Admin_LoginMutationOptions = Apollo.BaseMutationOptions<Admin_LoginMutation, Admin_LoginMutationVariables>;
export const Admin_LogoutDocument = gql`
    mutation admin_logout {
  admin_logout
}
    `;
export type Admin_LogoutMutationFn = Apollo.MutationFunction<Admin_LogoutMutation, Admin_LogoutMutationVariables>;

/**
 * __useAdmin_LogoutMutation__
 *
 * To run a mutation, you first call `useAdmin_LogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_LogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogoutMutation, { data, loading, error }] = useAdmin_LogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdmin_LogoutMutation(baseOptions?: Apollo.MutationHookOptions<Admin_LogoutMutation, Admin_LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_LogoutMutation, Admin_LogoutMutationVariables>(Admin_LogoutDocument, options);
      }
export type Admin_LogoutMutationHookResult = ReturnType<typeof useAdmin_LogoutMutation>;
export type Admin_LogoutMutationResult = Apollo.MutationResult<Admin_LogoutMutation>;
export type Admin_LogoutMutationOptions = Apollo.BaseMutationOptions<Admin_LogoutMutation, Admin_LogoutMutationVariables>;
export const Admin_Recipe_DeleteDocument = gql`
    mutation admin_recipe_delete($id: ID!) {
  admin_recipe_delete(id: $id)
}
    `;
export type Admin_Recipe_DeleteMutationFn = Apollo.MutationFunction<Admin_Recipe_DeleteMutation, Admin_Recipe_DeleteMutationVariables>;

/**
 * __useAdmin_Recipe_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Recipe_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRecipeDeleteMutation, { data, loading, error }] = useAdmin_Recipe_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Recipe_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Recipe_DeleteMutation, Admin_Recipe_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Recipe_DeleteMutation, Admin_Recipe_DeleteMutationVariables>(Admin_Recipe_DeleteDocument, options);
      }
export type Admin_Recipe_DeleteMutationHookResult = ReturnType<typeof useAdmin_Recipe_DeleteMutation>;
export type Admin_Recipe_DeleteMutationResult = Apollo.MutationResult<Admin_Recipe_DeleteMutation>;
export type Admin_Recipe_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Recipe_DeleteMutation, Admin_Recipe_DeleteMutationVariables>;
export const Admin_Recipe_Suggestion_DeleteDocument = gql`
    mutation admin_recipe_suggestion_delete($id: ID!) {
  admin_recipe_suggestion_delete(id: $id)
}
    `;
export type Admin_Recipe_Suggestion_DeleteMutationFn = Apollo.MutationFunction<Admin_Recipe_Suggestion_DeleteMutation, Admin_Recipe_Suggestion_DeleteMutationVariables>;

/**
 * __useAdmin_Recipe_Suggestion_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Recipe_Suggestion_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_Suggestion_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRecipeSuggestionDeleteMutation, { data, loading, error }] = useAdmin_Recipe_Suggestion_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Recipe_Suggestion_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Recipe_Suggestion_DeleteMutation, Admin_Recipe_Suggestion_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Recipe_Suggestion_DeleteMutation, Admin_Recipe_Suggestion_DeleteMutationVariables>(Admin_Recipe_Suggestion_DeleteDocument, options);
      }
export type Admin_Recipe_Suggestion_DeleteMutationHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_DeleteMutation>;
export type Admin_Recipe_Suggestion_DeleteMutationResult = Apollo.MutationResult<Admin_Recipe_Suggestion_DeleteMutation>;
export type Admin_Recipe_Suggestion_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Recipe_Suggestion_DeleteMutation, Admin_Recipe_Suggestion_DeleteMutationVariables>;
export const Admin_Recipe_Suggestion_UpsertDocument = gql`
    mutation admin_recipe_suggestion_upsert($input: RecipeSuggestionInput!) {
  admin_recipe_suggestion_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Recipe_Suggestion_UpsertMutationFn = Apollo.MutationFunction<Admin_Recipe_Suggestion_UpsertMutation, Admin_Recipe_Suggestion_UpsertMutationVariables>;

/**
 * __useAdmin_Recipe_Suggestion_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Recipe_Suggestion_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_Suggestion_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRecipeSuggestionUpsertMutation, { data, loading, error }] = useAdmin_Recipe_Suggestion_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Recipe_Suggestion_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Recipe_Suggestion_UpsertMutation, Admin_Recipe_Suggestion_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Recipe_Suggestion_UpsertMutation, Admin_Recipe_Suggestion_UpsertMutationVariables>(Admin_Recipe_Suggestion_UpsertDocument, options);
      }
export type Admin_Recipe_Suggestion_UpsertMutationHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_UpsertMutation>;
export type Admin_Recipe_Suggestion_UpsertMutationResult = Apollo.MutationResult<Admin_Recipe_Suggestion_UpsertMutation>;
export type Admin_Recipe_Suggestion_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Recipe_Suggestion_UpsertMutation, Admin_Recipe_Suggestion_UpsertMutationVariables>;
export const Admin_Recipe_UpsertDocument = gql`
    mutation admin_recipe_upsert($input: RecipeInput!) {
  admin_recipe_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Recipe_UpsertMutationFn = Apollo.MutationFunction<Admin_Recipe_UpsertMutation, Admin_Recipe_UpsertMutationVariables>;

/**
 * __useAdmin_Recipe_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Recipe_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRecipeUpsertMutation, { data, loading, error }] = useAdmin_Recipe_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Recipe_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Recipe_UpsertMutation, Admin_Recipe_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Recipe_UpsertMutation, Admin_Recipe_UpsertMutationVariables>(Admin_Recipe_UpsertDocument, options);
      }
export type Admin_Recipe_UpsertMutationHookResult = ReturnType<typeof useAdmin_Recipe_UpsertMutation>;
export type Admin_Recipe_UpsertMutationResult = Apollo.MutationResult<Admin_Recipe_UpsertMutation>;
export type Admin_Recipe_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Recipe_UpsertMutation, Admin_Recipe_UpsertMutationVariables>;
export const Admin_Substitution_Group_DeleteDocument = gql`
    mutation admin_substitution_group_delete($id: ID!) {
  admin_substitution_group_delete(id: $id)
}
    `;
export type Admin_Substitution_Group_DeleteMutationFn = Apollo.MutationFunction<Admin_Substitution_Group_DeleteMutation, Admin_Substitution_Group_DeleteMutationVariables>;

/**
 * __useAdmin_Substitution_Group_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Substitution_Group_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Substitution_Group_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSubstitutionGroupDeleteMutation, { data, loading, error }] = useAdmin_Substitution_Group_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Substitution_Group_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Substitution_Group_DeleteMutation, Admin_Substitution_Group_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Substitution_Group_DeleteMutation, Admin_Substitution_Group_DeleteMutationVariables>(Admin_Substitution_Group_DeleteDocument, options);
      }
export type Admin_Substitution_Group_DeleteMutationHookResult = ReturnType<typeof useAdmin_Substitution_Group_DeleteMutation>;
export type Admin_Substitution_Group_DeleteMutationResult = Apollo.MutationResult<Admin_Substitution_Group_DeleteMutation>;
export type Admin_Substitution_Group_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Substitution_Group_DeleteMutation, Admin_Substitution_Group_DeleteMutationVariables>;
export const Admin_Substitution_Group_UpsertDocument = gql`
    mutation admin_substitution_group_upsert($input: SubstitutionGroupInput!) {
  admin_substitution_group_upsert(input: $input) {
    id
    name
    ingredients {
      id
      name
    }
    createdAt
    updatedAt
    updatedByUser {
      name
    }
    createdByUser {
      name
    }
  }
}
    `;
export type Admin_Substitution_Group_UpsertMutationFn = Apollo.MutationFunction<Admin_Substitution_Group_UpsertMutation, Admin_Substitution_Group_UpsertMutationVariables>;

/**
 * __useAdmin_Substitution_Group_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Substitution_Group_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Substitution_Group_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSubstitutionGroupUpsertMutation, { data, loading, error }] = useAdmin_Substitution_Group_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Substitution_Group_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Substitution_Group_UpsertMutation, Admin_Substitution_Group_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Substitution_Group_UpsertMutation, Admin_Substitution_Group_UpsertMutationVariables>(Admin_Substitution_Group_UpsertDocument, options);
      }
export type Admin_Substitution_Group_UpsertMutationHookResult = ReturnType<typeof useAdmin_Substitution_Group_UpsertMutation>;
export type Admin_Substitution_Group_UpsertMutationResult = Apollo.MutationResult<Admin_Substitution_Group_UpsertMutation>;
export type Admin_Substitution_Group_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Substitution_Group_UpsertMutation, Admin_Substitution_Group_UpsertMutationVariables>;
export const Admin_Tag_DeleteDocument = gql`
    mutation admin_tag_delete($id: ID!) {
  admin_tag_delete(id: $id)
}
    `;
export type Admin_Tag_DeleteMutationFn = Apollo.MutationFunction<Admin_Tag_DeleteMutation, Admin_Tag_DeleteMutationVariables>;

/**
 * __useAdmin_Tag_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Tag_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTagDeleteMutation, { data, loading, error }] = useAdmin_Tag_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Tag_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Tag_DeleteMutation, Admin_Tag_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Tag_DeleteMutation, Admin_Tag_DeleteMutationVariables>(Admin_Tag_DeleteDocument, options);
      }
export type Admin_Tag_DeleteMutationHookResult = ReturnType<typeof useAdmin_Tag_DeleteMutation>;
export type Admin_Tag_DeleteMutationResult = Apollo.MutationResult<Admin_Tag_DeleteMutation>;
export type Admin_Tag_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Tag_DeleteMutation, Admin_Tag_DeleteMutationVariables>;
export const Admin_Tag_Group_DeleteDocument = gql`
    mutation admin_tag_group_delete($id: ID!) {
  admin_tag_group_delete(id: $id)
}
    `;
export type Admin_Tag_Group_DeleteMutationFn = Apollo.MutationFunction<Admin_Tag_Group_DeleteMutation, Admin_Tag_Group_DeleteMutationVariables>;

/**
 * __useAdmin_Tag_Group_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Tag_Group_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_Group_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTagGroupDeleteMutation, { data, loading, error }] = useAdmin_Tag_Group_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Tag_Group_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Tag_Group_DeleteMutation, Admin_Tag_Group_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Tag_Group_DeleteMutation, Admin_Tag_Group_DeleteMutationVariables>(Admin_Tag_Group_DeleteDocument, options);
      }
export type Admin_Tag_Group_DeleteMutationHookResult = ReturnType<typeof useAdmin_Tag_Group_DeleteMutation>;
export type Admin_Tag_Group_DeleteMutationResult = Apollo.MutationResult<Admin_Tag_Group_DeleteMutation>;
export type Admin_Tag_Group_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Tag_Group_DeleteMutation, Admin_Tag_Group_DeleteMutationVariables>;
export const Admin_Tag_Group_UpsertDocument = gql`
    mutation admin_tag_group_upsert($input: TagGroupInput!) {
  admin_tag_group_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Tag_Group_UpsertMutationFn = Apollo.MutationFunction<Admin_Tag_Group_UpsertMutation, Admin_Tag_Group_UpsertMutationVariables>;

/**
 * __useAdmin_Tag_Group_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Tag_Group_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_Group_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTagGroupUpsertMutation, { data, loading, error }] = useAdmin_Tag_Group_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Tag_Group_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Tag_Group_UpsertMutation, Admin_Tag_Group_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Tag_Group_UpsertMutation, Admin_Tag_Group_UpsertMutationVariables>(Admin_Tag_Group_UpsertDocument, options);
      }
export type Admin_Tag_Group_UpsertMutationHookResult = ReturnType<typeof useAdmin_Tag_Group_UpsertMutation>;
export type Admin_Tag_Group_UpsertMutationResult = Apollo.MutationResult<Admin_Tag_Group_UpsertMutation>;
export type Admin_Tag_Group_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Tag_Group_UpsertMutation, Admin_Tag_Group_UpsertMutationVariables>;
export const Admin_Tag_UpsertDocument = gql`
    mutation admin_tag_upsert($input: TagInput!) {
  admin_tag_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Tag_UpsertMutationFn = Apollo.MutationFunction<Admin_Tag_UpsertMutation, Admin_Tag_UpsertMutationVariables>;

/**
 * __useAdmin_Tag_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Tag_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTagUpsertMutation, { data, loading, error }] = useAdmin_Tag_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Tag_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Tag_UpsertMutation, Admin_Tag_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Tag_UpsertMutation, Admin_Tag_UpsertMutationVariables>(Admin_Tag_UpsertDocument, options);
      }
export type Admin_Tag_UpsertMutationHookResult = ReturnType<typeof useAdmin_Tag_UpsertMutation>;
export type Admin_Tag_UpsertMutationResult = Apollo.MutationResult<Admin_Tag_UpsertMutation>;
export type Admin_Tag_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Tag_UpsertMutation, Admin_Tag_UpsertMutationVariables>;
export const Admin_Unit_DeleteDocument = gql`
    mutation admin_unit_delete($id: ID!) {
  admin_unit_delete(id: $id)
}
    `;
export type Admin_Unit_DeleteMutationFn = Apollo.MutationFunction<Admin_Unit_DeleteMutation, Admin_Unit_DeleteMutationVariables>;

/**
 * __useAdmin_Unit_DeleteMutation__
 *
 * To run a mutation, you first call `useAdmin_Unit_DeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Unit_DeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUnitDeleteMutation, { data, loading, error }] = useAdmin_Unit_DeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Unit_DeleteMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Unit_DeleteMutation, Admin_Unit_DeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Unit_DeleteMutation, Admin_Unit_DeleteMutationVariables>(Admin_Unit_DeleteDocument, options);
      }
export type Admin_Unit_DeleteMutationHookResult = ReturnType<typeof useAdmin_Unit_DeleteMutation>;
export type Admin_Unit_DeleteMutationResult = Apollo.MutationResult<Admin_Unit_DeleteMutation>;
export type Admin_Unit_DeleteMutationOptions = Apollo.BaseMutationOptions<Admin_Unit_DeleteMutation, Admin_Unit_DeleteMutationVariables>;
export const Admin_Unit_UpsertDocument = gql`
    mutation admin_unit_upsert($input: UnitInput!) {
  admin_unit_upsert(input: $input) {
    id
  }
}
    `;
export type Admin_Unit_UpsertMutationFn = Apollo.MutationFunction<Admin_Unit_UpsertMutation, Admin_Unit_UpsertMutationVariables>;

/**
 * __useAdmin_Unit_UpsertMutation__
 *
 * To run a mutation, you first call `useAdmin_Unit_UpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Unit_UpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUnitUpsertMutation, { data, loading, error }] = useAdmin_Unit_UpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_Unit_UpsertMutation(baseOptions?: Apollo.MutationHookOptions<Admin_Unit_UpsertMutation, Admin_Unit_UpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Admin_Unit_UpsertMutation, Admin_Unit_UpsertMutationVariables>(Admin_Unit_UpsertDocument, options);
      }
export type Admin_Unit_UpsertMutationHookResult = ReturnType<typeof useAdmin_Unit_UpsertMutation>;
export type Admin_Unit_UpsertMutationResult = Apollo.MutationResult<Admin_Unit_UpsertMutation>;
export type Admin_Unit_UpsertMutationOptions = Apollo.BaseMutationOptions<Admin_Unit_UpsertMutation, Admin_Unit_UpsertMutationVariables>;
export const Admin_Allergen_GetDocument = gql`
    query admin_allergen_get($id: ID!) {
  admin_allergen_get(id: $id) {
    id
    name
    description
    slug
  }
}
    `;

/**
 * __useAdmin_Allergen_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Allergen_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Allergen_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Allergen_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Allergen_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Allergen_GetQuery, Admin_Allergen_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Allergen_GetQuery, Admin_Allergen_GetQueryVariables>(Admin_Allergen_GetDocument, options);
      }
export function useAdmin_Allergen_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Allergen_GetQuery, Admin_Allergen_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Allergen_GetQuery, Admin_Allergen_GetQueryVariables>(Admin_Allergen_GetDocument, options);
        }
export type Admin_Allergen_GetQueryHookResult = ReturnType<typeof useAdmin_Allergen_GetQuery>;
export type Admin_Allergen_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Allergen_GetLazyQuery>;
export type Admin_Allergen_GetQueryResult = Apollo.QueryResult<Admin_Allergen_GetQuery, Admin_Allergen_GetQueryVariables>;
export const Admin_Allergen_ListDocument = gql`
    query admin_allergen_list($tableQuery: TableQuery) {
  admin_allergen_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      description
      slug
    }
  }
}
    `;

/**
 * __useAdmin_Allergen_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Allergen_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Allergen_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Allergen_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Allergen_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Allergen_ListQuery, Admin_Allergen_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Allergen_ListQuery, Admin_Allergen_ListQueryVariables>(Admin_Allergen_ListDocument, options);
      }
export function useAdmin_Allergen_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Allergen_ListQuery, Admin_Allergen_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Allergen_ListQuery, Admin_Allergen_ListQueryVariables>(Admin_Allergen_ListDocument, options);
        }
export type Admin_Allergen_ListQueryHookResult = ReturnType<typeof useAdmin_Allergen_ListQuery>;
export type Admin_Allergen_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Allergen_ListLazyQuery>;
export type Admin_Allergen_ListQueryResult = Apollo.QueryResult<Admin_Allergen_ListQuery, Admin_Allergen_ListQueryVariables>;
export const Admin_Get_UserDocument = gql`
    query admin_get_user {
  admin_get_user {
    id
    email
    name
    lastLogin
    createdAt
    updatedAt
    deletedAt
  }
}
    `;

/**
 * __useAdmin_Get_UserQuery__
 *
 * To run a query within a React component, call `useAdmin_Get_UserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Get_UserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Get_UserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdmin_Get_UserQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Get_UserQuery, Admin_Get_UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Get_UserQuery, Admin_Get_UserQueryVariables>(Admin_Get_UserDocument, options);
      }
export function useAdmin_Get_UserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Get_UserQuery, Admin_Get_UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Get_UserQuery, Admin_Get_UserQueryVariables>(Admin_Get_UserDocument, options);
        }
export type Admin_Get_UserQueryHookResult = ReturnType<typeof useAdmin_Get_UserQuery>;
export type Admin_Get_UserLazyQueryHookResult = ReturnType<typeof useAdmin_Get_UserLazyQuery>;
export type Admin_Get_UserQueryResult = Apollo.QueryResult<Admin_Get_UserQuery, Admin_Get_UserQueryVariables>;
export const Admin_Ingredient_GetDocument = gql`
    query admin_ingredient_get($id: ID!) {
  admin_ingredient_get(id: $id) {
    id
    name
    groupId
    calculatedStats {
      energy
      protein
      fat
      carbon
    }
    displayOrder
    ignoreOrder
    defaultRecipeUnitId
    defaultShoppingUnitId
    media {
      uid
      url
      name
      size
      type
    }
    allergens {
      id
    }
    availableShoppingUnits {
      id
    }
    unitQuantities {
      unitId
      asGram
    }
    pricePerGram
    priceSpread
    implicitlyAvailable
    initialIngredient
    contentBlocks {
      kind
      name
      content
      seasonFrom
      seasonTo
    }
    visible
  }
}
    `;

/**
 * __useAdmin_Ingredient_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Ingredient_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Ingredient_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Ingredient_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Ingredient_GetQuery, Admin_Ingredient_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Ingredient_GetQuery, Admin_Ingredient_GetQueryVariables>(Admin_Ingredient_GetDocument, options);
      }
export function useAdmin_Ingredient_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Ingredient_GetQuery, Admin_Ingredient_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Ingredient_GetQuery, Admin_Ingredient_GetQueryVariables>(Admin_Ingredient_GetDocument, options);
        }
export type Admin_Ingredient_GetQueryHookResult = ReturnType<typeof useAdmin_Ingredient_GetQuery>;
export type Admin_Ingredient_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Ingredient_GetLazyQuery>;
export type Admin_Ingredient_GetQueryResult = Apollo.QueryResult<Admin_Ingredient_GetQuery, Admin_Ingredient_GetQueryVariables>;
export const Admin_Ingredient_Group_GetDocument = gql`
    query admin_ingredient_group_get($id: ID!) {
  admin_ingredient_group_get(id: $id) {
    id
    name
    displayOrder
    parentId
    parent {
      id
    }
    media {
      uid
      url
      name
      size
      type
    }
    ingredients {
      id
    }
  }
}
    `;

/**
 * __useAdmin_Ingredient_Group_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Ingredient_Group_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_Group_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Ingredient_Group_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Ingredient_Group_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Ingredient_Group_GetQuery, Admin_Ingredient_Group_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Ingredient_Group_GetQuery, Admin_Ingredient_Group_GetQueryVariables>(Admin_Ingredient_Group_GetDocument, options);
      }
export function useAdmin_Ingredient_Group_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Ingredient_Group_GetQuery, Admin_Ingredient_Group_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Ingredient_Group_GetQuery, Admin_Ingredient_Group_GetQueryVariables>(Admin_Ingredient_Group_GetDocument, options);
        }
export type Admin_Ingredient_Group_GetQueryHookResult = ReturnType<typeof useAdmin_Ingredient_Group_GetQuery>;
export type Admin_Ingredient_Group_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Ingredient_Group_GetLazyQuery>;
export type Admin_Ingredient_Group_GetQueryResult = Apollo.QueryResult<Admin_Ingredient_Group_GetQuery, Admin_Ingredient_Group_GetQueryVariables>;
export const Admin_Ingredient_Group_ListDocument = gql`
    query admin_ingredient_group_list($tableQuery: TableQuery) {
  admin_ingredient_group_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      displayOrder
      parent {
        id
        name
      }
      media {
        uid
        url
        name
        size
        type
      }
    }
  }
}
    `;

/**
 * __useAdmin_Ingredient_Group_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Ingredient_Group_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_Group_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Ingredient_Group_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Ingredient_Group_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Ingredient_Group_ListQuery, Admin_Ingredient_Group_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Ingredient_Group_ListQuery, Admin_Ingredient_Group_ListQueryVariables>(Admin_Ingredient_Group_ListDocument, options);
      }
export function useAdmin_Ingredient_Group_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Ingredient_Group_ListQuery, Admin_Ingredient_Group_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Ingredient_Group_ListQuery, Admin_Ingredient_Group_ListQueryVariables>(Admin_Ingredient_Group_ListDocument, options);
        }
export type Admin_Ingredient_Group_ListQueryHookResult = ReturnType<typeof useAdmin_Ingredient_Group_ListQuery>;
export type Admin_Ingredient_Group_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Ingredient_Group_ListLazyQuery>;
export type Admin_Ingredient_Group_ListQueryResult = Apollo.QueryResult<Admin_Ingredient_Group_ListQuery, Admin_Ingredient_Group_ListQueryVariables>;
export const Admin_Ingredient_ListDocument = gql`
    query admin_ingredient_list($tableQuery: TableQuery) {
  admin_ingredient_list(tableQuery: $tableQuery) {
    total
    items {
      id
      name
      createdAt
      updatedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      calculatedStats {
        energy
        protein
        fat
        carbon
      }
      group {
        name
      }
      displayOrder
      ignoreOrder
      allergens {
        id
        name
        description
        slug
      }
      pricePerGram
      priceSpread
      implicitlyAvailable
      initialIngredient
      visible
    }
  }
}
    `;

/**
 * __useAdmin_Ingredient_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Ingredient_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Ingredient_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Ingredient_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Ingredient_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Ingredient_ListQuery, Admin_Ingredient_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Ingredient_ListQuery, Admin_Ingredient_ListQueryVariables>(Admin_Ingredient_ListDocument, options);
      }
export function useAdmin_Ingredient_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Ingredient_ListQuery, Admin_Ingredient_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Ingredient_ListQuery, Admin_Ingredient_ListQueryVariables>(Admin_Ingredient_ListDocument, options);
        }
export type Admin_Ingredient_ListQueryHookResult = ReturnType<typeof useAdmin_Ingredient_ListQuery>;
export type Admin_Ingredient_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Ingredient_ListLazyQuery>;
export type Admin_Ingredient_ListQueryResult = Apollo.QueryResult<Admin_Ingredient_ListQuery, Admin_Ingredient_ListQueryVariables>;
export const Admin_Intolerance_Group_GetDocument = gql`
    query admin_intolerance_group_get($id: ID!) {
  admin_intolerance_group_get(id: $id) {
    id
    name
    visible
    allergens {
      id
    }
    description
  }
}
    `;

/**
 * __useAdmin_Intolerance_Group_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Intolerance_Group_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Intolerance_Group_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Intolerance_Group_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Intolerance_Group_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Intolerance_Group_GetQuery, Admin_Intolerance_Group_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Intolerance_Group_GetQuery, Admin_Intolerance_Group_GetQueryVariables>(Admin_Intolerance_Group_GetDocument, options);
      }
export function useAdmin_Intolerance_Group_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Intolerance_Group_GetQuery, Admin_Intolerance_Group_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Intolerance_Group_GetQuery, Admin_Intolerance_Group_GetQueryVariables>(Admin_Intolerance_Group_GetDocument, options);
        }
export type Admin_Intolerance_Group_GetQueryHookResult = ReturnType<typeof useAdmin_Intolerance_Group_GetQuery>;
export type Admin_Intolerance_Group_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Intolerance_Group_GetLazyQuery>;
export type Admin_Intolerance_Group_GetQueryResult = Apollo.QueryResult<Admin_Intolerance_Group_GetQuery, Admin_Intolerance_Group_GetQueryVariables>;
export const Admin_Intolerance_Group_ListDocument = gql`
    query admin_intolerance_group_list($tableQuery: TableQuery) {
  admin_intolerance_group_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      visible
      allergens {
        id
        name
        description
        slug
      }
      description
    }
  }
}
    `;

/**
 * __useAdmin_Intolerance_Group_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Intolerance_Group_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Intolerance_Group_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Intolerance_Group_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Intolerance_Group_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Intolerance_Group_ListQuery, Admin_Intolerance_Group_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Intolerance_Group_ListQuery, Admin_Intolerance_Group_ListQueryVariables>(Admin_Intolerance_Group_ListDocument, options);
      }
export function useAdmin_Intolerance_Group_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Intolerance_Group_ListQuery, Admin_Intolerance_Group_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Intolerance_Group_ListQuery, Admin_Intolerance_Group_ListQueryVariables>(Admin_Intolerance_Group_ListDocument, options);
        }
export type Admin_Intolerance_Group_ListQueryHookResult = ReturnType<typeof useAdmin_Intolerance_Group_ListQuery>;
export type Admin_Intolerance_Group_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Intolerance_Group_ListLazyQuery>;
export type Admin_Intolerance_Group_ListQueryResult = Apollo.QueryResult<Admin_Intolerance_Group_ListQuery, Admin_Intolerance_Group_ListQueryVariables>;
export const Admin_Recipe_GetDocument = gql`
    query admin_recipe_get($id: ID!) {
  admin_recipe_get(id: $id) {
    id
    createdAt
    updatedAt
    name
    nameDynamic
    activeFrom
    cookingTime {
      minutesMin
      minutesMax
    }
    tagGroups {
      tagGroupId
      tagIds
    }
    ingredients {
      ingredientId
      quantity
      unitId
      note
      substitutions {
        ingredientId
        unitId
        quantity
        note
      }
    }
    dynamicIngredients {
      key
      name
      subgroups {
        key
        name
        ingredients {
          ingredientId
          unitId
          quantity
          note
          substitutions {
            ingredientId
            unitId
            quantity
            note
          }
        }
        tagGroups {
          tagGroupId
          tagIds
        }
        media {
          uid
          url
          name
          size
          type
        }
      }
    }
    steps
    media {
      uid
      url
      name
      size
      type
    }
    contentBlocks {
      kind
      name
      content
      meta {
        key
        value
      }
      seasonFrom
      seasonTo
    }
  }
}
    `;

/**
 * __useAdmin_Recipe_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Recipe_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Recipe_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Recipe_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Recipe_GetQuery, Admin_Recipe_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Recipe_GetQuery, Admin_Recipe_GetQueryVariables>(Admin_Recipe_GetDocument, options);
      }
export function useAdmin_Recipe_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Recipe_GetQuery, Admin_Recipe_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Recipe_GetQuery, Admin_Recipe_GetQueryVariables>(Admin_Recipe_GetDocument, options);
        }
export type Admin_Recipe_GetQueryHookResult = ReturnType<typeof useAdmin_Recipe_GetQuery>;
export type Admin_Recipe_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Recipe_GetLazyQuery>;
export type Admin_Recipe_GetQueryResult = Apollo.QueryResult<Admin_Recipe_GetQuery, Admin_Recipe_GetQueryVariables>;
export const Admin_Recipe_ListDocument = gql`
    query admin_recipe_list($tableQuery: TableQuery) {
  admin_recipe_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      ingredients {
        ingredient {
          id
          name
        }
      }
      activeFrom
      isActive
      name
      tagGroups {
        tagGroup {
          id
          name
          description
        }
        tags {
          id
          name
          description
        }
      }
      media {
        uid
        url
        name
        size
        type
      }
    }
  }
}
    `;

/**
 * __useAdmin_Recipe_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Recipe_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Recipe_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Recipe_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Recipe_ListQuery, Admin_Recipe_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Recipe_ListQuery, Admin_Recipe_ListQueryVariables>(Admin_Recipe_ListDocument, options);
      }
export function useAdmin_Recipe_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Recipe_ListQuery, Admin_Recipe_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Recipe_ListQuery, Admin_Recipe_ListQueryVariables>(Admin_Recipe_ListDocument, options);
        }
export type Admin_Recipe_ListQueryHookResult = ReturnType<typeof useAdmin_Recipe_ListQuery>;
export type Admin_Recipe_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Recipe_ListLazyQuery>;
export type Admin_Recipe_ListQueryResult = Apollo.QueryResult<Admin_Recipe_ListQuery, Admin_Recipe_ListQueryVariables>;
export const Admin_Recipe_Suggestion_GetDocument = gql`
    query admin_recipe_suggestion_get($id: ID!) {
  admin_recipe_suggestion_get(id: $id) {
    id
    name
    description
    media {
      uid
      url
      name
      size
      type
    }
    recipes {
      id
    }
    displayOrder
    activeRule
    cachedActiveFrom
    cachedActiveTo
  }
}
    `;

/**
 * __useAdmin_Recipe_Suggestion_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Recipe_Suggestion_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_Suggestion_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Recipe_Suggestion_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Recipe_Suggestion_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Recipe_Suggestion_GetQuery, Admin_Recipe_Suggestion_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Recipe_Suggestion_GetQuery, Admin_Recipe_Suggestion_GetQueryVariables>(Admin_Recipe_Suggestion_GetDocument, options);
      }
export function useAdmin_Recipe_Suggestion_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Recipe_Suggestion_GetQuery, Admin_Recipe_Suggestion_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Recipe_Suggestion_GetQuery, Admin_Recipe_Suggestion_GetQueryVariables>(Admin_Recipe_Suggestion_GetDocument, options);
        }
export type Admin_Recipe_Suggestion_GetQueryHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_GetQuery>;
export type Admin_Recipe_Suggestion_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_GetLazyQuery>;
export type Admin_Recipe_Suggestion_GetQueryResult = Apollo.QueryResult<Admin_Recipe_Suggestion_GetQuery, Admin_Recipe_Suggestion_GetQueryVariables>;
export const Admin_Recipe_Suggestion_ListDocument = gql`
    query admin_recipe_suggestion_list($tableQuery: TableQuery) {
  admin_recipe_suggestion_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      description
      media {
        uid
        url
        name
        size
        type
      }
      recipes {
        id
        name
      }
      displayOrder
      activeRule
      cachedActiveFrom
      cachedActiveTo
    }
  }
}
    `;

/**
 * __useAdmin_Recipe_Suggestion_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Recipe_Suggestion_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Recipe_Suggestion_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Recipe_Suggestion_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Recipe_Suggestion_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Recipe_Suggestion_ListQuery, Admin_Recipe_Suggestion_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Recipe_Suggestion_ListQuery, Admin_Recipe_Suggestion_ListQueryVariables>(Admin_Recipe_Suggestion_ListDocument, options);
      }
export function useAdmin_Recipe_Suggestion_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Recipe_Suggestion_ListQuery, Admin_Recipe_Suggestion_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Recipe_Suggestion_ListQuery, Admin_Recipe_Suggestion_ListQueryVariables>(Admin_Recipe_Suggestion_ListDocument, options);
        }
export type Admin_Recipe_Suggestion_ListQueryHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_ListQuery>;
export type Admin_Recipe_Suggestion_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Recipe_Suggestion_ListLazyQuery>;
export type Admin_Recipe_Suggestion_ListQueryResult = Apollo.QueryResult<Admin_Recipe_Suggestion_ListQuery, Admin_Recipe_Suggestion_ListQueryVariables>;
export const Admin_Substitution_Group_GetDocument = gql`
    query admin_substitution_group_get($id: ID!) {
  admin_substitution_group_get(id: $id) {
    id
    name
    ingredients {
      id
      name
    }
    updatedByUser {
      name
    }
    createdByUser {
      name
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useAdmin_Substitution_Group_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Substitution_Group_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Substitution_Group_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Substitution_Group_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Substitution_Group_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Substitution_Group_GetQuery, Admin_Substitution_Group_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Substitution_Group_GetQuery, Admin_Substitution_Group_GetQueryVariables>(Admin_Substitution_Group_GetDocument, options);
      }
export function useAdmin_Substitution_Group_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Substitution_Group_GetQuery, Admin_Substitution_Group_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Substitution_Group_GetQuery, Admin_Substitution_Group_GetQueryVariables>(Admin_Substitution_Group_GetDocument, options);
        }
export type Admin_Substitution_Group_GetQueryHookResult = ReturnType<typeof useAdmin_Substitution_Group_GetQuery>;
export type Admin_Substitution_Group_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Substitution_Group_GetLazyQuery>;
export type Admin_Substitution_Group_GetQueryResult = Apollo.QueryResult<Admin_Substitution_Group_GetQuery, Admin_Substitution_Group_GetQueryVariables>;
export const Admin_Substitution_Group_ListDocument = gql`
    query admin_substitution_group_list($tableQuery: TableQuery, $forIngredient: ID) {
  admin_substitution_group_list(
    tableQuery: $tableQuery
    forIngredient: $forIngredient
  ) {
    total
    items {
      id
      name
      ingredients {
        id
        name
      }
      updatedAt
      createdAt
      updatedByUser {
        id
        name
      }
      createdByUser {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdmin_Substitution_Group_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Substitution_Group_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Substitution_Group_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Substitution_Group_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *      forIngredient: // value for 'forIngredient'
 *   },
 * });
 */
export function useAdmin_Substitution_Group_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Substitution_Group_ListQuery, Admin_Substitution_Group_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Substitution_Group_ListQuery, Admin_Substitution_Group_ListQueryVariables>(Admin_Substitution_Group_ListDocument, options);
      }
export function useAdmin_Substitution_Group_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Substitution_Group_ListQuery, Admin_Substitution_Group_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Substitution_Group_ListQuery, Admin_Substitution_Group_ListQueryVariables>(Admin_Substitution_Group_ListDocument, options);
        }
export type Admin_Substitution_Group_ListQueryHookResult = ReturnType<typeof useAdmin_Substitution_Group_ListQuery>;
export type Admin_Substitution_Group_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Substitution_Group_ListLazyQuery>;
export type Admin_Substitution_Group_ListQueryResult = Apollo.QueryResult<Admin_Substitution_Group_ListQuery, Admin_Substitution_Group_ListQueryVariables>;
export const Admin_Tag_GetDocument = gql`
    query admin_tag_get($id: ID!) {
  admin_tag_get(id: $id) {
    id
    name
    tagGroupId
    tagGroup {
      id
    }
    description
    displayOrder
    media {
      uid
      url
      name
      size
      type
    }
  }
}
    `;

/**
 * __useAdmin_Tag_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Tag_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Tag_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Tag_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Tag_GetQuery, Admin_Tag_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Tag_GetQuery, Admin_Tag_GetQueryVariables>(Admin_Tag_GetDocument, options);
      }
export function useAdmin_Tag_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Tag_GetQuery, Admin_Tag_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Tag_GetQuery, Admin_Tag_GetQueryVariables>(Admin_Tag_GetDocument, options);
        }
export type Admin_Tag_GetQueryHookResult = ReturnType<typeof useAdmin_Tag_GetQuery>;
export type Admin_Tag_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Tag_GetLazyQuery>;
export type Admin_Tag_GetQueryResult = Apollo.QueryResult<Admin_Tag_GetQuery, Admin_Tag_GetQueryVariables>;
export const Admin_Tag_Group_GetDocument = gql`
    query admin_tag_group_get($id: ID!) {
  admin_tag_group_get(id: $id) {
    id
    name
    description
    filterable
    multiselect
    categorizable
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useAdmin_Tag_Group_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Tag_Group_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_Group_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Tag_Group_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Tag_Group_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Tag_Group_GetQuery, Admin_Tag_Group_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Tag_Group_GetQuery, Admin_Tag_Group_GetQueryVariables>(Admin_Tag_Group_GetDocument, options);
      }
export function useAdmin_Tag_Group_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Tag_Group_GetQuery, Admin_Tag_Group_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Tag_Group_GetQuery, Admin_Tag_Group_GetQueryVariables>(Admin_Tag_Group_GetDocument, options);
        }
export type Admin_Tag_Group_GetQueryHookResult = ReturnType<typeof useAdmin_Tag_Group_GetQuery>;
export type Admin_Tag_Group_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Tag_Group_GetLazyQuery>;
export type Admin_Tag_Group_GetQueryResult = Apollo.QueryResult<Admin_Tag_Group_GetQuery, Admin_Tag_Group_GetQueryVariables>;
export const Admin_Tag_Group_ListDocument = gql`
    query admin_tag_group_list($tableQuery: TableQuery) {
  admin_tag_group_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      description
      filterable
      multiselect
      categorizable
      tags {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdmin_Tag_Group_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Tag_Group_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_Group_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Tag_Group_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Tag_Group_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Tag_Group_ListQuery, Admin_Tag_Group_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Tag_Group_ListQuery, Admin_Tag_Group_ListQueryVariables>(Admin_Tag_Group_ListDocument, options);
      }
export function useAdmin_Tag_Group_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Tag_Group_ListQuery, Admin_Tag_Group_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Tag_Group_ListQuery, Admin_Tag_Group_ListQueryVariables>(Admin_Tag_Group_ListDocument, options);
        }
export type Admin_Tag_Group_ListQueryHookResult = ReturnType<typeof useAdmin_Tag_Group_ListQuery>;
export type Admin_Tag_Group_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Tag_Group_ListLazyQuery>;
export type Admin_Tag_Group_ListQueryResult = Apollo.QueryResult<Admin_Tag_Group_ListQuery, Admin_Tag_Group_ListQueryVariables>;
export const Admin_Tag_ListDocument = gql`
    query admin_tag_list($tableQuery: TableQuery) {
  admin_tag_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      tagGroup {
        id
        name
      }
      description
      displayOrder
      media {
        uid
        url
        name
        size
        type
      }
    }
  }
}
    `;

/**
 * __useAdmin_Tag_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Tag_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Tag_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Tag_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Tag_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Tag_ListQuery, Admin_Tag_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Tag_ListQuery, Admin_Tag_ListQueryVariables>(Admin_Tag_ListDocument, options);
      }
export function useAdmin_Tag_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Tag_ListQuery, Admin_Tag_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Tag_ListQuery, Admin_Tag_ListQueryVariables>(Admin_Tag_ListDocument, options);
        }
export type Admin_Tag_ListQueryHookResult = ReturnType<typeof useAdmin_Tag_ListQuery>;
export type Admin_Tag_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Tag_ListLazyQuery>;
export type Admin_Tag_ListQueryResult = Apollo.QueryResult<Admin_Tag_ListQuery, Admin_Tag_ListQueryVariables>;
export const Admin_Unit_GetDocument = gql`
    query admin_unit_get($id: ID!) {
  admin_unit_get(id: $id) {
    id
    name
    asGram
  }
}
    `;

/**
 * __useAdmin_Unit_GetQuery__
 *
 * To run a query within a React component, call `useAdmin_Unit_GetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Unit_GetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Unit_GetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdmin_Unit_GetQuery(baseOptions: Apollo.QueryHookOptions<Admin_Unit_GetQuery, Admin_Unit_GetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Unit_GetQuery, Admin_Unit_GetQueryVariables>(Admin_Unit_GetDocument, options);
      }
export function useAdmin_Unit_GetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Unit_GetQuery, Admin_Unit_GetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Unit_GetQuery, Admin_Unit_GetQueryVariables>(Admin_Unit_GetDocument, options);
        }
export type Admin_Unit_GetQueryHookResult = ReturnType<typeof useAdmin_Unit_GetQuery>;
export type Admin_Unit_GetLazyQueryHookResult = ReturnType<typeof useAdmin_Unit_GetLazyQuery>;
export type Admin_Unit_GetQueryResult = Apollo.QueryResult<Admin_Unit_GetQuery, Admin_Unit_GetQueryVariables>;
export const Admin_Unit_ListDocument = gql`
    query admin_unit_list($tableQuery: TableQuery) {
  admin_unit_list(tableQuery: $tableQuery) {
    total
    items {
      id
      createdAt
      updatedAt
      deletedAt
      createdByUser {
        id
        name
      }
      updatedByUser {
        id
        name
      }
      name
      asGram
    }
  }
}
    `;

/**
 * __useAdmin_Unit_ListQuery__
 *
 * To run a query within a React component, call `useAdmin_Unit_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmin_Unit_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmin_Unit_ListQuery({
 *   variables: {
 *      tableQuery: // value for 'tableQuery'
 *   },
 * });
 */
export function useAdmin_Unit_ListQuery(baseOptions?: Apollo.QueryHookOptions<Admin_Unit_ListQuery, Admin_Unit_ListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Admin_Unit_ListQuery, Admin_Unit_ListQueryVariables>(Admin_Unit_ListDocument, options);
      }
export function useAdmin_Unit_ListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Admin_Unit_ListQuery, Admin_Unit_ListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Admin_Unit_ListQuery, Admin_Unit_ListQueryVariables>(Admin_Unit_ListDocument, options);
        }
export type Admin_Unit_ListQueryHookResult = ReturnType<typeof useAdmin_Unit_ListQuery>;
export type Admin_Unit_ListLazyQueryHookResult = ReturnType<typeof useAdmin_Unit_ListLazyQuery>;
export type Admin_Unit_ListQueryResult = Apollo.QueryResult<Admin_Unit_ListQuery, Admin_Unit_ListQueryVariables>;
export const Get_Global_SettingsDocument = gql`
    query get_global_settings {
  get_global_settings {
    backendVersion
    appLatestVersion
    environment
    debugEnabled
    staticContent {
      privacyPolicy
      termsAndConditions
    }
  }
}
    `;

/**
 * __useGet_Global_SettingsQuery__
 *
 * To run a query within a React component, call `useGet_Global_SettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Global_SettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Global_SettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Global_SettingsQuery(baseOptions?: Apollo.QueryHookOptions<Get_Global_SettingsQuery, Get_Global_SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get_Global_SettingsQuery, Get_Global_SettingsQueryVariables>(Get_Global_SettingsDocument, options);
      }
export function useGet_Global_SettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_Global_SettingsQuery, Get_Global_SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get_Global_SettingsQuery, Get_Global_SettingsQueryVariables>(Get_Global_SettingsDocument, options);
        }
export type Get_Global_SettingsQueryHookResult = ReturnType<typeof useGet_Global_SettingsQuery>;
export type Get_Global_SettingsLazyQueryHookResult = ReturnType<typeof useGet_Global_SettingsLazyQuery>;
export type Get_Global_SettingsQueryResult = Apollo.QueryResult<Get_Global_SettingsQuery, Get_Global_SettingsQueryVariables>;
import React, { ReactNode, useEffect } from 'react'
import R from '../resources/strings.json'

export interface KajaPageArgs {
  children: ReactNode
  title?: string
}

const KajaPage: React.FC<KajaPageArgs> = ({ children, title }) => {
  useEffect(() => {
    if (title) {
      document.title = `${R.title} - ${title}`
    } else {
      document.title = R.title
    }
  })
  return <div>{children}</div>
}
export default KajaPage

import { Button, Card, Dropdown, Form, Input, Menu, Modal, Space, AutoComplete } from 'antd'
import * as Icons from '@ant-design/icons'
import { PlusOutlined } from '@ant-design/icons'
import React, { useCallback, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import ElMover from '../ElMover'
import { ValidatorRule } from 'rc-field-form/lib/interface'
import lodash from 'lodash'
import { FormInterface } from '../../screens/RecipeEdit/RecipeEdit'
import { ContentBlockElemType } from './ContentBlockTemplateMenu'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ContentKindEnum, ContentBlockInput as ContentBlockInputType } from '../../generated/graphql'
import moment from 'moment'

export interface ContentBlockInputArgs {
  name: Array<string | number>
  options: ContentBlockElemType[]
}

// eslint-disable-next-line @typescript-eslint/require-await
const contentBlockValidator: ValidatorRule['validator'] = async (rule, value: FormInterface['contentBlocks']) => {
  if (lodash.uniq(lodash.map(value, 'name')).length !== value.length) {
    throw new Error('A blokkok nevei nem ismétlődhetnek.')
  }
}

const monthHelp = [
  { value: '01', label: 'Január' },
  { value: '02', label: 'Február' },
  { value: '03', label: 'Március' },
  { value: '04', label: 'Április' },
  { value: '05', label: 'Május' },
  { value: '06', label: 'Június' },
  { value: '07', label: 'Július' },
  { value: '08', label: 'Augusztus' },
  { value: '09', label: 'Szeptember' },
  { value: '10', label: 'Október' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },

  { value: '01-01', label: 'Január eleje' },
  { value: '02-01', label: 'Február eleje' },
  { value: '03-01', label: 'Március eleje' },
  { value: '04-01', label: 'Április eleje' },
  { value: '05-01', label: 'Május eleje' },
  { value: '06-01', label: 'Június eleje' },
  { value: '07-01', label: 'Július eleje' },
  { value: '08-01', label: 'Augusztus eleje' },
  { value: '09-01', label: 'Szeptember eleje' },
  { value: '10-01', label: 'Október eleje' },
  { value: '11-01', label: 'November eleje' },
  { value: '12-01', label: 'December eleje' },

  { value: '01-15', label: 'Január közepe' },
  { value: '02-15', label: 'Február közepe' },
  { value: '03-15', label: 'Március közepe' },
  { value: '04-15', label: 'Április közepe' },
  { value: '05-15', label: 'Május közepe' },
  { value: '06-15', label: 'Június közepe' },
  { value: '07-15', label: 'Július közepe' },
  { value: '08-15', label: 'Augusztus közepe' },
  { value: '09-15', label: 'Szeptember közepe' },
  { value: '10-15', label: 'Október közepe' },
  { value: '11-15', label: 'November közepe' },
  { value: '12-15', label: 'December közepe' },

  { value: '01-31', label: 'Január vége' },
  { value: '02-28', label: 'Február vége' },
  { value: '03-31', label: 'Március vége' },
  { value: '04-30', label: 'Április vége' },
  { value: '05-30', label: 'Május vége' },
  { value: '06-30', label: 'Június vége' },
  { value: '07-31', label: 'Július vége' },
  { value: '08-31', label: 'Augusztus vége' },
  { value: '09-30', label: 'Szeptember vége' },
  { value: '10-31', label: 'Október vége' },
  { value: '11-30', label: 'November vége' },
  { value: '12-31', label: 'December vége' }]

// eslint-disable-next-line @typescript-eslint/require-await
const isValidDate: ValidatorRule['validator'] = async (rule, value) => {
  if (!moment(`2001-${value as string}`).isValid()) {
    throw new Error('Érvénytelen dátum')
  }
}

const ContentBlockInput: React.FC<ContentBlockInputArgs> = ({ name, options }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [addFn, setAddFn] = useState<((value: ContentBlockInputType, insertIndex?: number) => void) | null>(null)
  const form = useFormInstance()
  const handleCancel = useCallback(() => {
    setIsModalVisible(false)
  }, [setIsModalVisible])

  return <>
        <Form.List name={name} rules={[{ validator: contentBlockValidator }]}>
            {(fields, { add, remove, move }, { errors }) => (
                <>
                    {fields.map(({ key, name: nname, ...restField }, index) => {
                      let kindEl = <></>
                      switch ((form.getFieldValue([...name, nname]) as ContentBlockInputType).kind) {
                        case ContentKindEnum.Simple:
                          kindEl = <Form.Item name={[nname, 'content']} required={true}
                                                  rules={[{ required: true, message: 'Mező megadása kötelező' }]}><TextArea rows={4}/></Form.Item>
                          break
                        case ContentKindEnum.Season:
                          kindEl = <Form.Item shouldUpdate noStyle>
                            {() => <>
                                <Form.Item required={true} label={(form.getFieldValue([...name, nname]) as ContentBlockInputType).name + ' kezdete'} name={[nname, 'seasonFrom']}
                                           rules={[{ required: true, message: 'Mező megadása kötelező', pattern: /^(0[1-9]|10|11|12)(-((0[1-9]|[12]\d)|30|31))?$/ }, { validator: isValidDate }]}>
                                    <AutoComplete options={monthHelp}/>
                                </Form.Item>
                                <Form.Item required={true} label={(form.getFieldValue([...name, nname]) as ContentBlockInputType).name + ' vége'} name={[nname, 'seasonTo']}
                                           rules={[{ required: true, message: 'Mező megadása kötelező', pattern: /^(0[1-9]|10|11|12)(-((0[1-9]|[12]\d)|30|31))?$/ }, { validator: isValidDate }]}>
                                    <AutoComplete options={monthHelp}/>
                                </Form.Item>
                            </>}
                            </Form.Item>
                          break
                      }
                      return (
                            <Card key={key}
                                  title={<Form.Item{...restField} name={[nname, 'name']} style={{ margin: 0 }}
                                                   rules={[{ required: true, message: 'Mező megadása kötelező' }]}><Input
                                      size="large"/></Form.Item>}
                                  extra={<ElMover index={index} name={nname} list={fields} move={move} remove={remove}
                                                  min={0}/>}

                                  style={{ marginBottom: 10 }}>
                                <Form.Item name={[nname, 'kind']} hidden={true}><Input/></Form.Item>

                                <Space key={key} style={{ display: 'block' }}>
                                    {kindEl}
                                </Space>
                            </Card>
                      )
                    })}

                    <Form.Item>
                        <Button type="dashed" onClick={() => {
                          setIsModalVisible(true)
                          setAddFn(() => add)
                        }}
                                icon={<PlusOutlined/>}>Blokk hozzáadása</Button>
                        <Form.ErrorList errors={errors}/>
                    </Form.Item>
                </>
            )}

        </Form.List>

        <Modal title="Blokk hozzáadása" open={isModalVisible} onCancel={handleCancel} footer={null}>
          {options.map((option, index) => (
            <div key={index}>
              {option.options
                ? <Dropdown.Button key={index}
                                   icon={<Icons.DownOutlined/>}
                                   placement="bottomLeft"
                                   arrow={{ pointAtCenter: true }}
                                   overlay={<Menu style={{ maxHeight: 210, overflow: 'scroll', flex: 'auto' }} onClick={ (e) => { addFn?.({ name: option.name, kind: option.kind, content: e.key }); setIsModalVisible(false) }} items={option.options}/>}
                                   onClick={() => { addFn?.({ name: option.name, kind: option.kind }); setIsModalVisible(false) }}
                >{option.name} blokk</Dropdown.Button>
                : <Button key={index} onClick={() => { addFn?.({ name: option.name, kind: option.kind }); setIsModalVisible(false) }}>{option.name} blokk</Button>
              }
            </div>

          ))}
        </Modal>
    </>
}

export default ContentBlockInput

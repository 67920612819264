import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'

import { onError } from '@apollo/client/link/error'
import { handleError } from './handle-error'

const errorLink = onError(handleError)

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API ?? 'http://localhost:8200/query',
  credentials: 'include'
})

const link = from([errorLink, httpLink])

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production',
  credentials: 'include',
  link
})

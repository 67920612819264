import * as lodash from 'lodash'
import { Alert, Form, PageHeader, Typography } from 'antd'
import * as RenderRecipe from './RenderRecipe'
import React from 'react'
import { kv } from '../../../utils/Types'

interface RenderRecipeTitleType {
  params: kv
}

const dottedStyle = { textDecoration: '1px dotted gray underline', marginRight: 5 }

export function RenderRecipeTitle ({ params }: RenderRecipeTitleType) {
  const form = Form.useFormInstance()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  let text: string = form.getFieldValue('dynamicIngredients')?.length > 0 && form.getFieldValue('nameDynamic') ? form.getFieldValue('nameDynamic') : form.getFieldValue('name')
  text = lodash.upperFirst(text)

  return <PageHeader
        title={<RenderRecipe.Title text={text} params={params}
                                   empty={<Alert message="Hiányzó ételnév" type="error"/>}/>}
        extra={[<Typography.Text key={0} type="secondary" style={dottedStyle}>(Kalkulált allergének
            helye)</Typography.Text>]}
    >
    </PageHeader>
}

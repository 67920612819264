import React, { useMemo } from 'react'
import { Card, Form, Input, SelectProps } from 'antd'
import { TagListMiniQuery } from '../../generated/graphql'
import TextArea from 'antd/es/input/TextArea'
import { LSelect } from '../../components/LSelect'
import { MediaDraggerInput } from '../../components/MediaDraggerInput'

const required = { required: true, rules: [{ required: true }] }

export type TagGroupItems = TagListMiniQuery['admin_tag_group_list']['items']

export interface TagEditFormArgs {
  copy?: boolean
  tagGroups: TagGroupItems
}

const TagEditForm: React.FC<TagEditFormArgs> = params => {
  const tagOptions = useMemo<SelectProps['options']>(() => params.tagGroups.map(el => ({
    label: el.name,
    value: el.id
  })), [params])

  return <Card style={{ height: '100%' }}>
        <Form.Item name="name" label="Név" required={true} rules={[{ required: true }, { max: 100 }]}><Input/></Form.Item>
        <Form.Item name="tagGroupId" label="Címkecsoport" {...required}>
            <LSelect showSearch={true} options={tagOptions}/>
        </Form.Item>
        <Form.Item name="description" label="Leírás"><TextArea/></Form.Item>

       <Form.Item name="media" label="Média">
         <MediaDraggerInput aspect={1 / 1}/>
       </Form.Item>

  </Card>
}

export default TagEditForm

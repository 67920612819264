import React from 'react'
import KajaTablePage, { DeleteType, editLink, KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import {
  DirectionEnum,
  useAdmin_Ingredient_Group_DeleteMutation,
  useAdmin_Ingredient_Group_ListLazyQuery
} from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'
import { message, Modal } from 'antd'
import * as Icons from '@ant-design/icons'
import i18n from '../../components/i18n'

type ListType = graphql.IngredientGroupList['items'][number]

const IngredientGroupList: React.FC = (props) => {
  const [fetch] = useAdmin_Ingredient_Group_ListLazyQuery()

  const request: KajaTableRequestType<ListType> = async (params, sort, filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      },
      fetchPolicy: 'network-only'

    })

    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_ingredient_group_list.items as ListType[],
      success: true,
      total: data.admin_ingredient_group_list.total
    }
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Név',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name',
      ...editLink(routes.ingredient_group.edit)
    }
    // {
    //   title: 'Allergének',
    //   width: 140,
    //   dataIndex: 'allergens',
    //   ellipsis: true,
    //   render: (dom, entity) => <>{entity.allergens.map(a => a.name).join(', ')}</>
    // }
  ]
  const [admin_ingredient_group_delete] = useAdmin_Ingredient_Group_DeleteMutation()
  const deleteFn: DeleteType<ListType> = (el, done) => {
    Modal.confirm({
      title: <>Biztosan törölni szeretnéd {i18n.az(el.name)} <b>{el.name}</b> Alapanyag fajtát?</>,
      icon: <Icons.ExclamationCircleOutlined/>,
      async onOk () {
        await admin_ingredient_group_delete({ variables: { id: el.id } })
        void message.info('Törölve.')
        done()
      }
    })
  }

  return (<KajaTablePage<ListType, { search: string }>
        columns={columns}
        labels="Alapanyag fajták"
        label="Alapanyag fajta"
        deleteFn={deleteFn}
        request={request}
        route={routes.ingredient_group}
    />)
}

export default IngredientGroupList

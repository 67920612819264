import { ErrorResponse } from '@apollo/client/link/error'
import { handleLogout } from '../../components/context/UserContext'
import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import lodash from 'lodash'
import React from 'react'

export const ErrorCodes = {
  AuthenticationError: 'unauthorized'
}

const debouncedNetworkErrorNotification = lodash.debounce(() => {
  notification.error({
    message: 'Hálózati hiba.',
    placement: 'top',
    maxCount: 1
  })
}, 500)
const debouncedLoginErrorNotification = lodash.debounce(() => {
  notification.error({
    message: 'Felhasználó nincs belépve, nincs jogosultsága, vagy lejárt a munkamenet.',
    placement: 'top',
    maxCount: 1
  })
}, 500)
export const handleError = (errorResponse: ErrorResponse): void => {
  const { graphQLErrors, networkError, operation } = errorResponse
  if (graphQLErrors) {
    const sentryId = Sentry.captureMessage('GraphQL error', (ctx) => {
      ctx.setExtras({
        graphQLErrors: JSON.stringify(graphQLErrors),
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables)
      })
      ctx.setFingerprint([operation.operationName])

      return ctx
    })

    graphQLErrors.forEach(({ message, locations, path }) => {
      switch (message) {
        case ErrorCodes.AuthenticationError:
          debouncedLoginErrorNotification()
          handleLogout?.()
          break
        default: {
          console.error('[GraphQL error]', {
            message,
            locations,
            path,
            operation
          })

          notification.error({
            message: 'Hiba történt.',
            description: [message, locations, path, sentryId].filter(el => el !== undefined).map(el => typeof el === 'object' ? JSON.stringify(el) : el)
              .map((val, key) => { return <div key={key}>{val}</div> }),
            placement: 'top'
          })
          break
        }
      }
    })
  }

  if (networkError) {
    debouncedNetworkErrorNotification()
    console.error('[Network error]', { networkError })
  }
}

import { kv, RecipeInterface, RecipeTitleInterface } from '../../../utils/Types'
import React from 'react'

function replaceConditions (selectedParameterVariants: kv, withHtml = true, empty = '') {
  const has: { [key: string]: boolean } = {}
  for (const i in selectedParameterVariants) {
    has[String(selectedParameterVariants[i]).trim().toLowerCase().replace(/\W-/, '')] = true
  }
  return function (full: string, cond: string, values: string, body: string) {
    const valueList = values.split(',').map(el => el.trim().toLowerCase().replace(/\W-/, ''))

    let found = false

    const [condNum] = cond.split('-')

    valueList.forEach(value => {
      const [partNum] = value.split('-')
      // eslint-disable-next-line eqeqeq
      if (selectedParameterVariants[condNum] == partNum) {
        found = true
      }
    })

    return found ? (withHtml ? (`<b>${body}</b>`) : body) : empty
  }
}

function replaceIngredients (full: string, val: string): string {
  val = val.replace(/^#\d+#/, '')
  return `<span style="color:brown;text-decoration: underline;text-decoration-style: dashed;">${val}</span>`
}

export const Recipe = function <T>({ recipe, params, empty }: RecipeInterface<T>) {
  const TOKEN_WRAP = '__EOL__'
  // PoC, plz dont use it :)
  recipe = (recipe ?? '').trim().replace(/[<>]/gu, '')
  recipe = recipe.replace(/^\d+ *\./g, '')// remove beginning numbers from recipes.
  recipe = recipe.replace(/^\/\/[^\n]*\n?/gum, '')// replace line comments

  let recipe2
  let i = 0

  do {
    recipe2 = recipe

    recipe = recipe.replace(/^\[([^=\]]*?)=([^\]]+?)\]([^[]*?)\[\/([^\]]*?)\]\n?/gum, replaceConditions(params))
    recipe = recipe.replace(/\[([^=\]]*?)=([^\]]+?)\]([^[]*?)\[\/([^\]]*?)\]/gum, replaceConditions(params))
  } while ((recipe2 !== recipe) && (i++ < 100))
  recipe = recipe
    .replace(/(\r\n|\r|\n){2,}/gu, TOKEN_WRAP)
    .replace(/\{([^{}[\]<>]*)\}/gu, replaceIngredients)
    .replace(/[ \r\n\t]+/gu, ' ')

  recipe = recipe.split(TOKEN_WRAP).filter(el => el.length > 0).map(r => `<li>${r}</li>`).join('')
  if (recipe) {
    recipe = `<ul>${recipe}</ul>`
  }

  const createRecipeMarkup = () => {
    return {
      __html: recipe
    }
  }
  return recipe ? <div dangerouslySetInnerHTML={createRecipeMarkup()}/> : empty
}

export const Title = function <T>({ text, params, empty }: RecipeTitleInterface<T>) {
  // PoC, plz dont use it :)
  text = (text ?? '').replace(/^[# \r\n\t]+|[# \r\n\t]+$/gu, '')
    .replace(/[<>\r\n]/gu, ' ')
    .trim()
  let text2
  let i = 0
  do {
    text2 = text

    text = text.replace(/\[([^=\]]*?)=([^\]]+?)\]([^[]*?)\[\/([^\]]*?)\]/gu, replaceConditions(params, false))
  } while ((text2 !== text) && (i++ < 100))
  text = text.replace(/[ \r\n\t]+/gu, ' ')

  const createRecipeMarkup = () => {
    return {
      __html: text
    }
  }
  return text ? <div dangerouslySetInnerHTML={createRecipeMarkup()}/> : empty
}

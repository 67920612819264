import React, { useCallback, useId, useMemo, useState } from 'react'
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  FormProps,
  Input,
  Menu,
  message,
  Modal,
  PageHeader,
  Space
} from 'antd'
import R from '../resources/strings.json'
import * as Icons from '@ant-design/icons'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ApolloError } from '@apollo/client'
import { huValidateMessages } from '../resources/validation'
import { CrudRoute } from '../utils/Types'
import { InputMaybe } from '../generated/graphql'
import { Callbacks, Store } from 'rc-field-form/lib/interface'
import { RenderBreadcrumb } from './RenderBreadcrumb'
import KajaPage from './KajaPage'
import { useNavigate } from 'react-router-dom'
import { client } from '../graphql/client/graphql-client'
import lodash from 'lodash'

export type KajaEditFormArgs<InputFormat, Values> = Omit<FormProps<Values>,
'name' | 'labelWrap' | 'form' | 'validateMessages' | 'labelCol' | 'autoComplete' | 'onFinish' | 'onFinishFailed'> & {
  children?: React.ReactNode
  label: string
  labels: string
  extra?: JSX.Element
  items?: ItemType[]
  initialValues?: Store | InputFormat
  card?: boolean
  saving?: boolean
  isNew?: boolean
  route: CrudRoute
  saver?: (variables: { variables: {input: InputFormat} }, requestHeaders?: RequestInit['headers']) => Promise<unknown>
  error?: ApolloError
  copy: boolean | undefined
}

interface WithId {
  id?: string | InputMaybe<string> | undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const KajaEditPage: <InputFormat extends WithId, Values = any>(props: KajaEditFormArgs<InputFormat, Values>) => JSX.Element = <InputFormat extends WithId, Values = any>({
  children,
  isNew,
  labels,
  label,
  extra,
  items,
  card,
  saver,
  route,
  error,
  copy,
  ...rest
}: KajaEditFormArgs<InputFormat, Values>) => {
  const [debugModal, setDebugModal] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  // const [saved, setSaved] = useState<boolean>(false)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const formId = useId()

  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: R.title
    },
    {
      path: route.list,
      breadcrumbName: labels
    },
    {
      path: route.edit ?? '',
      breadcrumbName: label
    }
  ]

  const saveWithoutClose = async () => {
    try {
      saveOnFinish(await form.validateFields(), false)
    } catch (err) {
      // eslint-disable-next-line
      onFinishFail(err as any)
    }
  }

  const saveOnFinish = (args: unknown, navigateAway = true) => {
    void (async (saveData: InputFormat) => {
      if (saver == null) return

      if (!saveData.id || (copy ?? false)) {
        delete saveData.id
      }
      setSaving(true)
      try {
        const result = await saver({ variables: { input: saveData } }) as {data: {[key: string]: {id?: string}}}
        await client.clearStore()
        // setSaved(true)
        void message.success('Elmentve.')
        if (navigateAway) {
          navigate(-1)
        } else {
          if (!saveData.id || (copy ?? false)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const id = result.data[lodash.keys(result.data)[0]]?.id

            if (id && route.edit) {
              navigate(route.edit.replace(':id', id))
            } else {
              navigate(-1)
            }
          }
        }
      } finally {
        setSaving(false)
      }
    })(args as InputFormat)
  }

  const onFinishFail = useCallback<NonNullable<Callbacks<Values>['onFinishFailed']>>(err => {
    err.errorFields.forEach(errorField => {
      errorField.errors.length > 0 && message.error(errorField.errors.map((err, key) => <span key={key}>{err}<br/></span>))
      form.scrollToField(errorField.name)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      form.getFieldInstance(errorField.name)?.focus()
    })
  }, [form])

  const onBack = useCallback(() => window.history.back(), [route])

  const pageExtra = useMemo(() => {
    return [<Space key={0} style={{ verticalAlign: 'baseline' }}>
            {extra}
            {process.env.NODE_ENV !== 'production' &&
              <Button onClick={() => setDebugModal(true)} icon={<Icons.QuestionCircleFilled/>}
                      type="dashed" shape="circle"/>}

            <Button disabled={saving || saver === undefined} loading={saving}
                    onClick={() => {
                      void saveWithoutClose()
                    }}
                    type="default" form={formId}
                    icon={<Icons.CheckOutlined/>}>{copy ? 'Másolat mentése ' : isNew ? 'Létrehozás' : 'Mentés'}</Button>

            <Button disabled={saving || saver === undefined} loading={saving}
                    htmlType="submit"
                    type="primary" form={formId}
                    icon={<Icons.CheckOutlined/>}>{copy ? 'Másolat mentése ' : isNew ? 'Létrehozás' : 'Mentés'} és bezárás</Button>
            {items?.length
              ? <Dropdown overlay={<Menu items={items}/>}
                            placement="bottomLeft"><Button><Icons.DownOutlined/></Button></Dropdown>
              : null}
        </Space>]
  }, [extra, setDebugModal, saver, saving, isNew, items])

  const breadcrumbEl = useMemo(() => <Breadcrumb routes={breadcrumb} itemRender={RenderBreadcrumb}/>, [breadcrumb])

  const debugEl = useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      return null
    }
    return <Modal forceRender open={debugModal} onCancel={() => setDebugModal(false)} footer={null}>
            <Form.Item shouldUpdate>
                {() => <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>}
            </Form.Item>
        </Modal>
  }, [form, debugModal, setDebugModal])

  // useBlocker(useCallback((tx) => {
  //   const hasChanged = form.isFieldsTouched()
  //   if ((!hasChanged && !saving) || saved) {
  //     tx.retry()
  //     return
  //   }
  //   Modal.confirm({
  //     icon: <Icons.ExclamationCircleOutlined/>,
  //     title: 'Az oldalon nem mentett változások vannak.',
  //     onOk: () => tx.retry(),
  //     closable: true,
  //     maskClosable: true
  //   })
  // }, [form, saving, saved, setSaving, setSaved]))

  return <>
        <KajaPage title={label}>
            <PageHeader className="site-page-header" onBack={onBack} title={label} breadcrumb={breadcrumbEl}
                        extra={pageExtra}>
                <Form
                    {...rest}
                    scrollToFirstError={true}
                    name={formId}
                    form={form}
                    labelWrap={true}
                    validateMessages={huValidateMessages}
                    labelCol={{ span: 'label' }}
                    autoComplete="off"
                    onFinish={saveOnFinish}
                    onFinishFailed={onFinishFail}

                >
                    <Form.Item label="Recept #" name="id" hidden><Input/></Form.Item>
                    {error != null
                      ? <Alert message="Hiba" description={error?.message} type="error"/>
                      : (card !== false ? <Card>{children}</Card> : children)}
                    {debugEl}
                </Form>
            </PageHeader></KajaPage>
  </>
}

export default KajaEditPage

/**
 * Prefix a word with a/az
 * @param text
 */
const az = (text: string): string => {
  if (text.match(/^[aáeéiíoóöőuúüűy]/i)) {
    return 'az'
  } else if (text.match(/^[bcdfghjklmnpqrstvwxyz]/i)) {
    return 'a'
  } else {
    return 'a(z)'
  }
}
const i18n = {
  az
}

export default i18n

import React from 'react'
import KajaEditPage from '../../components/KajaEditPage'
import { useParams } from 'react-router-dom'
import { routes } from '../../logic/navigation/routes'
import TagEditForm, { TagEditFormArgs } from './TagEditForm'
import KajaErrorPage from '../../components/KajaErrorPage'
import KajaLoadingPage from '../../components/KajaLoadingPage'
import {
  TagInput,
  useAdmin_Tag_GetQuery,
  useAdmin_Tag_Group_ListLazyQuery,
  useAdmin_Tag_UpsertMutation
} from '../../generated/graphql'

interface TagEditArgs {
  copy?: true
}

const getTexts: ({
  params,
  id
}: { params: { copy?: boolean }, id?: string }) => { label: string, labels: string } = ({
  params,
  id
}) => ({
  label: params.copy && id ? 'Címke ' + id + ' másolása' : (id ? 'Címke ' + id + ' szerkesztése' : 'Új címke'),
  labels: 'Címkék'
})

const TagEdit: React.FC<TagEditArgs> = (params) => {
  const { id } = useParams()
  const texts = getTexts({ params, id: `#${id ?? ''}` })
  const [saver] = useAdmin_Tag_UpsertMutation()

  const { loading, error, data } = useAdmin_Tag_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })

  const [fetchTagsGroups, tRes] = useAdmin_Tag_Group_ListLazyQuery({ errorPolicy: 'all' })

  if (tRes.error !== undefined || error !== undefined) {
    return <KajaErrorPage label={texts.label} message="Hiba"
                              description={tRes.error?.message ?? error?.message}
                              details={tRes.error?.graphQLErrors ?? error?.graphQLErrors}

        />
  }
  if (!tRes.loading && !tRes.data) {
    void fetchTagsGroups()
  }

  const tagGroups: TagEditFormArgs['tagGroups'] = tRes.data?.admin_tag_group_list.items ?? []

  if ((id !== undefined && loading)) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.tag}/>
  }
  const initialValues: TagInput = {
    id: data?.admin_tag_get.id ?? undefined,
    name: data?.admin_tag_get.name ?? '',

    description: data?.admin_tag_get.description ?? '',
    displayOrder: data?.admin_tag_get.displayOrder ?? undefined,
    media: data?.admin_tag_get.media ?? [],
    tagGroupId: data?.admin_tag_get.tagGroupId ?? ''
  }

  return <KajaEditPage<TagInput> route={routes.tag} {...getTexts({ params, id: initialValues.name })}
                                   isNew={params.copy ?? !id}
                                   saver={saver}
                                   copy={params.copy}
                                   initialValues={initialValues}
                                   card={false}
    >
        <TagEditForm {...params} {...{ tagGroups }} />
    </KajaEditPage>
}
export default TagEdit

import React from 'react'
import { useParams } from 'react-router-dom'
import KajaLoadingPage from '../../components/KajaLoadingPage'
import KajaErrorPage from '../../components/KajaErrorPage'
import SubstitutionEditForm from './SubstitutionGroupEditForm'
import { routes } from '../../logic/navigation/routes'
import KajaEditPage from '../../components/KajaEditPage'
import {
  SubstitutionGroupInput,
  useAdmin_Substitution_Group_GetQuery,
  useAdmin_Substitution_Group_UpsertMutation,
  useRecipeEditHelpersLazyQuery
} from '../../generated/graphql'

export type FormInterface = SubstitutionGroupInput
//
// export interface FormInterface {
//   cookingTime: CookingTimeInput
//   id?: InputMaybe<Scalars['ID']>
//   ingredients: RecipeIngredientInput[]
//   dynamicIngredients: GroupIngredientInput[]
//   media?: MediaInput[]
//   name: Scalars['String']
//   nameDynamic: Scalars['String']
//   steps: Scalars['String']
//   contentBlocks: ContentBlockInput[]
//   tagGroups: ActiveTagGroupInput[]
//   activeFrom: string | Date
// }

const getTexts: ({ params, id }: { params: any, id?: string }) => { label: string, labels: string } = ({ params, id }) => ({
  label: id ? 'Helyettesítési csoport ' + id + ' szerkesztése' : 'Új helyettesítési csoport',
  labels: 'Helyettesítési csoportok'
})

const SubstitutionGroupEdit: React.FC = (params) => {
  const { id } = useParams()
  // A form mentésére használt mutation
  const [saver] = useAdmin_Substitution_Group_UpsertMutation()
  const texts = getTexts({ params, id })

  // Ha van id paraméter, lekérdezzük az entitás adatait a szervertől
  const { error, loading, data } = useAdmin_Substitution_Group_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })

  // Ez a fetch arra szolgál, hogy az összetevők között keresgéljünk, és hozzá tudjuk adni az entitásunkhoz.
  const [fetch, res] = useRecipeEditHelpersLazyQuery()

  if (error) {
    return <KajaErrorPage label={texts.label} message="Hiba, helyettesítő csoport nem érhető el."
                              description={error.message}/>
  }
  if (res.error) {
    return <KajaErrorPage label={texts.label} message="Hiba, alapanyagok nem érhetőek el."
                              description={res.error.message} error={res}/>
  }
  if (!res.loading && !res.data) {
    void fetch()
  }

  if ((id !== undefined && loading) || res.loading) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.recipe}/>
  }

  // Csak az ingredients-t használjuk a helperfetch eredményéből, mert nekünk itt ez kell.
  const ingredients = res.data?.admin_ingredient_list.items ?? []

  // A mutation input kezdeti értékeit beállítjuk vagy a lekérdezés eredményéből, vagy pedig ha új entitást készítünk, üresen
  const initialValues: FormInterface = {
    id: data?.admin_substitution_group_get.id ?? '',
    name: data?.admin_substitution_group_get.name ?? '',
    ingredientIds: data?.admin_substitution_group_get.ingredients.map((val) => val.id) ?? []
  }

  // KajaEditPage: Egy form keret, ami magától rendezi a kezdeti értékeket, a mentésgombokat és az azokra kötött függvényeket
  // Gyereke egy form, aminek átpasszoljuk a dropdown-ok értékeit adó értékeket
  return <KajaEditPage<FormInterface> route={routes.substitution_group} {...getTexts({ params, id: initialValues.name })}
                         isNew={!id}
                         copy={false}
                         saver={saver}
                         initialValues={initialValues}
                         card={false}>
        <SubstitutionEditForm {...{ ingredients }} {...params} /></KajaEditPage>
}

export default SubstitutionGroupEdit

import { FormListProps } from 'antd/lib/form/FormList'
import { RecipeEditHelpersQuery } from '../../generated/graphql'
import React from 'react'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Form, Input } from 'antd'
import { LSelect } from '../../components/LSelect'
import { FormItemProps } from 'antd/lib/form/FormItem'

interface TagGroupsInputArgs {
  name: FormListProps['name']
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
}
export const TagGroupsInput: React.FC<TagGroupsInputArgs> = ({ name, tagCategories }) => {
  const nameArr: Array<string | number> = (typeof name === 'string' || typeof name === 'number') ? [name] : name
  return <Form.List name={name}>
        {(fields, { add, remove, move }, { errors }) => (
            <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return <TagGroupInputEl key={index} nameArr={nameArr} index= {index} tagCategories= {tagCategories}/>
                })}
            </>
        )}
    </Form.List>
}

interface TagGroupInputElArgs {
  nameArr: Array<string | number>
  index: number
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
  props?: FormItemProps
}

export const TagGroupInputEl: React.FC<TagGroupInputElArgs> = ({ nameArr, index, tagCategories, props }) => {
  const form = useFormInstance()

  const val = form.getFieldValue([...nameArr, index, 'tagGroupId']) as string
  const categ = tagCategories.find((el) => el.id === val)
  if (!categ) { return <></> }
  return <>
    <Form.Item name={[index, 'tagGroupId']} hidden>
      <Input/>
    </Form.Item>
    <Form.Item name={[index, 'tagIds']} label={categ.name} rules={[{ required: categ.categorizable }]} tooltip={categ.description} {...props}>
      <LSelect showSearch allowClear
               mode={categ.multiselect ? 'multiple' : undefined}
               options={categ.tags.map((tag) => ({
                 value: tag.id,
                 label: tag.name
               }))}/>
    </Form.Item>
  </>
}

import React from 'react'
import { Alert, Breadcrumb, PageHeader } from 'antd'
import { GraphQLErrors } from '@apollo/client/errors'
import KajaPage from './KajaPage'
import { RenderBreadcrumb } from './RenderBreadcrumb'
import R from '../resources/strings.json'

export interface KajaPageErrorArgs {
  label: string
  message: string
  description?: string
  details?: GraphQLErrors
  error?: unknown
}

const KajaErrorPage: React.FC<KajaPageErrorArgs> =
    ({ label, message, description, error, details }) => {
      const breadcrumb = [
        {
          path: '/',
          breadcrumbName: R.title
        }
      ]
      return <>
            <KajaPage title={label}>
                <PageHeader
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title={label}
                    subTitle={label}
                    breadcrumb={<Breadcrumb routes={breadcrumb} itemRender={RenderBreadcrumb}/>}
                >
                    <Alert message={message} description={description} type="error"/>
                    {details?.map((err, key) => <Alert key={key} description={
                        <>
                            {err.name}
                            <br/>
                            <code>{err.path?.join('.')}</code>

                        </>} message={err.message}
                                                       type={'warning'}/>)}
                </PageHeader></KajaPage></>
    }
export default KajaErrorPage

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import RecipeList from '../../screens/RecipeList/RecipeList'
import RecipeEdit from '../../screens/RecipeEdit/RecipeEdit'
import IngredientEdit from '../../screens/IngredientEdit/IngredientEdit'
import IngredientList from '../../screens/IngredientList/IngredientList'
import NotFoundPage from '../../screens/Global/NotFoundPage'
import IngredientGroupEdit from '../../screens/IngredientGroupEdit/IngredientGroupEdit'
import IngredientGroupList from '../../screens/IngredientGroupList/IngredientGroupList'
import AllergenList from '../../screens/AllergenList/AllergenList'
import IntoleranceGroupList from '../../screens/IntoleranceGroupList/IntoleranceGroupList'
import UnitList from '../../screens/UnitList/UnitList'
import TagList from '../../screens/TagList/TagList'
import RecipeSuggestionList from '../../screens/RecipeSuggestionList/RecipeSuggestionList'
import TagGroupList from '../../screens/TagGroupList/TagGroupList'
import TagGroupEdit from '../../screens/TagGroupEdit/TagGroupEdit'
import TagEdit from '../../screens/TagEdit/TagEdit'
import Logout from '../../screens/Auth/Logout'
import SubstitutionGroupList from '../../screens/SubstitutionList/SubstitutionList'
import SubstitutionGroupEdit from '../../screens/SubstitutionGroupEdit/SubstitutionGroupEdit'

export const routes = {
  main: '/',
  login: '/login',
  recipe: {
    list: '/recipe/list',
    key: '/recipe/',
    edit: '/recipe/edit/:id',
    copy: '/recipe/copy/:id',
    create: '/recipe/create'
  },
  ingredient: {
    list: '/ingredient/list',
    key: '/ingredient/',
    edit: '/ingredient/edit/:id',
    create: '/ingredient/create'
  },
  ingredient_group: {
    list: '/ingredient_group/list',
    key: '/ingredient_group/',
    edit: '/ingredient_group/edit/:id',
    copy: '/ingredient_group/copy/:id',
    create: '/ingredient_group/create'
  },
  allergen: {
    list: '/allergen/list',
    key: '/allergen/'
  },
  intolerance_group: {
    list: '/intolerance_group/list',
    key: '/intolerance_group/'
  },
  unit: {
    list: '/unit/list',
    key: '/unit/'
    // edit: '/unit/edit/:id',
    // create: '/unit/create'
  },
  tag: {
    list: '/tag/list',
    key: '/tag/',
    edit: '/tag/edit/:id',
    create: '/tag/create',
    copy: '/tag/copy/:id'
  },
  tag_group: {
    list: '/tag_group/list',
    key: '/tag_group/',
    edit: '/tag_group/edit/:id',
    create: '/tag_group/create',
    copy: '/tag_group/copy/:id'
  },
  recipe_suggestion: {
    list: '/recipe_suggestion/list',
    key: '/recipe_suggestion/',
    edit: '/recipe_suggestion/edit/:id',
    create: '/recipe_suggestion/create'
  },
  substitution_group: {
    list: '/substitution_group/list',
    key: '/substitution_group/',
    edit: '/substitution_group/edit/:id',
    create: '/substitution_group/create'
  },
  logout: '/logout'
}

export const SubRoutes = (): JSX.Element => (
    <Routes>
        <Route path={routes.main} element={<Navigate to={routes.recipe.list}/> }/>

        <Route path={routes.recipe.list} element={<RecipeList/>}/>
        <Route path={routes.recipe.edit} element={<RecipeEdit/>}/>
        <Route path={routes.recipe.copy} element={<RecipeEdit copy={true}/>}/>
        <Route path={routes.recipe.create} element={<RecipeEdit/>}/>

        <Route path={routes.ingredient.list} element={<IngredientList/>}/>
        <Route path={routes.ingredient.edit} element={<IngredientEdit/>}/>
        <Route path={routes.ingredient.create} element={<IngredientEdit/>}/>

        <Route path={routes.ingredient_group.list} element={<IngredientGroupList/>}/>
        <Route path={routes.ingredient_group.create} element={<IngredientGroupEdit/>}/>
        <Route path={routes.ingredient_group.edit} element={<IngredientGroupEdit/>}/>
        <Route path={routes.ingredient_group.copy} element={<IngredientGroupEdit copy={true}/>}/>

        <Route path={routes.allergen.list} element={<AllergenList/>}/>
        <Route path={routes.intolerance_group.list} element={<IntoleranceGroupList/>}/>
        <Route path={routes.unit.list} element={<UnitList/>}/>
        <Route path={routes.tag.list} element={<TagList/>}/>
        <Route path={routes.tag.edit} element={<TagEdit/>}/>
        <Route path={routes.tag.create} element={<TagEdit/>}/>
        <Route path={routes.tag.copy} element={<TagEdit copy={true}/>}/>
        <Route path={routes.tag_group.list} element={<TagGroupList/>}/>
        <Route path={routes.tag_group.edit} element={<TagGroupEdit/>}/>
        <Route path={routes.tag_group.create} element={<TagGroupEdit/>}/>
        <Route path={routes.tag_group.copy} element={<TagGroupEdit copy={true}/>}/>
        <Route path={routes.recipe_suggestion.list} element={<RecipeSuggestionList/>}/>

        <Route path={routes.substitution_group.list} element={<SubstitutionGroupList/>}/>
        <Route path={routes.substitution_group.edit} element={<SubstitutionGroupEdit/>}/>
        <Route path={routes.substitution_group.create} element={<SubstitutionGroupEdit/>}/>

        <Route path={routes.logout} element={<Logout/>}/>

        <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
)

import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../../components/context/UserContext'

const Logout: React.FC = () => {
  const { logout } = useContext(UserContext)
  useEffect(logout)
  return <Navigate to={'/'}/>
}

export default Logout

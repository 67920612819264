import React from 'react'
import { Breadcrumb, PageHeader } from 'antd'
import { RenderBreadcrumb } from '../../components/RenderBreadcrumb'
import KajaPage from '../../components/KajaPage'
import R from '../../resources/strings.json'

const NotFoundPage: React.FC = () => {
  const breadcrumb = [
    {
      path: '/index',
      breadcrumbName: R.title
    },
    {
      path: '/first',
      breadcrumbName: '404 - oldal nem található'
    }
  ]
  const title = '404 - oldal nem található'

  return <KajaPage><PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={title}
        breadcrumb={<Breadcrumb routes={breadcrumb} itemRender={RenderBreadcrumb}/>}>
    </PageHeader></KajaPage>
}
export default NotFoundPage

import { escapeRegExp, sanitizeRecipeItem } from './utils'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCodeMirror } from '@uiw/react-codemirror'
import { getLines } from './RecipeEditorHelpers'
import { Button, Dropdown, Menu, MenuProps, Space } from 'antd'
import * as Icons from '@ant-design/icons'
import { MyCodeMirrorType } from './types'
import { EditorView, highlightActiveLine } from '@codemirror/view'
import lodash from 'lodash'
import { parser } from '../../../generated/recipeLang'
import { LRLanguage } from '@codemirror/language'
import { printTree } from '../../../components/recipeLang/playground'

interface KMsimple {data: string}
interface KMwrap {before: string, placeholder: string, after: string}
interface KMType {[key: string]: KMsimple | KMwrap}

export const RecipeCodeMirror: React.FC<MyCodeMirrorType> = ({ completitionData, id, ...rest }) => {
  const editorRef = useRef<HTMLDivElement>(null)
  // const completitions = useMemo(() => myCompletions(completitionData), [completitionData])
  const { setContainer, view } = useCodeMirror({
    container: editorRef.current,
    basicSetup: { lineNumbers: false, rectangularSelection: false, autocompletion: true },
    extensions: [
      EditorView.lineWrapping,
      highlightActiveLine(),
      LRLanguage.define({ parser })
      // highlightSpecialChars({specialChars:/\[[^?]+\]/})
      // autocompletion({activateOnTyping: true, override: [completitions]})
    ],
    ...rest
  })
  useEffect(() => {
    if (editorRef.current) {
      setContainer(editorRef.current)
    }
  }, [editorRef, editorRef.current])

  const addText = useCallback((text: string, lft: string, rht: string) => {
    if (!view) {
      console.error('NO VIEW')
      return
    }

    const { from } = getLines(view.state)

    const fullText = view.state.doc.sliceString(from)

    let delta = 0
    const m = fullText.match(new RegExp(`/^[^${escapeRegExp(lft + rht)}]*?[${escapeRegExp(lft + rht)}]/`))

    if (m && m[0].slice(-1) === rht) {
      delta += m[0].length
    }

    view.dispatch({
      changes: {
        from: from + delta,
        insert: text
      },
      selection: { anchor: from + delta + text.length },
      scrollIntoView: true
    })
    view.focus()
  }, [view])
  const addTextWithSel = useCallback((left: string, placeholder: string, right: string) => {
    if (!view) {
      console.error('NO VIEW')
      return
    }

    const { from, to } = getLines(view.state)
    const sub = view.state.doc.sliceString(from, to)
    if (from !== to) {
      placeholder = sub
    }

    view.dispatch({
      changes: {
        from,
        to,
        insert: left + placeholder + right
      },
      selection: { anchor: from + left.length, head: from + left.length + placeholder.length },
      scrollIntoView: true
    })
    view.focus()
  }, [view])
  // const markIngredient = useCallback(() => {
  //   if (!view) {
  //     return
  //   }
  //
  //   const { from, to } = getLines(view.state)
  //   const sub = view.state.doc.sliceString(from, to)
  //   const text = `{${sub}}`
  //   view.dispatch({
  //     changes: {
  //       from,
  //       to,
  //       insert: text
  //     },
  //     selection: { anchor: to + 1 },
  //     scrollIntoView: true
  //   })
  //   view.focus()
  // }, [view])

  const [visible1, setVisible1] = useState<boolean>(false)
  const [visible2, setVisible2] = useState<boolean>(false)

  const { ingredientMenuItems, variationMenuItems, kMap } = useMemo(() => {
    const kMap: KMType = {}
    const ingredientMenuItems = lodash.uniqWith([
      ...completitionData.ingredients.filter(e => e.ingredientId && e.unitId).map(({ ingredientId }) => {
        const ingredient = completitionData.availableIngredients.find(ing => ing.id === ingredientId)
        return {
          label: ingredient?.name ?? '',
          key: `{#${ingredient?.id ?? ''}# ${ingredient?.name ?? ''}}`
        }
      }),
      ...completitionData.dynamicIngredients.map(({ subgroups, name: sname }) => {
        return (subgroups ?? []).map(({ ingredients, name: gname }) =>
          (ingredients ?? []).filter(e => e.ingredientId && e.unitId).map(({ ingredientId }) => {
            const ingredient = completitionData.availableIngredients.find(ing => ing.id === ingredientId)
            return {
              label: `${ingredient?.name ?? ''} (${sname ?? ''}, ${gname ?? ''})`,
              key: `{#${ingredient?.id ?? ''}# ${ingredient?.name ?? ''}}`
            }
          }).flat(1)).flat(1)
      }).flat(1)
    ], (a, b) => a.key === b.key)
    const variationMenuItems = [
      ...completitionData.dynamicIngredients.map(({ key, name, subgroups }) => {
        return (subgroups ?? []).map(({ name: sname, key: skey }) => {
          const myKey = `S${key}_${skey}`

          kMap[myKey] = { before: `[${key}-${sanitizeRecipeItem(name)}=${skey}-${sanitizeRecipeItem(sname)}]`, after: '[/]', placeholder: ' (Feltételes lépés helye) ' }
          return {
            label: `[${name ?? ''}=${sname ?? ''}]...[/]`,
            key: myKey
          }
        })
      }).flat(1),
      ...completitionData.dynamicIngredients.map(({ key, name, subgroups }) => {
        const myKey = `D${key}`
        kMap[myKey] = {
          data: `
${(subgroups ?? []).map(({ name: sname, key: skey }) =>
              `[${key}-${sanitizeRecipeItem(name)}=${skey}-${sanitizeRecipeItem(sname)}] [/]`).join('\n')}
`
        }

        return {
          label: `[${name ?? ''}=*]...[/]`,
          key: myKey
        }
      })
    ]
    return { ingredientMenuItems, variationMenuItems, kMap }
  }, [completitionData, completitionData.ingredients, completitionData.dynamicIngredients])
  const handleIngredientClick = useCallback<NonNullable<MenuProps['onClick']>>(e => {
    if (e?.item) {
      addText(e.key, '{', '}')
    }

    setVisible1(false)
    setVisible2(false)
  }, [addText, kMap])
  const handleVariationClick = useCallback<NonNullable<MenuProps['onClick']>>(e => {
    if (e?.item && kMap[e.key] !== undefined) {
      const km = kMap[e.key]
      if ('data' in km) {
        addText(km.data, '[', ']')
      } else {
        addTextWithSel(km.before, km.placeholder, km.after)
      }
    }

    setVisible1(false)
    setVisible2(false)
  }, [addText, kMap])

  const ingredientMenu = <Menu onClick={handleIngredientClick} items={ingredientMenuItems}/>
  const variationMenu = <Menu onClick={handleVariationClick} items={variationMenuItems}/>

  return <>
        <Space>
            {/* <Button className="toggle-heading" onClick={toggleHeading}> # </Button> */}
            {/* <Button className="toggle-heading" onClick={markIngredient}> {' { Alapanyag } '} </Button> */}

            <Dropdown disabled={ingredientMenuItems.length === 0} overlay={ingredientMenu} trigger={['click']}
                      onOpenChange={setVisible1} open={visible1}>
                <Button><Space><Icons.PlusOutlined/>Összetevő<Icons.DownOutlined/></Space></Button>
            </Dropdown>
            <Dropdown disabled={variationMenuItems.length === 0} overlay={variationMenu} trigger={['click']}
                      onOpenChange={setVisible2} open={visible2}>
                <Button><Space><Icons.PlusOutlined/>Variáció<Icons.DownOutlined/></Space></Button>
            </Dropdown>
        </Space>
        <div spellCheck="true" ref={editorRef} id={id}/>
    </>
}

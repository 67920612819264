import React from 'react'
import KajaTablePage, { DeleteType, editLink, KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import {
  DirectionEnum,
  useAdmin_Tag_Group_DeleteMutation,
  useAdmin_Tag_Group_ListLazyQuery
} from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'
import { message, Modal, Tooltip } from 'antd'
import * as Icons from '@ant-design/icons'
import i18n from '../../components/i18n'

type ListType = graphql.TagGroupList['items'][number]

const TagGroupList: React.FC = () => {
  const [fetch] = useAdmin_Tag_Group_ListLazyQuery()

  const request: KajaTableRequestType<ListType> = async (params, sort) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      },
      fetchPolicy: 'network-only'
    })
    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_tag_group_list.items as ListType[],
      success: true,
      total: data.admin_tag_group_list.total
    }
  }
  const [admin_tag_group_delete] = useAdmin_Tag_Group_DeleteMutation()

  const deleteFn: DeleteType<ListType> = (el, done) => {
    Modal.confirm({
      title: <>Biztosan törölni szeretnéd {i18n.az(el.name)} <b>{el.name}</b> címkecsoportot, és a hozzá tartozó {el.tags.length} címkét?</>,
      icon: <Icons.ExclamationCircleOutlined/>,
      async onOk () {
        await admin_tag_group_delete({ variables: { id: el.id } })
        void message.info('Törölve.')
        done()
      }
    })
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Címkecsoport neve',
      dataIndex: 'name',
      sorter: 'Name',
      ...editLink(routes.tag_group.edit)
    },
    {
      title: 'Címkék',
      dataIndex: 'tags',
      ellipsis: true,
      render: (dom, entity, index, action, schema) => {
        if (entity.tags.length === 0) {
          return <>0 elem</>
        }
        const menu = <>{entity.tags.map((el, index) => <div key={index}>{el.name}</div>)}</>

        return <Tooltip overlay={menu} placement={'bottomLeft'} mouseLeaveDelay={0} mouseEnterDelay={0}>
          <span>
            {entity.tags.length} elem <Icons.DownOutlined/>
          </span>
                </Tooltip>
      }
    },
    {
      title: 'Főkategória lehet',
      dataIndex: 'categorizable',
      width: 150,
      valueEnum: {
        true: { text: 'Igen', status: 'Success' },
        false: { text: 'Nem', status: 'Error' }
      }
    },
    // {
    //   title: 'Szűrhető',
    //   dataIndex: 'filterable',
    //   width: 150,
    //   valueEnum: {
    //     true: { text: 'Igen', status: 'Success' },
    //     false: { text: 'Nem', status: 'Error' }
    //   }
    // },
    {
      title: 'Több is választható',
      dataIndex: 'multiselect',
      width: 150,
      valueEnum: {
        true: { text: 'Igen', status: 'Success' },
        false: { text: 'Nem', status: 'Error' }
      }
    }
  ]

  return <KajaTablePage<ListType, { search: string }>
        columns={columns}
        labels="Címkecsoportok"
        label="Címkecsoport"
        request={request}
        deleteFn={deleteFn}
        route={routes.tag_group}
    />
}

export default TagGroupList

import { Badge, Button, Card, Form, Input, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  GroupIngredient,
  IngredientListMiniQuery,
  RecipeEditHelpersQuery,
  UnitListMiniQuery
} from '../../../generated/graphql'
import ElMover from '../../../components/ElMover'
import React, { useCallback, useMemo, useState } from 'react'
import lodash from 'lodash'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { SubgroupBlock } from './SubgroupBlock'

interface RecipeEditorParametersType {
  maxParamVariantCount: number
  maxParamCount: number
  ingredients: IngredientListMiniQuery['admin_ingredient_list']['items']
  units: UnitListMiniQuery['admin_unit_list']['items']
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
}

const MAX = 100
export const RecipeEditorParameters: React.FC<RecipeEditorParametersType> = ({
  maxParamVariantCount,
  maxParamCount,
  ingredients,
  units,
  tagCategories
}) => {
  const form = useFormInstance()

  const getGroupIngredients:(() => GroupIngredient[]) = useCallback(() => (form.getFieldValue('dynamicIngredients') as GroupIngredient[]), [form])
  const recalcTotal = useCallback(() => {
    return getGroupIngredients().reduce((acc, el) => el.subgroups.length * acc, 1)
  }, [form, getGroupIngredients])
  const checkRootTotal = useCallback(() => {
    return getGroupIngredients().reduce((acc, el) => el.subgroups.length * acc, 2)
  }, [form, getGroupIngredients])
  const checkElTotal = useCallback((me: number) => {
    return getGroupIngredients().reduce((acc, el, index) => (el.subgroups.length + (index === me ? 1 : 0)) * acc, 1)
  }, [form, getGroupIngredients])
  const [total, setTotal] = useState(recalcTotal)

  const tagCategoryMenu = useMemo<ItemType[]>(() => tagCategories.map((categ, key): ItemType => ({ label: categ.name, key })), [tagCategories])
  return <Form.Item label="Paraméterek">
        <Form.List name="dynamicIngredients">
            {(fieldsA, { add: addA, remove: removeA, move: moveA }, { errors: errorsA }) => <>
                {total > 1 && <Badge.Ribbon text={total} >
                    {fieldsA.map(({ key: keyA, name: nameA, ...restField }, indexA) => {
                      const cardTitle = <Form.Item{...restField} name={[nameA, 'name']} style={{ margin: 0 }}
                                                        rules={[{ required: true, message: 'Mező megadása kötelező' }]}><Input
                                placeholder="Paraméter neve, pl: feltét" size="large"/></Form.Item>
                      const cardMover = <ElMover index={indexA} name={nameA} list={fieldsA} move={moveA}
                                                       remove={ind => { removeA(ind); setTotal(recalcTotal()) }}
                                                       min={0}/>

                      return <Card key={`A${keyA}`} title={cardTitle} extra={cardMover} style={{ marginBottom: 10 }}>
                                <Form.Item{...restField} name={[nameA, 'key']} hidden={true}><Input/></Form.Item>

                                <Space style={{ display: 'block' }}>
                                    <Form.List name={[nameA, 'subgroups']}>
                                        {(fieldsB, { add: addB, remove: removeB, move: moveB }, { errors: errorsB }) => <>
                                            {fieldsB.map(({ key: keyB, name: nameB, ...restField }, indexB) => {
                                              const groupTitle = <Form.Item name={[nameB, 'name']} style={{ margin: 0, marginRight: 10 }}
                                                                              rules={[{ required: true, message: 'Mező megadása kötelező' }]}><Input
                                                    placeholder={'Név'}></Input></Form.Item>
                                              const groupMover = <ElMover name={nameB} index={indexB} list={fieldsB}
                                                                          move={moveB} remove={ind => { removeB(ind); setTotal(recalcTotal()) }} min={2}/>

                                              return <Form.Item shouldUpdate key={`B${keyB}`}>
                                                {() => <SubgroupBlock
                                                             groupTitle={groupTitle}
                                                             groupMover={groupMover}
                                                             restField={restField}
                                                             units={units}
                                                             nameA={nameA}
                                                             nameB={nameB}
                                                             ingredients={ingredients}
                                                             tagCategories={tagCategories}
                                                             menu={tagCategoryMenu}
                                                />}</Form.Item>
                                            })}
                                            <Button type="dashed"
                                                    disabled={fieldsB.length >= maxParamVariantCount || checkElTotal(nameA) > MAX}
                                                    onClick={() => {
                                                      addB({
                                                        key: (lodash.max(lodash.map(fieldsB, 'key')) ?? 0) + 1,
                                                        name: `Opció ${fieldsB.length + 1}`
                                                      }); setTotal(recalcTotal())
                                                    }}
                                                    icon={<PlusOutlined/>} title={`${checkElTotal(nameA)} lehetőség`}>Paraméter-variáns hozzáadása </Button>
                                            <Form.ErrorList errors={errorsB}/>
                                        </>}
                                    </Form.List>
                                </Space>
                            </Card>
                    })}
                    </Badge.Ribbon>}
                    <Form.Item>
                        <Button
                            disabled={fieldsA.length >= maxParamCount || checkRootTotal() > MAX}
                            type="dashed"
                            onClick={() => {
                              const d: GroupIngredient[] = form.getFieldValue('dynamicIngredients') as GroupIngredient[]
                              addA({
                                key: (lodash.max(lodash.map(d, 'key')) ?? 0) + 1,
                                subgroups: [{ name: 'Opció 1', key: 0 }, { name: 'Opció 2', key: 1 }]
                              })
                              setTotal(recalcTotal())
                            }}
                            icon={<PlusOutlined/>} title={`${checkRootTotal()} lehetőség`}>Paraméter hozzáadása </Button>
                        <Form.ErrorList errors={errorsA}/>
                    </Form.Item>
                </>}

        </Form.List>
    </Form.Item>
}

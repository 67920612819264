import React from 'react'
import { Breadcrumb, PageHeader, Skeleton } from 'antd'
import KajaPage from './KajaPage'
import { RenderBreadcrumb } from './RenderBreadcrumb'
import R from '../resources/strings.json'
import { CrudRoute } from '../utils/Types'

export interface KajaLoadingPageArgs {
  label: string
  labels: string
  route: CrudRoute
}

const KajaLoadingPage: React.FC<KajaLoadingPageArgs> = ({ label, route, labels }) => {
  const breadcrumb = [
    {
      path: '/',
      breadcrumbName: R.title
    },
    {
      path: route.list,
      breadcrumbName: labels
    },
    {
      path: route.edit ?? '',
      breadcrumbName: label
    }
  ]

  return <>
        <KajaPage title={label}>
            <PageHeader
                className="site-page-header"
                onBack={() => window.history.back()}
                title={label}
                subTitle={label}
                breadcrumb={<Breadcrumb routes={breadcrumb} itemRender={RenderBreadcrumb}/>}
            >
                <Skeleton active/>
            </PageHeader></KajaPage></>
}
export default KajaLoadingPage

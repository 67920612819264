import { Form, Input, InputNumber, Space } from 'antd'
import { IngredientSelect } from './IngredientSelect'
import React, { useCallback, useMemo, useRef } from 'react'
import { InternalNamePath } from 'rc-field-form/lib/interface'
import { UnitListMiniQuery } from '../../../generated/graphql'
import { LSelect } from '../../../components/LSelect'

type SubstitutionRowArgs = Omit<typeof Form.Item, 'useStatus'> & {
  name: number | string
  children?: React.ReactNode
  ingredients: Array<{ id: string, name: string, defaultRecipeUnitId: string }>
  parents: InternalNamePath
  units: UnitListMiniQuery['admin_unit_list']['items']
  add: () => void
}
const style = { margin: 0, maxWidth: '100%' }
const spaceStyle = { display: 'flex', maxWidth: '100%' }

export const SubstitutionRowInputs: React.FC<SubstitutionRowArgs> = (
  { name, add, parents, children, ingredients, units, ...restField }
) => {
  const form = Form.useFormInstance()
  const qnRef = useRef<HTMLInputElement>(null)
  const onIngredientSelect = useCallback((sel: unknown) => {
    const ingredient = ingredients.find((ing) => ing.id === sel)
    if (ingredient) {
      if (!form.getFieldValue([...parents, name, 'unitId'])) {
        form.setFieldValue([...parents, name, 'unitId'], ingredient.defaultRecipeUnitId)
      }
      qnRef.current?.focus()
      qnRef.current?.select()
    }
  }, [ingredients, parents, name, qnRef, form])
  const addNextFieldOnTab = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Tab' && !e.shiftKey) {
      add()
    }
  }, [add])
  const unitOptions = useMemo(() => units.map((unit) => ({ label: unit.name, value: unit.id })), [units])
  return <Space size={1} style={spaceStyle}>
        <Form.Item {...restField} name={[name, 'ingredientId']} noStyle style={style} rules={[{ required: true, message: 'Minden alapanyag megadása kötelező, a felesleges sorok pedig törlendőek.' }]}>
            <IngredientSelect ingredients={ingredients} onSelect={onIngredientSelect}/>
        </Form.Item>
        <Form.Item {...restField} name={[name, 'quantity']} noStyle style={style} rules={[{ required: true, message: 'Minden mennyiség megadása kötelező, a felesleges sorok pedig törlendőek.' }]}>
            <InputNumber ref={qnRef} placeholder="1" style={{ width: 50 }} controls={false} min={0}/>
        </Form.Item>
        <Form.Item {...restField} name={[name, 'unitId']} noStyle style={style}>
            <LSelect showSearch placeholder="Mértékegység" allowClear={false} style={{ width: 90 }}
                     onInputKeyDown={addNextFieldOnTab}
                     options={unitOptions}
            />
        </Form.Item>
        <Form.Item {...restField} name={[name, 'note']} rules={[{ max: 100 }]} noStyle style={style}><Input placeholder="..." tabIndex={-1}/></Form.Item>
        {children}
    </Space>
}

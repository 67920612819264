import React from 'react'
import { useParams } from 'react-router-dom'
import KajaLoadingPage from '../../components/KajaLoadingPage'
import KajaErrorPage from '../../components/KajaErrorPage'
import RecipeEditForm from './RecipeEditForm'
import { routes } from '../../logic/navigation/routes'
import KajaEditPage from '../../components/KajaEditPage'
import {
  Admin_Recipe_GetQuery,
  RecipeEditHelpersQuery,
  RecipeInput,
  useAdmin_Recipe_GetQuery,
  useAdmin_Recipe_UpsertMutation,
  useRecipeEditHelpersLazyQuery
} from '../../generated/graphql'
import moment from 'moment'

export type FormInterface = RecipeInput
//
// export interface FormInterface {
//   cookingTime: CookingTimeInput
//   id?: InputMaybe<Scalars['ID']>
//   ingredients: RecipeIngredientInput[]
//   dynamicIngredients: GroupIngredientInput[]
//   media?: MediaInput[]
//   name: Scalars['String']
//   nameDynamic: Scalars['String']
//   steps: Scalars['String']
//   contentBlocks: ContentBlockInput[]
//   tagGroups: ActiveTagGroupInput[]
//   activeFrom: string | Date
// }

export interface RecipeEditArgs {
  maxParamCount?: number
  maxParamVariantCount?: number
  copy?: boolean
}

const getTexts: ({ params, id }: { params: { copy?: boolean }, id?: string }) => { label: string, labels: string } = ({ params, id }) => ({
  label: params.copy && id ? 'Recept ' + id + ' másolása' : (id ? 'Recept ' + id + ' szerkesztése' : 'Új recept'),
  labels: 'Receptek'
})

type FixTcType = (groups: Admin_Recipe_GetQuery['admin_recipe_get']['tagGroups'], tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']) => FormInterface['tagGroups']

const fixTc: FixTcType = (groups, tagCategories) => tagCategories.map(tc => ({
  tagGroupId: tc.id,
  tagIds: groups.find(tag => +tag.tagGroupId === +tc.id)?.tagIds ?? []
}))

const RecipeEdit: React.FC<RecipeEditArgs> = (params) => {
  const { id } = useParams()
  const [saver] = useAdmin_Recipe_UpsertMutation()
  const texts = getTexts({ params, id })

  const { error, loading, data } = useAdmin_Recipe_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })

  const [fetch, res] = useRecipeEditHelpersLazyQuery()

  if (error) {
    return <KajaErrorPage label={texts.label} message="Hiba, receptek nem érhetőek el."
                              description={error.message}/>
  }
  if (res.error) {
    return <KajaErrorPage label={texts.label} message="Hiba, alapanyagok nem érhetőek el."
                              description={res.error.message} error={res}/>
  }
  if (!res.loading && !res.data) {
    void fetch()
  }

  if ((id !== undefined && loading) || res.loading) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.recipe}/>
  }

  const ingredients = res.data?.admin_ingredient_list.items ?? []
  const units = res.data?.admin_unit_list.items ?? []
  const tagCategories = res.data?.admin_tag_group_list.items ?? []

  // @ts-expect-error date/moment conflict, but its okay
  const activeFrom: FormInterface['activeFrom'] = data?.admin_recipe_get.activeFrom && typeof (data?.admin_recipe_get.activeFrom as unknown) === 'string' ? (moment(data?.admin_recipe_get.activeFrom) as unknown) : data?.admin_recipe_get.activeFrom
  const initialValues: FormInterface = {
    id: data?.admin_recipe_get.id ?? '',
    name: data?.admin_recipe_get.name ?? '',
    steps: data?.admin_recipe_get.steps ?? '',
    cookingTime: {
      minutesMax: data?.admin_recipe_get.cookingTime.minutesMax ?? 30,
      minutesMin: data?.admin_recipe_get.cookingTime.minutesMin ?? 30
    },
    ingredients: data?.admin_recipe_get.ingredients ?? [],
    dynamicIngredients: data?.admin_recipe_get.dynamicIngredients ?? [],
    nameDynamic: data?.admin_recipe_get.nameDynamic ?? '',
    contentBlocks: data?.admin_recipe_get.contentBlocks ?? [],
    tagGroups: fixTc(data?.admin_recipe_get.tagGroups ?? [], tagCategories),
    media: data?.admin_recipe_get.media ?? [],
    activeFrom
  }

  return <KajaEditPage<FormInterface> route={routes.recipe} {...getTexts({ params, id: initialValues.name })}
                         isNew={params.copy ?? !id}
                         copy={params.copy}
                         saver={saver}
                         initialValues={initialValues}
                         card={false}>
        <RecipeEditForm {...{ ingredients, units, tagCategories }} {...params} /></KajaEditPage>
}

export default RecipeEdit

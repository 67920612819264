import { EditorView } from '@codemirror/view'
import { CDtype, Completion } from './types'
import { EditorSelection, SelectionRange } from '@codemirror/state'
import { CompletionContext } from '@codemirror/autocomplete'

export function sanitizeRecipeItem (s: string | undefined | null): string {
  return s?.replace(/[=[\]-]/g, '') ?? ''
}

export function escapeRegExp (text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export const insertWrap = (begin: string, end: string) => (view: EditorView, completion: Completion, from: number, to: number): void => {
  const state = view.state
  view.dispatch(state.changeByRange((range: SelectionRange) => {
    if (range === state.selection.main) {
      return {
        changes: { from, to, insert: begin + end },
        range: EditorSelection.cursor(from + begin.length - to)
      }
    }
    const len = to - from
    if (!range.empty ||
            (len && state.sliceDoc(range.from - len, range.from) !== state.sliceDoc(from, to))) {
      return { range }
    }
    return {
      changes: { from: range.from - len, to: range.from, insert: begin + end },
      range: EditorSelection.cursor(begin.length + range.from + from - to - 1)
    }
  }))
  view.focus()
}
export const insertCb = (cb: () => string) => (view: EditorView, completion: Completion, from: number, to: number): void => {
  const state = view.state
  const text = cb()
  view.dispatch(state.changeByRange((range: SelectionRange) => {
    if (range === state.selection.main) {
      return {
        changes: { from, to: to + 1, insert: text },
        range: EditorSelection.cursor(from + text.length)
      }
    }
    const len = to - from
    if (!range.empty || (len && state.sliceDoc(range.from - len, range.from) !== state.sliceDoc(from, to))) {
      return { range }
    }
    return {
      changes: { from: range.from - len, to: range.from, insert: text },
      range: EditorSelection.cursor(range.from - len + 1)
    }
  }))
  view.focus()
}

export const myCompletions = (completitionData: CDtype) => {
  return (context: CompletionContext) => {
    const word = context.matchBefore(/[\w[\]=/]*/)
    if (word === null || (word.from === word.to && !context.explicit)) {
      return null
    }

    const completeDynamicItems: Completion[] = completitionData.dynamicIngredients.map(({
      key,
      name,
      subgroups
    }) => {
      return (subgroups ?? []).map(({ name: sname, key: skey }) => ({
        label: `[${name ?? ''}=${sname ?? ''}]...[/]`,
        apply: insertWrap(`[${key}-${sanitizeRecipeItem(name)}=${skey}-${sanitizeRecipeItem(sname)}]`, '[/]')
      }))
    }).flat(1)

    const completeDynamicGroups: Completion[] = completitionData.dynamicIngredients.map(({
      key,
      name,
      subgroups
    }) => {
      return {
        label: `[${name ?? ''}=*]#...[/]`,
        apply: insertCb(() =>
          (subgroups ?? []).map(({ name: sname, key: skey }) =>
                        `[${key}-${sanitizeRecipeItem(name)}=${skey}-${sanitizeRecipeItem(sname)}]# [/]`).join('\n'))
      }
    })

    const completeIngredients: Completion[] = completitionData.ingredients.map(({ ingredientId }) => {
      const ingredient = completitionData.availableIngredients.find(ing => ing.id === ingredientId)
      return {
        label: `{${ingredient?.name ?? ''}}`,
        apply: insertWrap(`{${ingredient?.name ?? ''}}`, '')
      }
    })
    const completeDynamicIngredients: Completion[] =
            completitionData.dynamicIngredients.map(({ subgroups, name: sname }) =>
              (subgroups ?? []).map(({ ingredients, name: gname }) =>
                (ingredients ?? []).map(({ ingredientId }) => {
                  const ingredient = completitionData.availableIngredients.find(ing => ing.id === ingredientId)
                  return {
                    label: `{${ingredient?.name ?? ''}} (${sname ?? ''}, ${gname ?? ''})}`,
                    apply: insertWrap(`{${ingredient?.name ?? ''}}`, '')
                  }
                }).flat(1)).flat(1)).flat(1)

    return {
      from: word.from,
      options: [
        ...completeDynamicGroups,
        ...completeDynamicItems,
        ...completeIngredients,
        ...completeDynamicIngredients
      ]
    }
  }
}

import { Select } from 'antd'
import React, { useCallback } from 'react'
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select'
import { SelectProps } from 'antd/lib/select'

function similar (a: string, b: string) {
  let equivalency = 0
  const minLength = (a.length > b.length) ? b.length : a.length
  const maxLength = (a.length < b.length) ? b.length : a.length
  for (let i = 0; i < minLength; i++) {
    if (a[i] === b[i]) {
      equivalency++
    }
  }
  return equivalency / maxLength
}

export type LSelectArgs = Omit<SelectProps, 'filterOption' | 'children' | 'optionFilterProp'>

export const LSelect: React.FC<LSelectArgs> = (props) => {
  const filterOption = useCallback<FilterFunc<DefaultOptionType>>((inputValue, option) => {
    if (option?.value?.toString() === inputValue) {
      return true
    }
    if (option?.label === undefined) {
      return false
    }

    if (typeof option?.label === 'string' || typeof option?.label === 'number') {
      if (`${option?.label}`.startsWith(inputValue)) {
        return true
      }

      const val = `${option?.label}`.normalize('NFD').toLowerCase().replace(/\p{Diacritic}/gu, '')

      const search = inputValue.normalize('NFD').toLowerCase().replace(/\p{Diacritic}/gu, '')

      if (val.startsWith(search)) {
        return true
      }
      if (search.length > 3) {
        return similar(val, search) >= 0.6
      }
      return false
    }

    return true
  }, [])
  return <Select filterOption={filterOption} {...props}/>
}

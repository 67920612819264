import React from 'react'
import { Card, Col, Form, Input, Row } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { MediaDraggerInput } from '../../components/MediaDraggerInput'

export interface IngredientGroupEditFormArgs {
  copy?: boolean
}

function IngredientGroupEditForm<InputType> (params: IngredientGroupEditFormArgs) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const form: FormInstance<InputType> = useFormInstance()

  return <>
        <Row gutter={[16, 16]} align={'stretch'}
             style={{ alignItems: 'stretch', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Col sm={24}>
                <Card style={{ height: '100%' }}>

                    <Form.Item name="name" label="Név" required={true} rules={[{ required: true }, { max: 100 }]}><Input/></Form.Item>

                    <Form.Item name="media" label="Média">
                        <MediaDraggerInput aspect={1 / 1}/>
                    </Form.Item>
                </Card>
            </Col>
        </Row>
    </>
}

export default IngredientGroupEditForm

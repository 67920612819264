import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

export const RecipeUsageHelpModal: React.FC = () => {
  return <>
        <ol>
            <li>Az egyes elkészítés lépéseket üres sorral kell elválasztani.</li>
            <ol>
                <li>Próbáld meg kb 5-15 lépés között tartani</li>
            </ol>
            <li>Az alapanyagokat <Text code>{'{}'}</Text>-be kell rakni.</li>
            <ol>
                <li>Az alapanyag ékezeteit megváltoztathatod, hogy helyes legyen</li>
                <li>Próbáld meg tartani magad a felsorolt hozzávalókhoz!</li>
            </ol>
            <li>A variációkat <Text code>[]</Text> jelekkel lehet jelezni.</li>
            <ol>
                <li>A rendszer a kötőjel előtti számokat veszi figyelembe.</li>
                <li>Az egyenlőségjel után fel lehet több számot is sorolni, vesszővel elválasztva. pl: <Text
                    code>[0-feltét=1-sajt,2-tejföl]</Text></li>
            </ol>
        </ol>
    </>
}

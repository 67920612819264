import React from 'react'
import { Card, Checkbox, Col, Form, Input, Row } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import TextArea from 'antd/es/input/TextArea'

export interface TagGroupEditFormArgs {
  copy?: boolean
}

function TagGroupEditForm<InputType> (params: TagGroupEditFormArgs) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const form: FormInstance<InputType> = useFormInstance()
  return <>
        <Row gutter={[16, 16]} align={'stretch'}
             style={{ alignItems: 'stretch', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Col sm={24}>
                <Card style={{ height: '100%' }}>

                    <Form.Item name="name" label="Név" required={true} rules={[{ required: true }, { max: 100 }]}><Input/></Form.Item>
                    <Form.Item name="description" label="Leírás"><TextArea/></Form.Item>
                    <Form.Item label="Főkategória lehet" name="categorizable" valuePropName="checked"><Checkbox
                        value={true}/></Form.Item>
                    <Form.Item label="Több is választható" name="multiselect" valuePropName="checked" tooltip={<>Ha be van jelölve, akkor egy recepthez ebből bármennyi hozzátársítható.<hr/>Ha nincs bejelölve, akkor egy recepthez maximum 1 jelölhető.</>}><Checkbox
                        value={true}/></Form.Item>
                    <Form.Item label="Szűrhető" name="filterable" valuePropName="checked" hidden={true}><Checkbox
                        value={true}/></Form.Item>

                </Card>
            </Col>
        </Row>
    </>
}

export default TagGroupEditForm

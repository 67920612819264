import React from 'react'
import { Form } from 'antd'
import ErrorList from 'antd/es/form/ErrorList'
import { RecipeCodeMirror } from './RecipeCodeMirror'
import { RecipeEditFormArgs } from '../RecipeEditForm'
import { CDtype } from './types'
import { UseCodeMirror } from '@uiw/react-codemirror'

type RecipeTextEditorArgs = UseCodeMirror & {
  ingredients: RecipeEditFormArgs['ingredients']
}

export const RecipeTextEditor: React.FC<RecipeTextEditorArgs> = ({ ingredients, ...rest }) => {
  const form = Form.useFormInstance()

  const completitionData: CDtype = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ingredients: form.getFieldValue('ingredients') ?? [],
    availableIngredients: ingredients,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    dynamicIngredients: form.getFieldValue('dynamicIngredients') ?? []
  }
  return <>
        <RecipeCodeMirror {...rest} completitionData={completitionData} height="80vh" placeholder="(Recept megadása)"/>
        <ErrorList className={'ant-form-item-explain-error'}
                   errors={form.getFieldsError(['steps']).map(x => x.errors.join(', '))}/>
    </>
}

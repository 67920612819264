import React from 'react'
import KajaTablePage, { DeleteType, editLink, KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import { routes } from '../../logic/navigation/routes'
import * as graphql from '../../generated/graphql'
import { useAdmin_Substitution_Group_DeleteMutation, useAdmin_Substitution_Group_ListLazyQuery, DirectionEnum } from '../../generated/graphql'
import lodash from 'lodash'
import { message, Modal, Tag } from 'antd'
import * as Icons from '@ant-design/icons'
import i18n from '../../components/i18n'

type ListType = graphql.Admin_Substitution_Group_ListQuery['admin_substitution_group_list']['items'][number]

const SubstitutionGroupList: React.FC = () => {
  const [fetch] = useAdmin_Substitution_Group_ListLazyQuery()

  const request: KajaTableRequestType<ListType> = async (params, sort, filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      },
      fetchPolicy: 'network-only'
    })

    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_substitution_group_list.items,
      success: true,
      total: data.admin_substitution_group_list.total
    }
  }

  const [admin_substitution_group_delete] = useAdmin_Substitution_Group_DeleteMutation()

  const deleteFn: DeleteType<ListType> = (el, done) => {
    Modal.confirm({
      title: <>Biztosan törölni szeretnéd {i18n.az(el.name)} <b>{el.name}</b> csoportot?</>,
      icon: <Icons.ExclamationCircleOutlined/>,
      async onOk () {
        await admin_substitution_group_delete({ variables: { id: el.id } })
        void message.info('Törölve.')
        done()
      }
    })
  }

  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Csoport neve',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name',
      ...editLink(routes.substitution_group.edit)
    },
    {
      title: 'Alapanyagok',
      dataIndex: 'ingredients',
      align: 'left',
      width: 300,
      ellipsis: true,
      render: (node, el: ListType) => <>
        {el.ingredients.length} db:
        {el.ingredients.map((ing, key) =>
            <Tag key={key}>
              {ing.name}
            </Tag>
        )}</>
    }
  ]

  return (<KajaTablePage<ListType, { search: string }>
    columns={columns}
    labels="Helyettesítési csoportok"
    label="Helyettesítési csoport"
    request={request}
    deleteFn={deleteFn}
    route={routes.substitution_group}
/>)
}

export default SubstitutionGroupList

import { Button } from 'antd'
import * as Icons from '@ant-design/icons'
import { FormListOperation } from 'antd/lib/form/FormList'
import ButtonGroup from 'antd/es/button/button-group'
import React from 'react'

type ElMoverArgs = Omit<FormListOperation, 'add'> & {
  list: unknown[]
  name: number[] | number
  index: number
  min?: number
}

const ElMover: React.FC<ElMoverArgs> = function ({ list, name, index, move, remove, min = 0 }) {
  return <>
        <ButtonGroup>
            <Button type="text" size="small" icon={<Icons.MinusCircleOutlined/>}
                    disabled={list.length - 1 < min}
                    tabIndex={-1}
                    onClick={() => remove(name)}/>
            <Button type="text" size="small" icon={<Icons.ArrowUpOutlined/>}
                    disabled={index === 0}
                    tabIndex={-1}
                    onClick={() => move(index, index - 1)}/>
            <Button type="text" size="small" icon={<Icons.ArrowDownOutlined/>} disabled={index >= list.length - 1}
                    tabIndex={-1}
                    onClick={() => move(index, index + 1)}/>
        </ButtonGroup>
    </>
}

export default ElMover

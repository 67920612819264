import { styleTags, tags as t } from '@lezer/highlight'

export const jsonHighlighting = styleTags({
  ConditionBegin: t.bracket,
  ConditionEnd: t.bracket,
  IngredientName: t.string,
  IngredientId: t.comment,
  Text: t.literal

})

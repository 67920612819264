import { ContentKindEnum } from '../../generated/graphql'

export interface ContentBlockElemType {
  name: string
  kind: ContentKindEnum
  options?: Array<{label: string, key: string}>
}

export const ContentBlockTarolasModja: ContentBlockElemType = {
  kind: ContentKindEnum.Simple,
  name: 'Tárolás módja',
  options: [
    {
      label: 'Húsleves, Hűtve (2-5 °C) 1-2 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 1-2 napig, fagyasztóban (-18 °C) 2-3 hónapig, de a húst és a zöldséget külön edénybe tegyük!'
    },
    {
      label: 'Húsleves, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztóban (-18 °C) 2-3 hónapig, de a húst és a zöldséget külön edénybe tegyük!'
    },
    {
      label: 'Húsleves, Hűtve (2-5 °C) 3-4 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztóban (-18 °C) 2-3 hónapig, de a húst és a zöldséget külön edénybe tegyük!'
    },
    {
      label: 'Leves, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztóban (-18 °C) 4-6 hónapig, de a zöldséget külön edénybe tegyük! '
    },
    {
      label: 'Főtt húsok, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztóban (-18 °C) 2-3 hónapig, de érdemes a gyorsabb hűlés és a könnyebb felhasználhatóság miatt kisebb darabokra vágva eltenni.'
    },
    {
      label: 'Sült csirke, Hűtve (2-5 °C) 3-4 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztóban (-18 °C) 4 hónapig'
    },
    {
      label: 'Húsos ételek, Hűtve (2-5 °C) 3-4 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztóban (-18 °C) 2 hónapig'
    },
    {
      label: 'Szószos tésztaételek, Hűtve (2-5 °C) 1-2 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 1-2 napig, fagyasztóban (-18 °C) 1 hónapig'
    },
    {
      label: 'Sima főtt tészta, Hűtve (2-5 °C) 3-4 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztása nem ajánlott'
    },
    {
      label: 'Pizza, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztóban (-18 °C) 3 hónapig'
    },
    {
      label: 'Szendvicsek, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztóban (-18 °C) 1 hónapig'
    },
    {
      label: 'Főtt keménytojás, Hűtve (2-5 °C) 5-7 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 5-7 napig, fagyasztása nem ajánlott'
    },
    {
      label: 'Kaszinótojás, Hűtve (2-5 °C) 2-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 2-3 napig, fagyasztása nem ajánlott'
    },
    {
      label: 'Tojásos ételek, Hűtve (2-5 °C) 3-4 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztóban (-18 °C) 1 hónapig'
    },
    {
      label: 'Saláták sonkával, csirkehússal, tonhallal, Hűtve...',
      key: 'Hűtőszekrényben (2-5 °C) 3-4 napig, fagyasztása nem ajánlott.'
    },
    {
      label: 'Rakott ételek, Hűtve (2-5 °C) 1-2 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 1-2 napig, fagyasztóban (-18 °C) 1 hónapig.'
    },
    {
      label: 'Sütemények és piték, Hűtve (2-5 °C) 1-3 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 1-3 napig, fagyasztóban (-18 °C) 4-6 hónapig (sütemény jellegétől rövidülhet pl. habossütemény csak 1-2 napig áll el a hűtőben, fagyasztása nem javasolt'
    },
    {
      label: 'Gofri, fánk, Hűtve (2-5 °C) 4-5 napig...',
      key: 'Hűtőszekrényben (2-5 °C) 4-5 napig, fagyasztóban (-18 °C) 1 hónapig'
    },
    {
      label: 'Frissen fogyasztható...',
      key: 'Ha nem fogyasztjátok el a napokban, érdemes azonnal a mélyhűtőbe rakni, ahol 1-2 hétig megőrzi az élvezeti értékét'
    }
  ]
}
export const ContentBlockTalalasModja: ContentBlockElemType = {
  kind: ContentKindEnum.Simple,
  name: 'Tálalás módja'
}

export const ContentBlockSeason: ContentBlockElemType = {
  kind: ContentKindEnum.Season,
  name: 'Szezon'
}

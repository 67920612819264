import React, { useMemo } from 'react'
import KajaEditPage from '../../components/KajaEditPage'
import { useParams } from 'react-router-dom'
import { routes } from '../../logic/navigation/routes'
import IngredientEditForm, { IngredientEditFormArgs } from './IngredientEditForm'
import {
  IngredientInput,
  useAdmin_Ingredient_GetQuery,
  useAdmin_Ingredient_UpsertMutation,
  useIngredientEditHelpersLazyQuery
} from '../../generated/graphql'
import KajaErrorPage from '../../components/KajaErrorPage'
import KajaLoadingPage from '../../components/KajaLoadingPage'

interface IngredientEditArgs {
  copy?: true
}

const getTexts: ({
  params,
  id
}: { params: { copy?: boolean }, id?: string }) => { label: string, labels: string } = ({
  params,
  id
}) => ({
  label: params.copy && id ? 'Alapanyag ' + id + ' másolása' : (id ? 'Alapanyag ' + id + ' szerkesztése' : 'Új alapanyag'),
  labels: 'Alapanyagok'
})

const IngredientEdit: React.FC = (params: IngredientEditArgs) => {
  const { id } = useParams()
  const texts = getTexts({ params, id })
  const [saver] = useAdmin_Ingredient_UpsertMutation()

  const { loading, error, data } = useAdmin_Ingredient_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })
  const [dataQuery, dataRes] = useIngredientEditHelpersLazyQuery()

  const units: IngredientEditFormArgs['units'] = dataRes.data?.admin_unit_list.items ?? []
  const ingredientGroups: IngredientEditFormArgs['ingredientGroups'] = dataRes.data?.admin_ingredient_group_list.items ?? []
  const allergens: IngredientEditFormArgs['allergens'] = dataRes.data?.admin_allergen_list.items ?? []

  const d = useMemo(() => ({
    availableShoppingUnitIds: units.filter(el => el.name === 'g' || el.name === 'kg' || el.name === 'db').map(el => el.id),
    defaultRecipeUnitId: units.filter(el => el.name === 'kg').map(el => el.id)[0],
    defaultShoppingUnitId: units.filter(el => el.name === 'kg').map(el => el.id)[0]
  }), [units])

  if (dataRes.error ?? error) {
    return <KajaErrorPage label={texts.label} message="Hiba"
                          description={dataRes.error?.message ?? error?.message}
                          details={dataRes.error?.graphQLErrors ?? error?.graphQLErrors}

    />
  }
  if (!dataRes.loading && !dataRes.data) {
    void dataQuery()
  }

  const initialValues: IngredientInput = {
    allergens: data?.admin_ingredient_get.allergens.map(el => el.id) ?? [],
    availableShoppingUnitIds: data?.admin_ingredient_get.availableShoppingUnits.map(el => el.id) ?? d.availableShoppingUnitIds,
    calculatedStats: data?.admin_ingredient_get.calculatedStats ?? {},
    contentBlocks: data?.admin_ingredient_get.contentBlocks ?? [],
    defaultRecipeUnitId: data?.admin_ingredient_get.defaultRecipeUnitId ?? d.defaultRecipeUnitId,
    defaultShoppingUnitId: data?.admin_ingredient_get.defaultShoppingUnitId ?? d.defaultShoppingUnitId,
    groupId: data?.admin_ingredient_get.groupId ?? '',
    id: data?.admin_ingredient_get.id ?? undefined,
    implicitlyAvailable: data?.admin_ingredient_get.implicitlyAvailable ?? false,
    initialIngredient: data?.admin_ingredient_get.initialIngredient ?? false,
    media: data?.admin_ingredient_get.media ?? undefined,
    name: data?.admin_ingredient_get.name ?? '',
    pricePerGram: data?.admin_ingredient_get.pricePerGram ?? undefined,
    priceSpread: data?.admin_ingredient_get.priceSpread ?? undefined,
    unitQuantities: data?.admin_ingredient_get.unitQuantities.map((el: { unitId: string, asGram?: number | null }) => {
      return ({ unitId: el.unitId, asGram: el.asGram ?? 0 })
    }) ?? [],
    visible: data?.admin_ingredient_get.visible ?? true
  }

  if (dataRes.loading || dataRes?.data === undefined || loading) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.ingredient}/>
  }

  return <KajaEditPage<IngredientInput> route={routes.ingredient} {...getTexts({ params, id: initialValues.name })}
                                          isNew={params.copy ?? !id}
                                          saver={saver}
                                          initialValues={initialValues}
                                          card={false}
                                          copy={params.copy}

  >
        <IngredientEditForm<IngredientInput> {...params} {...{ units, ingredientGroups, allergens }} />
    </KajaEditPage>
}
export default IngredientEdit

import React from 'react'
import KajaTablePage, { editLink, KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import { DirectionEnum, useAdmin_Ingredient_ListLazyQuery, useAdmin_Ingredient_Group_ListLazyQuery } from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'

const ingredintsGroupArray: Array<{text: string, value: string}> = []

type ListType = graphql.IngredientList['items'][number]
const IngredientList: React.FC = () => {
  const [fetch] = useAdmin_Ingredient_ListLazyQuery()
  const [fetchIngredientGroups] = useAdmin_Ingredient_Group_ListLazyQuery()
  const request: KajaTableRequestType<ListType> = async (params, sort, _filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => ({
            column: key,
            direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc
          }))
        }
      },
      fetchPolicy: 'network-only'
    })

    const IG = await fetchIngredientGroups({
      variables: {
        tableQuery: {
          limit: 100
        }
      },
      fetchPolicy: 'network-only'
    })
    if (ingredintsGroupArray.length === 0) {
      IG.data?.admin_ingredient_group_list.items.map((igItems) => (
        ingredintsGroupArray.push({
          text: igItems.name,
          value: igItems.name
        })
      ))
    }
    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_ingredient_list.items as ListType[],
      success: true,
      total: data.admin_ingredient_list.total
    }
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Alapanyag neve',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name',
      ...editLink(routes.ingredient.edit)
    },
    {
      title: 'Allergének',
      width: 140,
      dataIndex: 'allergens',
      ellipsis: true,
      render: (dom, entity: ListType) => {
        return <>
                    {entity.allergens.map(a => a.name).join(', ')}
                </>
      }
    },
    {
      title: 'Kategória',
      width: 140,
      dataIndex: 'category',
      ellipsis: true,
      // sorter: 'GroupId',
      render: (dom, entity: ListType) => {
        return <>
                    {entity.group.name}
                </>
      },
      filters: ingredintsGroupArray,
      onFilter: (value, record) => record.group.name.includes(value.toString())

    },
    // {
    //   title: 'Megjelenítési sorrend',
    //   dataIndex: 'displayOrder',
    //   sorter: 'DisplayOrder'
    //
    // },
    // {
    //   title: 'Elrejtési sorrend',
    //   dataIndex: 'ignoreOrder',
    //   sorter: 'IgnoreOrder'
    // },
    {
      title: 'Mindig elérhető',
      dataIndex: 'implicitlyAvailable',
      sorter: 'ImplicitlyAvailable',
      width: 100,
      valueEnum: {
        true: { text: 'Igen', status: 'Success' },
        false: { text: 'Nem', status: 'Error' }
      }
    },
    {
      title: 'Alapkészlet része',
      dataIndex: 'initialIngredient',
      sorter: 'InitialIngredient',
      width: 100,
      valueEnum: {
        true: { text: 'Igen', status: 'Success' },
        false: { text: 'Nem', status: 'Error' }
      }
    },
    {
      title: 'Látható',
      dataIndex: 'visible',
      sorter: 'Visible',
      width: 100,

      valueEnum: {
        true: { text: 'Igen', status: 'Success' },
        false: { text: 'Nem', status: 'Error' }
      }
    },
    {
      dataIndex: 'energy',
      title: 'Energia',
      width: 100,

      render: (dom, obj) => obj.calculatedStats.energy
        ? <><b>{obj.calculatedStats.energy}</b>&nbsp;
                <small>kcal</small></>
        : '-'
    },
    {
      dataIndex: 'carbon',
      title: 'Szénhidrát',
      width: 100,

      render: (dom, obj) => obj.calculatedStats.carbon
        ? <><b>{obj.calculatedStats.carbon}</b>&nbsp;
                <small>g/100g</small></>
        : '-'
    },
    {
      dataIndex: 'fat',
      title: 'Zsír',
      width: 100,

      render: (dom, obj) => obj.calculatedStats.fat
        ? <><b>{obj.calculatedStats.fat}</b>&nbsp;
                <small>g/100g</small></>
        : '-'
    },
    {
      dataIndex: 'protein',
      title: 'Fehérje',
      width: 100,

      render: (dom, obj) => obj.calculatedStats.protein
        ? <><b>{obj.calculatedStats.protein}</b>&nbsp;
                <small>g/100g</small></>
        : '-'
    }
  ]

  return (
  <KajaTablePage<ListType, { search: string }>
        columns={columns}
        labels="Alapanyagok"
        columnDefaults={{ displayOrder: { show: false }, ignoreOrder: { show: false }, implicitlyAvailable: { show: false }, initialIngredient: { show: false } }}
        label="Alapanyag"
        request={request}
        route={routes.ingredient}
    />)
}

export default IngredientList

/* eslint-disable no-template-curly-in-string */
export const huValidateMessages = {
  default: "Érvénytelen '${label}'",
  required: "'${label}' megadása kötelező",
  enum: "'${label}' az alábbiakba kell tartozzon: [${enum}]",
  whitespace: "'${label}' nem lehet üres",
  date: {
    format: "'${label}' érvénytelen dátum",
    parse: "'${label}' nem formázható dátummá",
    invalid: "'${label}' nem érvényes dátum"
  },
  types: {
    string: "'${label}' nem érvényes szöveg",
    method: "'${label}' nem érvényes metódus",
    array: "'${label}' nem érvényes tömb",
    object: "'${label}' nem érvényes objektum",
    number: "'${label}' nem érvényes szám",
    date: "'${label}' nem érvényes dátum",
    boolean: "'${label}' nem érvényes logikai",
    integer: "'${label}' nem érvényes szám",
    float: "'${label}' nem érvényes törtszám",
    regexp: "'${label}' nem érvényes regexp",
    email: "'${label}' nem érvényes email cím",
    url: "'${label}' nem érvényes url",
    hex: "'${label}' nem érvényes hexakód"
  },
  string: {
    len: "'${label}' pontosan ${len} karakter kell legyen",
    min: "'${label}' legalább ${min} karakter kell legyen",
    max: "'${label}' legfeljebb ${max} karakter kell legyen",
    range: "'${label}' ${min} és ${max} közötti karakter kell legyen"
  },
  number: {
    len: "'${label}' pontosan ${len} kell legyen",
    min: "'${label}' legalább ${min} kell legyen",
    max: "'${label}' legfeljebb ${max} kell legyen",
    range: "'${label}' ${min} és ${max} között kell legyen"
  },
  array: {
    len: "'${label}' pontosan ${len} hosszúnak kell lennie",
    min: "'${label}' legalább ${min} hosszúnak kell lennie",
    max: "'${label}' legfeljebb ${max} hosszúnak kell lennie",
    range: "'${label}' ${min} és ${max} közötti hosszúnak kell lennie"
  },
  pattern: {
    mismatch: "'${label}' érvénytelen minta: ${pattern}"
  }
}

import { Form, Space } from 'antd'
import { IngredientSelect } from '../RecipeEdit/IngredientInputs/IngredientSelect'
import React, { useCallback, useRef } from 'react'

type IngredientRowArgs = Omit<typeof Form.Item, 'useStatus'> & {
  name: number | string
  children?: React.ReactNode
  ingredients: Array<{ id: string, name: string, defaultRecipeUnitId: string }>
  add: () => void
}
const style = { margin: 0, maxWidth: '100%' }
const spaceStyle = { display: 'flex', maxWidth: '100%' }

export const IngredientToGroupInput: React.FC<IngredientRowArgs> = (
  { name, add, children, ingredients, ...restField }
) => {
  const form = Form.useFormInstance()
  const qnRef = useRef<HTMLInputElement>(null)
  // Megkeressük az adott összetevőt (keresés alapján pl.), és ha van olyan az előre lekértek között, akkor a form instance-szel beállítjuk a megfelelő értéket.
  // Az, hogy jó helyre teszi a form az adatokat onnan lehetünk biztosak, hogy a 'name' argumentum pl. ebben az esetben 'ingredientIds' lesz, és az előre
  // lekért ingredient adatok között csak az id-ját adjuk meg neki, hogy azt tegye be nekünk a tömbbe.
  const onIngredientSelect = useCallback((sel: unknown) => {
    const ingredient = ingredients.find((ing) => ing.id === sel)
    if (ingredient) {
      if (!form.getFieldValue([name, 'id'])) {
        form.setFieldValue([name, 'id'], ingredient.id)
      }
      qnRef.current?.focus()
      qnRef.current?.select()
    }
  }, [ingredients, name, qnRef, form])
  return <Space size={1} style={spaceStyle}>
        <Form.Item {...restField} name={name} noStyle style={style} rules={[{ required: true, message: 'Minden alapanyag megadása kötelező, a felesleges sorok pedig törlendőek.' }]}>
            <IngredientSelect minWidth={500} ingredients={ingredients} onSelect={onIngredientSelect}/>
        </Form.Item>
        {children}
    </Space>
}

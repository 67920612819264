import React from 'react'
import KajaTablePage, { KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import { DirectionEnum, useAdmin_Allergen_ListLazyQuery } from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'

type ListType = graphql.Admin_Allergen_ListQuery['admin_allergen_list']['items'][number]

const AllergenList: React.FC = () => {
  const [fetch] = useAdmin_Allergen_ListLazyQuery()
  const request: KajaTableRequestType<ListType> = async (params, sort, filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      }
    })

    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_allergen_list.items,
      success: true,
      total: data.admin_allergen_list.total
    }
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Allergén neve',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name',
      ellipsis: true
    },
    {
      title: 'Jelölés',
      dataIndex: 'slug',
      sorter: 'Slug',
      width: 100
    },
    {
      title: 'Leírás',
      dataIndex: 'description',
      ellipsis: true,
      sorter: 'Description'
    }
  ]

  return (<KajaTablePage<ListType, { search: string }>
        columns={columns}
        labels="Allergének"
        label="Allergén"
        request={request}
        withMetaColumns={false}
        route={routes.allergen}
    />)
}

export default AllergenList

/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
import { Alert, Button, Card, Col, DatePicker, Divider, Empty, Form, Input, Radio, Row } from 'antd'
import { IngredientRowInputs } from './IngredientInputs/IngredientRowInputs'
import * as Icons from '@ant-design/icons'
import React, { useMemo, useState } from 'react'
import { MediaDraggerInput } from '../../components/MediaDraggerInput'
import { Recipe } from './RecipeRendererInput/RenderRecipe'
import { RecipeTextEditor } from './RecipeRendererInput/RecipeTextEditor'
import { RenderRecipeTitle } from './RecipeRendererInput/RenderRecipeTitle'
import { RenderRecipeIngredients } from './RecipeRendererInput/RenderRecipeIngredients'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { RecipeEditorParameters } from './IngredientInputs/RecipeEditorParameters'
import { FormInterface } from './RecipeEdit'
import { GroupIngredientInput, RecipeEditHelpersQuery } from '../../generated/graphql'
import ElMover from '../../components/ElMover'
import lodash from 'lodash'
import ContentBlockInput from '../../components/ContentBlockInput/ContentBlockInput'
import { makeDynamicName } from './RecipeEditHelpers'
import { RecipeUsageHelpModal } from './RecipeRendererInput/RecipeUsageHelpModal'
import { IngredientValidationRule } from './RecipeRendererInput/IngredientValidationRule'
import { ContentBlockTalalasModja, ContentBlockTarolasModja } from '../../components/ContentBlockInput/ContentBlockTemplateMenu'
import { TagGroupsInput } from './TagGroupsInput'
import { HelpBtn } from '../../components/HelpBtn'
import locale from 'antd/es/date-picker/locale/hu_HU'
import TextArea from 'antd/lib/input/TextArea'
import { RenderRecipeWarnings } from './RecipeRendererInput/RenderRecipeWarnings'

export interface RecipeEditFormArgs {
  ingredients: RecipeEditHelpersQuery['admin_ingredient_list']['items']
  units: RecipeEditHelpersQuery['admin_unit_list']['items']
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
  maxParamCount?: number
  maxParamVariantCount?: number
}

function previewParamsValid (previewParam: { [p: string]: string }, fieldValue: GroupIngredientInput[]): boolean {
  return fieldValue?.every(val => previewParam[val.key] !== undefined) ?? true
}

const RecipeEditForm: React.FC<RecipeEditFormArgs> = ({
  ingredients,
  units,
  tagCategories,
  maxParamCount = 5,
  maxParamVariantCount = 10
}) => {
  const form: FormInstance<FormInterface> = Form.useFormInstance()
  const [previewParam, setPreviewParam] = useState<{ [key: string]: string }>({})
  const ingredientValidator = useMemo(() => IngredientValidationRule(ingredients), [ingredients])

  return <>
      <Row gutter={[16, 16]} align={'stretch'}>
          <Col sm={24} md={16}>
              <Card title="" style={{ height: '100%' }}>
                  <Form.Item label="Recept neve" name="name" rules={[{
                    required: true,
                    pattern: /^[\p{Alphabetic}\p{L}\p{Nd} ,"';+()-]*$/u,
                    message: 'Érvénytelen név'
                  }]}><Input/></Form.Item>
                  <Form.Item label={'Összetevők'}>
                      <Card>
                        <p style={{ paddingBottom: '5px' }}>Az összetevők mennyiségét <b>4 főre</b> kell megadni!</p>
                          <Form.List name="ingredients">
                              {(fields, { add, remove, move }, { errors }) => (
                                  <>
                                      {fields.map(({ key, name, ...restField }, index) => {
                                        return <IngredientRowInputs {...restField}
                                                                      add={() => add({ quantity: '1' })}
                                                                      parents={['ingredients']}
                                                                      key={key}
                                                                      name={name}
                                                                      ingredients={ingredients}
                                                                      units={units}
                                          >
                                              <ElMover index={index} name={name} list={fields} move={move}
                                                       remove={remove} min={0}/>
                                          </IngredientRowInputs>
                                      })}
                                    <HelpBtn>Recept alapanyagai. Minden alapanyagot <b>4</b> főre kell megadni.</HelpBtn>

                                      <Form.Item>
                                          <Button onClick={() => add({ quantity: '1' })} icon={<Icons.PlusOutlined/>}>
                                              Összetevő hozzáadása
                                          </Button>
                                          <Form.ErrorList errors={errors}/>
                                      </Form.Item>
                                  </>
                              )}
                          </Form.List>
                      </Card>
                  </Form.Item>

                  <RecipeEditorParameters maxParamVariantCount={maxParamVariantCount}
                                          maxParamCount={maxParamCount}
                                          ingredients={ingredients}
                                          units={units}
                                          tagCategories={tagCategories}
                  />

                  <Form.Item noStyle shouldUpdate>
                      {() => <>
                          <Row gutter={8} hidden={!form.getFieldValue('dynamicIngredients')?.length}>
                            <Col span={22}>
                        <Form.Item label={'Dinamikus név'} name='nameDynamic' rules={[{
                          pattern: /^[\p{Alphabetic}\p{L}\p{Nd} ,"';+=\r\n()-]*(\[[^[<>{}\]]+=[^[<>{}\]]+\][\p{Alphabetic}\p{L}\p{Nd} ,"';+=\r\n()-]*\[\/\][\p{Alphabetic}\p{L}\p{Nd} ,"';+=\r\n()-]*)*$/u,
                          required: !!form.getFieldValue('dynamicIngredients')?.length,
                          message: 'Érvénytelen név'
                        }]}>
                              <TextArea autoSize={true}></TextArea>
                        </Form.Item>
                            </Col>
                            <Col span={2}>

                              <Button size={'small'}
                                      type="text" icon={<Icons.SyncOutlined/>}
                                      onClick={() => {
                                        form.setFieldsValue({
                                          nameDynamic: makeDynamicName(form)
                                        })
                                        setPreviewParam({ ...previewParam })
                                      }}/>
                            </Col>
                          </Row>
                      </>}
                  </Form.Item>
              </Card>
          </Col>
          <Col sm={24} md={8}>
              <Card title="" style={{ height: '100%' }}>
                  <Form.Item dependencies={[['activeFrom']]} name="activeFrom" label="Aktív ekkortól" tooltip={<>Múltbeli dátum = recept publikus<br />Jövőbeli dátum = recept akkortól érhető el<br />Üres mező = recept nem publikus.</>}>
                  {/* <Input.Group compact> */}
                    <DatePicker allowClear={true} showTime locale={locale}/>
                    {/* <Button onClick={() => form.setFieldsValue({ activeFrom: new Date() })} type="primary">Publikál</Button> */}
                  {/* </Input.Group> */}
                  </Form.Item>

                  <Form.Item label="Elkészítési idő">
                  <Input.Group compact>
                      <Form.Item
                          name={['cookingTime', 'minutesMin']}
                          style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                      >
                          <Input placeholder="-Től" addonAfter="perc"/>
                      </Form.Item>

                      <span style={{
                        display: 'inline-block',
                        width: '24px',
                        lineHeight: '32px',
                        textAlign: 'center'
                      }}>&mdash;</span>
                      <Form.Item
                          name={['cookingTime', 'minutesMax']}
                          style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                      >
                          <Input placeholder="-Ig" addonAfter="perc"/>
                      </Form.Item>
                  </Input.Group>
              </Form.Item>

                  <TagGroupsInput tagCategories={tagCategories} name={'tagGroups'}/>

                  <Form.Item label="Média" name="media">
                      <MediaDraggerInput aspect={4 / 3}/>
                  </Form.Item>

                  <Form.Item label="Blokkok">
                      <ContentBlockInput name={['contentBlocks']} options={[ContentBlockTarolasModja, ContentBlockTalalasModja]}/>
                  </Form.Item>

              </Card>
          </Col>

      </Row>
      <Row gutter={[16, 16]} align={'stretch'} style={{ justifyContent: 'stretch', marginTop: 16 }}>

          <Col sm={24} md={12}>
              <Card style={{ height: '100%' }} title={'Recept'}
                    extra={<HelpBtn><RecipeUsageHelpModal/></HelpBtn>}>

                  <Form.Item noStyle
                             shouldUpdate={(prevVal, currVal) => !lodash.isEqual(prevVal.ingredients, currVal.ingredients) || !lodash.isEqual(prevVal.dynamicIngredients, currVal.dynamicIngrdients)}>
                      {() => <Form.Item label="Elkészítés" name="steps" noStyle
                                        rules={[{ required: true }, { validator: ingredientValidator }]}>
                          <RecipeTextEditor ingredients={ingredients}/>
                      </Form.Item>}
                  </Form.Item>
              </Card></Col><Col sm={24} md={12}><Card title={'Előnézet'} style={{ height: '100%' }}>

          <Form.Item noStyle
                     shouldUpdate={(prevVal, currVal) => !lodash.isEqual(prevVal.dynamicIngredients, currVal.dynamicIngrdients)}>
              {() => form.getFieldValue('dynamicIngredients')?.map((field: GroupIngredientInput) => {
                return <Form.Item label={field?.name} key={field.key}>
                      <Radio.Group buttonStyle="solid" onChange={(el) => {
                        setPreviewParam({ ...previewParam, [field.key]: el.target.value })
                      }}>
                          {field.subgroups?.filter(variant => (variant?.name)).map(variant => <Radio.Button
                              value={variant.key} key={variant.key}>{variant?.name}</Radio.Button>
                          )}
                      </Radio.Group>
                  </Form.Item>
              })}
          </Form.Item>

          <Form.Item shouldUpdate>
              {() => {
                if (!previewParamsValid(previewParam, form.getFieldsValue().dynamicIngredients)) {
                  return <Alert message="Paraméter nincs kiválasztva" type="error"/>
                }
                return <>
                      <RenderRecipeWarnings params={previewParam} paramlist={form.getFieldValue('dynamicIngredients')} nameDynamic={form.getFieldValue('nameDynamic')}/>
                      <RenderRecipeTitle params={previewParam}/>

                      <RenderRecipeIngredients ingredients={ingredients} units={units} params={previewParam}
                                               commonIngredients={form.getFieldsValue().ingredients}
                                               paramlist={form.getFieldValue('dynamicIngredients')}/>

                      <Divider/>

                      <Recipe recipe={form.getFieldsValue().steps}
                              params={previewParam}
                              empty={<Alert type="error"
                                            description={<Empty description="Hiányzó recept"/>}/>}/>
                  </>
              }}
          </Form.Item>

      </Card>
      </Col>

      </Row>
  </>
}

export default RecipeEditForm

import * as lodash from 'lodash'
import { Alert } from 'antd'
import React, { useMemo } from 'react'
import { GroupIngredientInput, RecipeIngredientInput, UnitListMiniQuery } from '../../../generated/graphql'
import { kv } from '../../../utils/Types'

interface RenderRecipeIngredientsType {
  params: kv
  commonIngredients: RecipeIngredientInput[]
  paramlist: GroupIngredientInput[]
  ingredients: Array<{ id: string, name: string, defaultRecipeUnitId: string }>
  units: UnitListMiniQuery['admin_unit_list']['items']
}

export function RenderRecipeIngredients ({
  params,
  commonIngredients,
  paramlist,
  ingredients,
  units
}: RenderRecipeIngredientsType) {
  const ingrCommon = commonIngredients ?? []
  const ingrFromParams = useMemo(() => paramlist?.map(param => param?.subgroups?.find(variant => variant.key === params[param.key])?.ingredients).flat(1) as RecipeIngredientInput[] ?? [], [paramlist, params])

  const ingrFiltered: RecipeIngredientInput[] = [...ingrCommon, ...ingrFromParams].filter(ing => ing?.ingredientId && ing.quantity && ing.unitId)

  const ingrGroupped = lodash.groupBy(ingrFiltered, el => `${el.ingredientId}_${el.unitId}`)

  const myIngredients: RecipeIngredientInput[] = lodash.map(ingrGroupped, grp => ({
    ingredientId: grp[0].ingredientId,
    unitId: grp[0].unitId,
    // @ts-expect-error b shown as int, but can be string.
    quantity: grp.map(el => el.quantity).reduce((acc, val) => parseFloat(acc) + parseFloat(val), 0),
    note: grp.map(el => el.note).join(', ')
  }))

  const list = <ul>{myIngredients.map((el, index) =>
        <li key={index}>
            {el.quantity}&nbsp;{units.find(x => x.id === el.unitId)?.name}&nbsp;
            <b>{ingredients.find(x => x.id === el.ingredientId)?.name}</b>
            {el.note && <i>&nbsp;{el.note}</i>}
        </li>)}</ul>

  return myIngredients.length ? list : <Alert message="Hiányzó összetevők!" type="error"/>
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
import { Button, Card, Col, Form, Input, Row } from 'antd'
import { IngredientToGroupInput } from './IngredientToGroupInput'
import * as Icons from '@ant-design/icons'
import React from 'react'
import { RecipeEditHelpersQuery } from '../../generated/graphql'
import ElMover from '../../components/ElMover'
import { HelpBtn } from '../../components/HelpBtn'
// Meg kell adni a bemeneti interface-t, a paramétereket. Itt az összetevők listáját adjuk oda, amit egy szinttel feljebb lekértünk
export interface SubstitutionGroupEditFormArgs {
  ingredients: RecipeEditHelpersQuery['admin_ingredient_list']['items']
}

const SubstitutionGroupEditForm: React.FC<SubstitutionGroupEditFormArgs> = ({ ingredients }) => {
  // Fontos, hogy az egy szinttel feljebb lévő komponensben (edit) a mutationinputoknak megfelelően nevezzük el a form elemeket, listákat (pl. name, vagy listánál: ingredientIds)
  // Ha valamilyen mélységben van egy adat, akkor legyen parent is, így találja meg a form, hogy a belső state-jébe hová mentse az adatokat
  return (
    <>
      <Row gutter={[16, 16]} align={'stretch'}>
        <Col sm={24} md={16}>
          <Card title="" style={{ height: '100%' }}>
            <Form.Item
              label="Helyettesítési csoport neve"
              name="name"
              rules={[
                {
                  required: true,
                  pattern: /^[\p{Alphabetic}\p{L}\p{Nd} ,"';+()-]*$/u,
                  message: 'Érvénytelen név'
                }
              ]}>
              <Input />
            </Form.Item>
            <Form.Item label={'Ősszetevők a csoportban'}>
              <Card>
                <Form.List name="ingredientIds">
                  {(fields, { add, remove, move }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <IngredientToGroupInput
                            {...restField}
                            add={() => add()}
                            key={key}
                            name={name}
                            ingredients={ingredients}>
                            <ElMover
                              index={index}
                              name={name}
                              list={fields}
                              move={move}
                              remove={remove}
                              min={0}
                            />
                          </IngredientToGroupInput>
                        )
                      })}
                      <HelpBtn>A helyettesítési csoport összetevői</HelpBtn>

                      <Form.Item>
                        <Button onClick={() => add()} icon={<Icons.PlusOutlined />}>
                          Összetevő hozzáadása
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Card>
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default SubstitutionGroupEditForm

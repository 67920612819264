import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import React from 'react'
import { Link } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getBreadcrumbName (route: Route, params: any): string | null {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return route.breadcrumbName?.replace(new RegExp(':('.concat(Object.keys(params).join('|'), ')'), 'g'), (replacement, key) => params[key] || replacement)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RenderBreadcrumb: (route: Route, params: any, routes: Route[], paths: string[]) => React.ReactNode = (route, params, routes, paths) => {
  const isLastItem = routes.indexOf(route) === routes.length - 1
  const name = getBreadcrumbName(route, params)
  return isLastItem ? <span>{name}</span> : <Link to={'/' + paths.join('/')}>{name}</Link>
}

import React, { useCallback } from 'react'
import { Button, Modal } from 'antd'
import * as Icons from '@ant-design/icons'

export const HelpBtn: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const cb = useCallback(() =>
    Modal.info({
      focusTriggerAfterClose: false,
      title: 'Segítség',
      maskClosable: true,
      closable: true,
      content: children
    }), [children])
  return <Button type={'text'} icon={<Icons.QuestionCircleOutlined/>} style={{ float: 'right' }} onClick={cb}/>
}

import React, { useContext, useMemo, useState } from 'react'
import { Layout, Menu, Tooltip, Typography } from 'antd'
import 'antd/dist/antd.css'
import R from '../resources/strings.json'
import { TransitionGroup } from 'react-transition-group'
import { SubRoutes } from '../logic/navigation/routes'
import { menu } from '../logic/navigation/menu'
import * as Icons from '@ant-design/icons'
import { UserContext } from './context/UserContext'
import { useLocation } from 'react-router-dom'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { MenuItemGroupType } from 'antd/es/menu/hooks/useItems'

const AppLayout: React.FC = () => {
  const { state } = useContext(UserContext)
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const folder: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> = { className: 'trigger', style: { float: 'right' }, onClick: () => setCollapsed(!collapsed) }
  const location = useLocation()

  const [selectedKeys, openKeys] = useMemo(() => {
    const res: string[] = []
    const open: string[] = []
    menu.forEach((el: ItemType) => {
      if (el === null) { return }
      if (el?.key !== undefined && location.pathname.startsWith(`${el.key}`)) {
        res.push(`${el.key}`)
      }
      (el as MenuItemGroupType)?.children?.forEach(child => {
        if (el?.key !== undefined && child?.key !== undefined && location.pathname.startsWith(`${child.key}`)) {
          res.push(`${child.key}`)
          open.push(`${el.key}`)
        }
      })
    })
    return [res, open]
  }, [location])

  return <>
      <Layout.Sider width={200} trigger={null} collapsible collapsed={collapsed}
                    style={{ position: 'sticky', top: 0, float: 'left', height: '100vh', overflow: 'auto' }}>
          <div className="logo" style={{ color: 'white' }}>
              {collapsed ? <>&nbsp;</> : R.title}
              <span {...folder}> {collapsed ? <Icons.MenuUnfoldOutlined/> : <Icons.MenuFoldOutlined/>}</span>
          </div>
          <Typography.Text ellipsis={true} style={{ padding: 5, color: 'white', display: 'block' }}>
              {collapsed ? '' : 'Belépve:'} <Tooltip placement="bottomRight" title={state}>{state}</Tooltip>
          </Typography.Text>
          <Menu theme="dark" selectedKeys={selectedKeys} defaultOpenKeys={openKeys}
                mode="inline"
                style={{ borderRight: 0 }}
                items={menu}
          />
      </Layout.Sider>
      <Layout style={{ minHeight: '100vh' }}>
        <Layout.Content className="site-layout-background">
            <TransitionGroup component={null}>
                <SubRoutes/>
            </TransitionGroup>
        </Layout.Content>
    </Layout>
  </>
}

export default AppLayout

import { IngredientListMiniQuery, UnitListMiniQuery } from '../../../generated/graphql'
import React from 'react'
import { Button, Form, Space } from 'antd'
import { IngredientRowInputs } from './IngredientRowInputs'
import ElMover from '../../../components/ElMover'
import { PlusOutlined } from '@ant-design/icons'
import { HelpBtn } from '../../../components/HelpBtn'

interface SubgroupBlockIngredientsType{
  nameA: number | string
  nameB: number | string
  ingredients: IngredientListMiniQuery['admin_ingredient_list']['items']
  units: UnitListMiniQuery['admin_unit_list']['items']
}

export const SubgroupBlockIngredients: React.FC<SubgroupBlockIngredientsType> = ({
  nameA,
  nameB,
  ingredients,
  units
}) =>
  <Form.List name={[nameB, 'ingredients']}>
    {(ingredientList, { add: addIngredient, remove: removeIngredient, move }, { errors }) =>
      <>
        <p style={{ paddingBottom: '5px' }}>Az összetevők mennyiségét <b>4 főre</b> kell megadni!</p>
        {ingredientList.map(({
          key,
          name: name3,
          ...restField
        }, iIndex) =>
          <Space style={{ display: 'flex' }}
                 align="baseline"
                 key={key}>
            <IngredientRowInputs
              add={() => addIngredient({ quantity: '1' })}
              parents={['dynamicIngredients', nameA, 'subgroups', nameB, 'ingredients']}
              ingredients={ingredients}
              units={units}
              name={name3} {...restField} />
            <ElMover index={iIndex} name={name3}
                     list={ingredients}
                     move={move}
                     remove={removeIngredient} min={0}/>
          </Space>)}
        <HelpBtn>A megadott alapanyagokkal kibővül az alaprecept, ha a paraméterek közül ez az opció aktív. Minden
          alapanyagot <b>4</b> főre kell megadni.</HelpBtn>
        <Button type="dashed" onClick={() => addIngredient({ quantity: '1' })} icon={<PlusOutlined/>}>
          Összetevő hozzáadása</Button>

        <Form.ErrorList errors={errors}/>
      </>}

  </Form.List>

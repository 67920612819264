// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
import {jsonHighlighting} from "../components/recipeLang/highlight"
export const parser = LRParser.deserialize({
  version: 14,
  states: "%pOQOPOOO]OQO'#CbObOPO'#C_OpOQO'#CgOOOO'#Co'#CoQOOOOOOuOQO'#CpOzOQO,58|OOOO'#Cl'#ClO!POPO'#CuO!_OPO,58yO!dOQO'#ChO!iOSO,59RO!tOWO,59[O]OQO1G.hOOOO-E6j-E6jOOOO'#Cd'#CdOOOO1G.e1G.eO#SOQO,59SOOOO'#Cm'#CmO#XOSO1G.mOOOO1G.m1G.mO#dOPO1G.mOOOO1G.v1G.vO#iOQO7+$SOOOO1G.n1G.nOOOO-E6k-E6kOOOO7+$X7+$XO#qOPO7+$XO]OQO'#CkO#vOQO<<GnOOOO<<Gn<<GnOOOO<<Gs<<GsOOOO,59V,59VOOOO-E6i-E6iOOOOAN=YAN=Y",
  stateData: "$T~OTPOYRO^SO~OeUO~OTPOYRO^SOQiP~OjZO~Of]O~OV^O~OTPOYRO^SOQiX~OQ`O~OebO~OXeO]fOkcO~OggOVdaSdahda~OjiO~OXkO]lOkcO~OXkO~OSoOhmO~OXpO~OSsOhmO~O^g]^~",
  goto: "!rjPPPkPPpPuPPkxPP{!R!XP!_!fPPPP!oVSOQXVQOQXRaYR[RQnhRrnQXQR_XQd[RjdQTOTWQXQVPQh^RqmRYQ",
  nodeNames: "⚠ RecipeText [/] Condition ] [ ConditionBegin = ConditionEnd } { Ingredient IngredientId IngredientName Text",
  maxTerm: 27,
  nodeProps: [
    ["openedBy", 4,"[",8,"ConditionBegin",9,"{"],
    ["closedBy", 5,"]",6,"ConditionEnd",10,"}"]
  ],
  propSources: [jsonHighlighting],
  skippedNodes: [0],
  repeatNodeCount: 3,
  tokenData: ".S~RkOX!vXY%qYZ%qZ]!v]^%q^p!vpq%qqs!vst&ut|!v|}'y}!O(l!O!Q!v!Q!R)_!R![*c![!_!v!_!`+m!`!}!v!}#O,`#O#P!v#P#Q-c#Q#R$t#R#o!v#o#p-p#p#q!v#q#r-}#r~!v]#P]^PgW]SO|!v|!O#x!O!_!v!_!`#x!`!}!v!}#O$i#O#P!v#P#Q$i#Q#R$t#R#o!v#o#p$i#p#q!v#r~!vT$PW^P]SO!}#x!}#O$i#O#P#x#P#R$i#R#o#x#o#p$i#p#q#x#r~#xS$nQ]SO#q$i#r~$i[${[gW]SO|$t|!O$i!O!_$t!_!`$i!`!}$t!}#O$i#O#P$t#P#Q$i#Q#o$t#o#p$i#p#q$t#r~$t~%|]^PgW]Sk~O|!v|!O#x!O!_!v!_!`#x!`!}!v!}#O$i#O#P!v#P#Q$i#Q#R$t#R#o!v#o#p$i#p#q!v#r~!v_'Q]jQ^PgW]SO|!v|!O#x!O!_!v!_!`#x!`!}!v!}#O$i#O#P!v#P#Q$i#Q#R$t#R#o!v#o#p$i#p#q!v#r~!v_(SWhY^P]SO!}#x!}#O$i#O#P#x#P#R$i#R#o#x#o#p$i#p#q#x#r~#xV(uWfQ^P]SO!}#x!}#O$i#O#P#x#P#R$i#R#o#x#o#p$i#p#q#x#r~#x_)j]eQ^PgW]SO|!v|!O#x!O!_!v!_!`#x!`!}!v!}#O$i#O#P!v#P#Q$i#Q#R$t#R#o!v#o#p$i#p#q!v#r~!v_*n_eQ^PgW]SO|!v|!O#x!O!Q!v!Q![*c![!_!v!_!`#x!`!}!v!}#O$i#O#P!v#P#Q$i#Q#R$t#R#o!v#o#p$i#p#q!v#r~!v_+vWVY^P]SO!}#x!}#O$i#O#P#x#P#R$i#R#o#x#o#p$i#p#q#x#r~#x~,gST~]SO!P$i!P!Q,s!Q#q$i#r~$i~,xS]SO#P$i#P#Q-U#Q#q$i#r~$i~-]QQ~]SO#q$i#r~$i~-jQS~]SO#q$i#r~$iT-wQYP]SO#q$i#r~$i~.SOX~",
  tokenizers: [0, 1, 2, 3],
  topRules: {"RecipeText":[0,1]},
  tokenPrec: 138,
  termNames: {"0":"⚠","1":"@top","2":"\"[/]\"","3":"Condition","4":"\"]\"","5":"\"[\"","6":"ConditionBegin","7":"\"=\"","8":"ConditionEnd","9":"\"}\"","10":"\"{\"","11":"Ingredient","12":"IngredientId","13":"IngredientName","14":"Text","15":"(\",\" conditionPart)+","16":"expression+","17":"whitespace+","18":"␄","19":"expression","20":"conditionPart","21":"int","22":"\"-\"","23":"limitedText","24":"\",\"","25":"conditionData","26":"\"#\"","27":"whitespace"}
})

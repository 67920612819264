import React, { FunctionComponent } from 'react'
import KajaTablePage, { editLink, KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import { DirectionEnum, useAdmin_Recipe_Suggestion_ListLazyQuery } from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'

type ListType = graphql.RecipeSuggestionList['items'][number]

const RecipeSuggestionList: FunctionComponent = (props) => {
  const [fetch] = useAdmin_Recipe_Suggestion_ListLazyQuery()

  const request: KajaTableRequestType<ListType> = async (params, sort, filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      },
      fetchPolicy: 'network-only'
    })

    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_recipe_suggestion_list.items as ListType[],
      success: true,
      total: data.admin_recipe_suggestion_list.total
    }
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Ajánlás neve',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name',
      ...editLink(routes.recipe_suggestion.edit)
    }
    // {
    //     title: 'calculatedStats',
    //     dataIndex: 'calculatedStats',
    // },
    // {
    //     title: 'displayOrder',
    //     dataIndex: 'displayOrder',
    //     sorter:'DisplayOrder',
    //
    // },
    // {
    //     title: 'ignoreOrder',
    //     dataIndex: 'ignoreOrder',
    //     sorter:'IgnoreOrder',
    //
    // },
    // // {
    // //     title: 'pricePerGram',
    // //     dataIndex: 'pricePerGram',
    // // },
    // // {
    // //     title: 'priceSpread',
    // //     dataIndex: 'priceSpread',
    // // },
    // {
    //     title: 'implicitlyAvailable',
    //     dataIndex: 'implicitlyAvailable',
    //     valueEnum: {
    //         true: { text: 'Igen', status: 'Success' },
    //         false: { text: 'Nem', status: 'Error' },
    //     },
    // },
    // {
    //     title: 'visible',
    //     dataIndex: 'visible',
    //
    //     valueEnum: {
    //         true: { text: 'Igen', status: 'Success' },
    //         false: { text: 'Nem', status: 'Error' },
    //     },
    // },
    // {
    //     title: 'Tags',
    //     dataIndex: 'tags',
    //     align: 'left',
    //     render: (node, el: ListType) => <>
    //         {el.tagGroups.filter(t=>t.tags.length>0).map(tg=><>
    //             {tg.tagGroup.name}: {tg.tags.map(t=><>{t.name}</>)} </>)}</>,
    // },
  ]

  return (<KajaTablePage<ListType>
        columns={columns}
        labels="Receptajánlók"
        label="Receptajánló"
        request={request}
        route={routes.recipe_suggestion}
    />)
}

export default RecipeSuggestionList

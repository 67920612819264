import * as Icons from '@ant-design/icons'
import { Button, Card, Form, Input, InputRef, Layout, message } from 'antd'
import React, { useCallback, useEffect, useRef } from 'react'
import useLogin, { LoginForm } from './useLogin'
import { Callbacks } from 'rc-field-form/lib/interface'
import { huValidateMessages } from '../../resources/validation'
import { Content } from 'antd/es/layout/layout'
import R from '../../resources/strings.json'

const Login: React.FC = () => {
  const { onLoginPress, isLoadingLogin } = useLogin()
  const [form] = Form.useForm()
  const emailRef = useRef<InputRef>(null)
  useEffect(() => emailRef.current?.focus())

  const onFinishFail = useCallback<NonNullable<Callbacks<LoginForm>['onFinishFailed']>>(err => {
    void message.error(err.errorFields[0].errors.map(err => <>{err}<br/></>))
  }, [form])

  return <>
      <Layout className="layout" style={{ minHeight: '100vh' }}>
          <Content style={{ padding: '0 50px', marginTop: 200, minWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
            <Card title={R.title + ' Admin - Belépés'}>
                <Form<LoginForm> validateTrigger={'onBlur'} colon={false} requiredMark={false} form={form} labelCol={{ span: 'label' }} onFinish={onLoginPress} onFinishFailed={onFinishFail} validateMessages={huValidateMessages}>
                        <Form.Item label="Email cím" name={'email'} rules={[{ required: true, type: 'email' }]}>
                            <Input prefix={<Icons.UserOutlined placeholder='Email' />} placeholder="Email cím" ref={emailRef}/>
                        </Form.Item>
                        <Form.Item label="Jelszó" name={'password'} rules={[{ required: true }]}>
                            <Input prefix={<Icons.LockOutlined/>} type='password' placeholder='Jelszó'/>
                        </Form.Item>
                        <Button htmlType="submit" type='primary' style={{ width: '100%' }} loading={isLoadingLogin}>
                            Bejelentkezés
                        </Button>
                    </Form>
            </Card>
          </Content>
      </Layout>
  </>
}

export default Login

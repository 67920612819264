import { useContext, useState } from 'react'
import { message } from 'antd'
import { UserContext } from '../../components/context/UserContext'
import { useAdmin_LoginMutation } from '../../generated/graphql'

export interface LoginForm{
  email: string
  password: string
}

const useLogin = () => {
  const [isLoadingLogin, setIsLoadingLogin] = useState<boolean>(false)
  const { login } = useContext(UserContext)
  const [admin_login] = useAdmin_LoginMutation()

  const onLoginPress = (input: LoginForm) => {
    void (async () => {
      setIsLoadingLogin(true)
      try {
        const { data, errors } = await admin_login({ variables: { input } })
        if (errors) {
          errors.forEach(({ message: msg, locations, path }) => {
            void message.error(`Hiba: ${msg}`)
            console.error('[GraphQL error]', { msg, locations, path })
          })
        } else if (data) {
          if (data.admin_login.__typename === 'LoginError') {
            void message.error(`Hiba: ${data.admin_login.error}`)
          } else if (data.admin_login.__typename === 'AdminLoginSuccess') {
            void message.success('Sikeres bejelentkezés')
            login(data.admin_login.adminUser.email)
          } else {
            console.error(data)
            void message.error('FATAL error')
          }
        }
      } finally {
        setIsLoadingLogin(false)
      }
    })()
  }

  return { onLoginPress, isLoadingLogin }
}

export default useLogin

import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Alert, Breadcrumb, Button, ConfigProvider, PageHeader, Tooltip, Typography } from 'antd'
import * as Icons from '@ant-design/icons'
import { ActionType, ColumnsState, ProColumns } from '@ant-design/pro-table'
import { ProSchema, ProTable, ProTableProps, RequestData } from '@ant-design/pro-components'
import { Link } from 'react-router-dom'
import { ApolloError } from '@apollo/client'
import En_US from 'antd/es/locale/en_US'
import { ParamsType } from '@ant-design/pro-provider'
import { SortOrder } from 'antd/lib/table/interface'
import { CrudRoute } from '../utils/Types'
import KajaPage from './KajaPage'
import R from '../resources/strings.json'
import moment from 'moment'
import 'moment/locale/hu'
import { client } from '../graphql/client/graphql-client'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import { RenderBreadcrumb } from './RenderBreadcrumb'
import { AdminUser } from '../generated/graphql'

moment.locale('hu')

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const editLink = function <T>(route: string) {
  const render: ProSchema<T>['render'] = (dom: ReactNode, record: unknown) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    const id: string = (record as any)?.id as string
    return <Typography.Text style={{ textAlign: 'left', display: 'block' }} ellipsis={true}>
      <Link to={route.replace(':id', id)}>
      <Tooltip title={dom}>
            {dom}
      </Tooltip>
        </Link></Typography.Text>
  }
  return { render, ellipsis: false }
}

export type KajaTableRequestType<T, U = unknown> =
    (params: U & {
      pageSize?: number
      current?: number
      keyword?: string
      search?: string
    }, sort: Record<string, SortOrder>, filter: Record<string, Array<string | number> | null>) => Promise<Partial<RequestData<T>>>

type Props<DataType, Params, ValueType> = Omit<ProTableProps<DataType, Params, ValueType>,
'loading' | 'rowKey' | 'columnsState' | 'options' | 'search' | 'toolbar'> & {
  columns: Array<ProColumns<DataType>>
  label: string
  labels: string
  route: CrudRoute
  deleteFn?: DeleteType<DataType>
  error?: ApolloError | undefined | unknown | string
  columnDefaults?: Record<string, ColumnsState>
  withMetaColumns?: boolean
  withActionColumn?: boolean
}

export type DeleteType<DataType> = (el: DataType, done: () => void) => unknown

type ParamsType2 = ParamsType & { search: string }

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-explicit-any
const KajaTablePage = function <DataType extends Record<string, any>, Params extends ParamsType2 = ParamsType2, ValueType = 'text'>
({
  label,
  labels,
  columns,
  route,
  request,
  withMetaColumns,
  withActionColumn,
  deleteFn,
  error,
  params,
  columnDefaults,
  ...rest
}: Props<DataType, Params, ValueType>) {
  const [loading, setLoading] = useState<boolean>(false)

  const actionRef = useRef<ActionType>()
  const routes: Route[] = useMemo(() =>
    [
      {
        path: '/',
        breadcrumbName: R.title
      },
      {
        path: route.edit ?? '',
        breadcrumbName: labels
      }
    ], [route, labels])
  const breadcrumbEl = useMemo(() => <Breadcrumb routes={routes} itemRender={RenderBreadcrumb}/>, [routes])

  const extra = useMemo(() => {
    if (route.create) {
      return [
                <Link key={0} to={route.create}><Button type="dashed" icon={<Icons.PlusOutlined/>}>
                    Új {label}
                </Button></Link>
      ]
    }
    return []
  }, [route.create, label])
  const deleteCb = useCallback((record: DataType) => () => {
    return deleteFn?.(record, () => {
      void (async () => {
        await client.clearStore()
        await actionRef.current?.reload()
      })()
    })
  }, [actionRef, actionRef.current, deleteFn, client])
  const columnsAll = useMemo(() => {
    const columnsBefore: Array<ProColumns<DataType, ValueType>> = [
      {
        title: 'id',
        width: 64,
        dataIndex: 'id',
        sorter: 'ID',
        hideInSearch: true
      },
      {
        title: 'Keresés',
        dataIndex: 'search',
        hideInSearch: false,
        formItemProps: {
        },
        hideInTable: true
      }
    ]

    const columnsAfter: Array<ProColumns<DataType, ValueType>> = withMetaColumns !== false
      ? [
          {
            title: 'Létrehozva',
            width: 80,
            dataIndex: 'createdAt',
            sorter: 'CreatedAt',
            valueType: 'fromNow'
          },
          {
            title: 'Módosítva',
            width: 80,
            dataIndex: 'updatedAt',
            sorter: 'UpdatedAt',
            valueType: 'fromNow'
          },
          {
            title: 'Létrehozó',
            width: 80,
            dataIndex: 'createdBy',
            sorter: 'CreatedBy',
            render: (text: ReactNode, record) => <>{record.createdByUser ? (record.createdByUser as AdminUser)?.name : '-'}</>
          },
          {
            title: 'Módosító',
            width: 80,
            dataIndex: 'updatedBy',
            sorter: 'UpdatedBy',
            render: (text: ReactNode, record) => <>{record.updatedByUser ? (record.updatedByUser as AdminUser)?.name : '-'}</>
          }
        ]
      : []

    const columnsAction = withActionColumn !== false && (route.copy !== undefined || deleteFn !== undefined)
      ? [{
          title: 'Műveletek',
          key: 'operations',
          hideInSearch: true,
          width: 100,
          render: (text: ReactNode, record: DataType) => [
        <div key={'actions'}>
          {route.copy && record.id &&
              <Link to={route.copy.replace(':id', record.id as string)} key="copy">
                <Button icon={<Icons.CopyFilled/>} size={'small'} type={'ghost'}></Button>
              </Link>}
          {deleteFn &&
              <Button onClick={deleteCb(record)} key="delete" icon={<Icons.DeleteFilled/>} size={'small'}
                      danger type={'ghost'}></Button>}
        </div>
          ]
        }]
      : []
    return [...columnsBefore, ...columns.map(el => { return { ...el, hideInSearch: true } }), ...columnsAfter.map(el => { return { ...el, hideInSearch: true } }), ...columnsAction]
  }, [columns, route, route?.copy, deleteFn, deleteCb])

  const [reqError, setReqError] = useState<undefined | string>(undefined)

  const myRequest = useCallback<NonNullable<ProTableProps<DataType, Params, ValueType>['request']>>(
    async (params, sort, filter) => {
      setLoading(true)
      if (request === undefined) {
        throw new Error('No request')
      }
      try {
        const res = await request(params, sort, filter)
        setReqError(undefined)

        return res
      } catch (e: unknown) {
        console.error(e)
        if (e instanceof ApolloError) {
          if (e.networkError) {
            setReqError('Hálózati hiba, kérlek próbáld újra később')
          } else {
            setReqError(`${e.message} - ${e.graphQLErrors.map(err => `${err.message} (${err.path?.join('-') ?? ''})`).join(', ')}`)
          }
        } else if (e instanceof TypeError || (e as {message: string})?.message !== undefined) {
          setReqError(`${(e as TypeError).message}`)
        } else {
          setReqError('Valami hiba történt.')
        }
        return {
          data: undefined,
          success: false
        }
      } finally {
        setLoading(false)
      }
    }, [request, setLoading, setReqError]
  )

  return <KajaPage title={labels}><PageHeader className="site-page-header" title={labels}
                                 breadcrumb={ breadcrumbEl} extra={extra}>
        <ConfigProvider locale={En_US}>
            <>
                {error && error instanceof ApolloError &&
                  <Alert message="Hiba" description={error.message} type="error" showIcon
                         action={<Button size="small" type="text" onClick={() => {
                           void actionRef.current?.reload()
                         }}>Újratöltés</Button>}/>}
                {typeof error === 'string' &&
                  <Alert message="Hiba" description={error} type="error" showIcon
                         action={<Button size="small" type="text" onClick={() => {
                           void actionRef.current?.reload()
                         }}>Újratöltés</Button>}/>}
                {typeof reqError === 'string' &&
                  <Alert message="Hiba" description={reqError} type="error" showIcon
                         action={<Button size="small" type="text" onClick={() => {
                           void actionRef.current?.reload()
                         }}>Újratöltés</Button>}/>}

                <ProTable<DataType, Params, ValueType>
                    {...rest}
                    request={myRequest}
                    loading={loading ?? error !== undefined}
                    actionRef={actionRef}
                    params={params}
                    rowKey="id"
                    columns={columnsAll}

                    form={{
                      syncToUrl: true
                    }}

                    scroll={{ x: 1000 }}
                    columnsState={
                        {
                          defaultValue: {
                            id: { show: false, fixed: 'left' },
                            createdBy: { show: false },
                            updatedBy: { show: false },
                            createdAt: { show: false },
                            updatedAt: { show: false },

                            operations: {
                              show: route.copy !== undefined || deleteFn !== undefined,
                              disable: true,
                              fixed: 'right'
                            },
                            ...columnDefaults
                          },
                          persistenceKey: `TABLE-${label}`,
                          persistenceType: 'localStorage'
                        }
                    }
                    options={{ fullScreen: true, reload: true, setting: true }}
                    search={{
                      searchText: 'Keres',
                      resetText: 'Alaphelyzetbe',
                      split: true
                    }}
                />
            </>
            </ConfigProvider>
    </PageHeader></KajaPage>
}

export default KajaTablePage

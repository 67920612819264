import React from 'react'
import KajaEditPage from '../../components/KajaEditPage'
import { useParams } from 'react-router-dom'
import { routes } from '../../logic/navigation/routes'
import TagGroupEditForm from './TagGroupEditForm'
import KajaErrorPage from '../../components/KajaErrorPage'
import KajaLoadingPage from '../../components/KajaLoadingPage'
import { TagGroupInput, useAdmin_Tag_Group_GetQuery, useAdmin_Tag_Group_UpsertMutation } from '../../generated/graphql'

interface TagGroupEditArgs {
  copy?: true
}

const getTexts: ({
  params,
  id
}: { params: { copy?: boolean }, id?: string }) => { label: string, labels: string } = ({
  params,
  id
}) => ({
  label: params.copy && id ? 'Kategóriacsoport ' + id + ' másolása' : (id ? 'Kategóriacsoport ' + id + ' szerkesztése' : 'Új kategóriacsoport'),
  labels: 'Kategóriacsoportok'
})

const TagGroupEdit: React.FC<TagGroupEditArgs> = (params) => {
  const { id } = useParams()
  const texts = getTexts({ params, id })
  const [saver] = useAdmin_Tag_Group_UpsertMutation()

  const { loading, error, data } = useAdmin_Tag_Group_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })

  if (error !== undefined) {
    return <KajaErrorPage label={texts.label} message="Hiba"
                              description={error?.message}
                              details={error?.graphQLErrors}

        />
  }
  if ((id !== undefined && loading)) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.tag_group}/>
  }
  const initialValues: TagGroupInput = {
    categorizable: data?.admin_tag_group_get.categorizable ?? false,
    description: data?.admin_tag_group_get.description ?? '',
    filterable: data?.admin_tag_group_get.filterable ?? false,
    id: data?.admin_tag_group_get.id ?? '',
    multiselect: data?.admin_tag_group_get.multiselect ?? false,
    name: data?.admin_tag_group_get.name ?? ''
  }

  return <KajaEditPage<TagGroupInput> route={routes.tag_group} {...getTexts({ params, id: initialValues.name })}
                                        isNew={params.copy ?? !id}
                                        saver={saver}
                                        copy={params.copy}

                                        initialValues={initialValues}
                                        card={false}
    >
        <TagGroupEditForm<TagGroupInput> {...params} />
    </KajaEditPage>
}
export default TagGroupEdit

import React, { useState } from 'react'
import Login from '../../screens/Auth/Login'

const LS_KEY = 'login_admin'

interface IUserContext {
  state: string | null
  login: (token: string) => void
  logout: () => void
}
export const UserContext = React.createContext<IUserContext>({
  state: null,
  login: (token: string) => { return undefined },
  logout: () => { return undefined }
})

export const getToken = (): string | null => {
  return localStorage.getItem(LS_KEY)
}

export let handleLogout: null | (() => void) = null
UserContext.displayName = 'UserContext'

export const ContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const loginToken = localStorage.getItem(LS_KEY)

  const [state, setState] = useState<string | null>(loginToken)

  const login = (token: string) => {
    localStorage.setItem(LS_KEY, token)
    setState(token)
  }

  const logout = () => {
    localStorage.removeItem(LS_KEY)
    setState(null)
  }
  handleLogout = logout

  return <UserContext.Provider value={{ state, login, logout }} >
    {state ? children : <Login/>}
        </UserContext.Provider>
}

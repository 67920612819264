import { Card, SelectProps, Row, Col, Select, Form, Button, Space } from 'antd'
import React, { useMemo, useState } from 'react'
import ElMover from '../../../components/ElMover'
import { Admin_Recipe_GetQuery, UnitListMiniQuery, useAdmin_Substitution_Group_ListQuery } from '../../../generated/graphql'
import { SubstitutionRowInputs } from './SubstitutionRowInputs'
import { HelpBtn } from '../../../components/HelpBtn'
import * as Icons from '@ant-design/icons'
import { InternalNamePath } from 'antd/lib/form/interface'

export type SubstitutionsArray = Admin_Recipe_GetQuery['admin_recipe_get']['ingredients'][number]['substitutions']

interface SubstitutionFormArgs {
  ingredientId: string
  unitId: string
  quantity: number
  show: boolean
  formName: number | string
  ingredients: Array<{ id: string, name: string, defaultRecipeUnitId: string }>
  units: UnitListMiniQuery['admin_unit_list']['items']
  parents: InternalNamePath
}
export const SubstitutionForm: React.FC<SubstitutionFormArgs> = ({ ingredientId, unitId, quantity, show, formName, ingredients, units, parents }) => {
  const { data, error } = useAdmin_Substitution_Group_ListQuery({
    variables: { forIngredient: ingredientId },
    skip: !show
  })
  const form = Form.useFormInstance()
  const substitutionGroups = useMemo(() => data?.admin_substitution_group_list.items ?? [], [data])
  const [groupMultiSelect, setGroupMultiSelect] = useState<string[]>([])
  const getOptions = () => {
    const options: SelectProps['options'] = []
    for (const group of substitutionGroups) {
      options.push({ label: group.name, value: group.id })
    }
    return options
  }
  const handleSelect = (value: string) => {
    setGroupMultiSelect((selected: string[]) => {
      const newsSelected = [...selected]
      newsSelected.push(value)
      return newsSelected
    })
    const ingredients = []
    const subs = [...form.getFieldValue([...parents, formName, 'substitutions']) as SubstitutionsArray]
    for (const grp of substitutionGroups) {
      if (value === grp.id) {
        for (const ing of grp.ingredients) {
          const subsItem = subs.find((si) => si.ingredientId === ing.id)
          if (ing.id !== ingredientId && !subsItem) {
            ingredients.push({ ingredientId: ing.id, quantity, unitId })
          }
        }
      }
    }
    subs.push(...ingredients)
    form.setFieldValue([...parents, formName, 'substitutions'], subs)
  }
  const handleDeselect = (value: string) => {
    setGroupMultiSelect((selected: string[]) => {
      const newsSelected = selected.filter((val: string) => val !== value)
      return newsSelected
    })
    const deleteIngredientIds: string[] = []
    for (const grp of substitutionGroups) {
      if (value === grp.id) {
        for (const ing of grp.ingredients) {
          deleteIngredientIds.push(ing.id)
        }
      }
    }
    const subs = [...form.getFieldValue([...parents, formName, 'substitutions']) as SubstitutionsArray]
    const newSubs: SubstitutionsArray = []
    for (const subIng of subs) {
      if (!deleteIngredientIds.includes(subIng.ingredientId)) {
        newSubs.push(subIng)
      }
    }
    form.setFieldValue([...parents, formName, 'substitutions'], newSubs)
  }
  const removeAllSubstitutions = () => {
    setGroupMultiSelect([])
    form.setFieldValue([...parents, formName, 'substitutions'], [])
  }
  if (!show || error) {
    return null
  }
  return (
    <Card>
      <HelpBtn>Helyettesítő alapanyagok. Minden alapanyagot <b>4</b> főre kell megadni.</HelpBtn>
      <Row>
        <Col span={12}>
          <Form.Item required={false}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Válassz csoportokat!"
              value={groupMultiSelect}
              onSelect={handleSelect}
              onDeselect={handleDeselect}
              options={getOptions()}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.List name={[formName, 'substitutions']}>
          {(fields, { add, remove, move }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => {
                return <SubstitutionRowInputs {...restField}
                                              add={() => add({ quantity: '1' })}
                                              parents={[formName, 'substitutions']}
                                              key={key}
                                              name={name}
                                              ingredients={ingredients}
                                              units={units}
                  >
                      <ElMover index={index} name={name} list={fields} move={move}
                                remove={remove} min={0}/>
                  </SubstitutionRowInputs>
              })}

              <Form.Item>
                <Space>
                  <Button onClick={() => add({ quantity, unitId })} icon={<Icons.PlusOutlined/>}>
                      Helyettesítő Összetevő hozzáadása
                  </Button>
                  <Button onClick={removeAllSubstitutions} icon={<Icons.DeleteFilled/>}>
                      Helyettesítő Összetevők törlése
                  </Button>
                </Space>
                <Form.ErrorList errors={errors}/>
              </Form.Item>
            </>
          )}
          </Form.List>
        </Col>
      </Row>
    </Card>
  )
}

import { EditorState, Line } from '@codemirror/state'

export const getLines = (state: EditorState): { startLine: Line, endLine: Line, from: number, to: number } => {
  const from = state.selection.main.from ?? 0
  const to = state.selection.main.to ?? 0
  const startLine = state.doc.lineAt(from)
  const endLine = state.doc.lineAt(to)

  return { startLine, endLine, from, to }
}

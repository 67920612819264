import React, { useMemo, useState } from 'react'
import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Space } from 'antd'
import * as Icons from '@ant-design/icons'
import { DefaultOptionType } from 'rc-select/lib/Select'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import ContentBlockInput from '../../components/ContentBlockInput/ContentBlockInput'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { AllergenListMiniQuery, IngredientGroupListMiniQuery, UnitListMiniQuery } from '../../generated/graphql'
import { LSelect } from '../../components/LSelect'
import { MediaDraggerInput } from '../../components/MediaDraggerInput'
import { ContentBlockSeason, ContentBlockTarolasModja } from '../../components/ContentBlockInput/ContentBlockTemplateMenu'

const required = { required: true, rules: [{ required: true }] }

// function Units (unitsRaw: UnitsType[]) {
//   return <>{unitsRaw.map(({ key, value }, index) => <LSelect.Option key={index}
//                                                                    value={key}>{value}</LSelect.Option>)}</>
// }

export interface IngredientEditFormArgs {
  copy?: boolean
  units: UnitListMiniQuery['admin_unit_list']['items']
  ingredientGroups: IngredientGroupListMiniQuery['admin_ingredient_group_list']['items']
  allergens: AllergenListMiniQuery['admin_allergen_list']['items']
}

function IngredientEditForm<InputType> (params: IngredientEditFormArgs) {
  const [addOption, setAddOption] = useState(null)
  const form: FormInstance<InputType> = useFormInstance()
  const optionsAllergens = useMemo(() => params.allergens.map(el => ({
    label: el.name,
    value: el.id
  })), [params.allergens])
  const optionsUnits = useMemo(() => params.units.map(el => ({ label: el.name, value: el.id })), [params.units])
  return <>
        <Row gutter={[16, 16]} align={'stretch'}
             style={{ alignItems: 'stretch', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Col sm={24}>
                <Card style={{ height: '100%' }}>

                    <Form.Item required={true} rules={[{ required: true }, { max: 100 }]} name="name" label="Név"><Input/></Form.Item>
                    <Form.Item name="groupId" label="Alapanyag csoport" {...required}>
                        <LSelect showSearch={true} options={params.ingredientGroups.map(el => {
                          return { label: el.name, value: el.id }
                        })}/></Form.Item>
                    <Form.Item name="allergens" label="Allergének">
                        <LSelect mode="multiple" allowClear={true}
                                 options={optionsAllergens}/>
                    </Form.Item>
                    <Divider/>
                    <Form.Item label="Szövegblokkok">
                        <ContentBlockInput name={['contentBlocks']} options={[ContentBlockTarolasModja, ContentBlockSeason] }/>
                    </Form.Item>

                    <Form.Item name="media" label="Média">
                        <MediaDraggerInput aspect={4 / 3}/>
                    </Form.Item>
                </Card>
            </Col>
            <Col sm={24} md={8}>
                <Card style={{ height: '100%' }} title="Mértékegységek">
                    <Form.Item name="defaultRecipeUnitId" {...required} label="Mértékegység recepteknél">
                        <LSelect showSearch={true} options={optionsUnits}/>
                    </Form.Item>
                    <Form.Item name="defaultShoppingUnitId" {...required} label="Mértékegység vásárlásnál">
                        <LSelect showSearch={true} options={optionsUnits}/>
                    </Form.Item>

                    <Form.Item label="Mértékegység készlet vásárlásnál" name="availableShoppingUnitIds">
                        <LSelect mode={'multiple'} showSearch={true} allowClear={true}
                                 options={optionsUnits}/>
                    </Form.Item>
                    <Form.Item label="Mértékegység grammosítása">

                        <Form.List name="unitQuantities">
                            {(fields, { add, remove }) => {
                              return <>
                                    {fields.map(({ key, name, ...restField }) => {
                                      const unitId: number = +form.getFieldValue(['unitQuantities', name, 'unitId'])
                                      const unit = params.units.find(el => +el.id === unitId)
                                      return <Space key={key} align="baseline">
                                            <Form.Item name={[name, 'unitId']} hidden={true}><Input/></Form.Item>
                                            <Form.Item {...restField} name={[name, 'asGram']}
                                                       label={`1 ${unit?.name ?? ''} = `}
                                                       rules={[{
                                                         required: true,
                                                         message: `'${unit?.name ?? ''}' értéke hiányzik`
                                                       }]}>
                                                <InputNumber min={0} addonAfter={'g'}/>
                                            </Form.Item>
                                            <Icons.MinusCircleOutlined onClick={() => remove(name)}/>
                                        </Space>
                                    })}
                                    <LSelect placeholder={'Hozzáadás'} value={addOption}
                                             onSelect={(value: string, option: DefaultOptionType) => {
                                               // eslint-disable-next-line
                                                 add({unitId: value})
                                               setAddOption(null)
                                               // eslint-disable-next-line
                                                 setTimeout(() => form.getFieldInstance(['unitQuantities', fields.length, 'quantity'])?.focus(), 0)
                                             }}
                                             options={params.units
                                             // eslint-disable-next-line
                                                 .filter(el => (form.getFieldValue('unitQuantities')?.filter((uq: any) => uq.unitId === el.id).length === 0))
                                               .filter(el => el.asGram === undefined || el.asGram === null)
                                               .map(el => ({ label: el.name, value: el.id }))}/>
                                </>
                            }}
                        </Form.List>

                    </Form.Item>
                </Card>
            </Col><Col sm={24} md={8}>
            <Card style={{ height: '100%' }} title="Stats">
                <Form.Item name={['calculatedStats', 'energy']} label={'Energia'}>
                    <InputNumber min={0} max={100} addonAfter={'kcal'}/></Form.Item>
                <Form.Item name={['calculatedStats', 'carbon']} label={'Szénhidrát'}>
                    <InputNumber min={0} max={100} addonAfter={'g/100g'}/></Form.Item>
                <Form.Item name={['calculatedStats', 'fat']} label={'Zsír'}>
                    <InputNumber min={0} max={100} addonAfter={'g/100g'}/></Form.Item>
                <Form.Item name={['calculatedStats', 'protein']} label={'Fehérje'}>
                    <InputNumber min={0} max={100} addonAfter={'g/100g'}/></Form.Item>
                {/* <Form.Item name={["calculatedStats","other"]} label={"other"}> */}
                {/*    <InputNumber min{0} step=0.1 addonAfter={"g/100g"}/></Form.Item> */}
            </Card></Col>
            <Col sm={24} md={8}>
                <Card style={{ height: '100%' }} title="Tulajdonságok">

                    <Form.Item hidden={true} label="Grammonkénti ár" name="pricePerGram"
                               tooltip="beviteli mező. A grammhoz adható meg egy forintos érték."><InputNumber
                        disabled={true} min={0}
                        addonAfter={'Ft'}/></Form.Item>
                    <Form.Item hidden={true} label="Lehetséges áreltérés" name="priceSpread"
                               tooltip="százalékos érték adható meg."><InputNumber min={0} disabled={true}
                                                                                   addonAfter={'%'}/></Form.Item>
                    <Form.Item label="Nem fogy el" name="implicitlyAvailable"
                               tooltip="A készlet kezelést nem érinti." valuePropName="checked"><Checkbox value={true}/></Form.Item>
                    <Form.Item label="Látható" name="visible" valuePropName="checked"><Checkbox
                        value={true}/></Form.Item>
                    <Form.Item label="Alapkészlet része" name="initialIngredient" valuePropName="checked"><Checkbox
                        value={false}/></Form.Item>
                </Card></Col></Row>
    </>
}

export default IngredientEditForm

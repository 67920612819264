import React from 'react'
import ReactDOM from 'react-dom/client'
import './style/index.scss'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import AppLayout from './components/AppLayout'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ContextProvider as UserContextProvider } from './components/context/UserContext'
import { client } from './graphql/client/graphql-client'
import 'moment/locale/hu'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: +(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0),
  debug: process.env.REACT_APP_SENTRY_DEBUG === 'true'
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApolloProvider client={client}>
                <UserContextProvider>
                    <AppLayout/>
                </UserContextProvider>
            </ApolloProvider>
        </BrowserRouter>
    </React.StrictMode>
)

reportWebVitals()

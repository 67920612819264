import { RecipeEditHelpersQuery } from '../../../generated/graphql'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import React from 'react'
import { Button, Dropdown, Form, Menu } from 'antd'
import { HelpBtn } from '../../../components/HelpBtn'
import { TagGroupInputEl } from '../TagGroupsInput'
import * as Icons from '@ant-design/icons'

interface SubgroupBlockTagsType{
  nameA: number | string
  nameB: number | string
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
  menu: ItemType[]

}

export const SubgroupBlockTags: React.FC<SubgroupBlockTagsType> = ({
  nameA,
  nameB,
  tagCategories,
  menu

}) => {
  return <Form.List name={[nameB, 'tagGroups']}>
    {(tagList, { add: addTag, remove: removeTag, move }) => {
      return <>

        {tagList.map(({ key, name: name4, ...restField }, iIndex) => {
          return <div key={key }>
            <TagGroupInputEl
              props={{ style: { display: 'inline-block', minWidth: 300 } }}
              index={name4} tagCategories={tagCategories} nameArr={['dynamicIngredients', nameA, 'subgroups', nameB, 'tagGroups']}/>
            <Button type="text" size="small" icon={<Icons.MinusCircleOutlined/>} tabIndex={-1} onClick={() => removeTag(name4)}/>
          </div>
        }) }

        {/* eslint-disable-next-line */}
        <Dropdown overlay={<Menu onClick={menuInfo => addTag({ tagGroupId: tagCategories[(menuInfo as any).key].id })}
                                 items={menu}/>} placement="bottom">
          <Button type="dashed"> Címke hozzáadása</Button>
        </Dropdown>
        <HelpBtn>A felsorolt címkék felülírják az alaprecept címkéit. Ha többször is szerel egy címketípus, akkor mindig a legutóbbi felülírást vesszük figyelembe.</HelpBtn>
      </>
    } }

  </Form.List>
}

import React from 'react'
import KajaEditPage from '../../components/KajaEditPage'
import { routes } from '../../logic/navigation/routes'
import { useParams } from 'react-router-dom'
import KajaErrorPage from '../../components/KajaErrorPage'
import KajaLoadingPage from '../../components/KajaLoadingPage'
import IngredientGroupEditForm from '../IngredientGroupEdit/IngredientGroupEditForm'
import {
  IngredientGroupInput,
  useAdmin_Ingredient_Group_GetQuery,
  useAdmin_Ingredient_Group_UpsertMutation,
  useAllergenListMiniLazyQuery
} from '../../generated/graphql'

interface IngredientGroupEditArgs {
  copy?: true
}

const getTexts: ({
  params,
  id
}: { params: { copy?: boolean }, id?: string }) => { label: string, labels: string } = ({
  params,
  id
}) => ({
  label: params.copy && id ? 'Alapanyag csoport ' + id + ' másolása' : (id ? 'Alapanyag csoport ' + id + ' szerkesztése' : 'Új alapanyag csoport'),
  labels: 'Alapanyag csoportok'
})

const IngredientGroupEdit: React.FC<IngredientGroupEditArgs> = params => {
  const { id } = useParams()
  const texts = getTexts({ params, id })
  const [saver] = useAdmin_Ingredient_Group_UpsertMutation()

  const { loading, error, data } = useAdmin_Ingredient_Group_GetQuery({
    variables: { id: id as string },
    errorPolicy: 'all',
    skip: id === undefined,
    fetchPolicy: 'network-only'
  })

  const [fetchAllergens, aRes] = useAllergenListMiniLazyQuery({ errorPolicy: 'all' })

  if (aRes.error !== undefined || error !== undefined) {
    return <KajaErrorPage label={texts.label} message="Hiba"
                              description={aRes.error?.message ?? error?.message}
                              details={aRes.error?.graphQLErrors ?? error?.graphQLErrors}

        />
  }
  if (!aRes.loading && !aRes.data) {
    void fetchAllergens()
  }

  if (id !== undefined && loading) {
    return <KajaLoadingPage label={texts.label} labels={texts.labels} route={routes.ingredient_group}/>
  }
  const initialValues: IngredientGroupInput = {
    id: data?.admin_ingredient_group_get.id ?? '',
    displayOrder: data?.admin_ingredient_group_get.displayOrder ?? 0,
    media: data?.admin_ingredient_group_get.media ?? [],
    name: data?.admin_ingredient_group_get.name ?? ''
  }

  return <KajaEditPage<IngredientGroupInput> route={routes.ingredient_group} {...getTexts({ params, id: initialValues.name })}
                                               isNew={params.copy ?? !id}
                                               saver={saver}
                                               initialValues={initialValues}
                                               card={false}
                                             copy={params.copy}

  >
        <IngredientGroupEditForm<IngredientGroupInput> {...params} />
    </KajaEditPage>
}
export default IngredientGroupEdit

import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { FormInterface } from './RecipeEdit'
import { sanitizeRecipeItem } from './RecipeRendererInput/utils'
import { GroupIngredientInput } from '../../generated/graphql'

export function makeDynamicName (form: FormInstance): string {
  const name: string = (form.getFieldValue('name') as string) ?? ''
  const parameters = (form.getFieldValue('dynamicIngredients') ?? []) as FormInterface['dynamicIngredients']

  const dynamicParts = parameters.map(field =>
    field
      ?.subgroups
      ?.filter(variant => (variant?.name))
      ?.map(variant => `[${field.key}-${sanitizeRecipeItem(field.name)}=${variant.key}-${sanitizeRecipeItem(variant.name)}]${sanitizeRecipeItem(variant.name)}[/]`)
      ?.join(' ')).join(' ')
  return `${dynamicParts} ${name}`
}

export function getDynamicComponents (parameters: GroupIngredientInput[]): string[] {
  return parameters.map(field =>
    field
      ?.subgroups
      ?.filter(variant => (variant?.name))
      ?.map(variant => `[${field.key}-${sanitizeRecipeItem(field.name)}=${variant.key}-${sanitizeRecipeItem(variant.name)}]`) ?? []).flat(1)
}

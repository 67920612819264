import React, { useMemo } from 'react'
import { SelectProps } from 'rc-select/lib/Select'
import { LSelect } from '../../../components/LSelect'

type IngredientSelectArgs = Omit<SelectProps, 'mode' | 'showSearch' | 'options'> & {
  ingredients: Array<{ id: string, name: string, defaultRecipeUnitId: string }>
  minWidth?: number
}

export function IngredientSelect ({ ingredients, minWidth = 200, ...rest }: IngredientSelectArgs) {
  const ingredientOptions = useMemo(() => ingredients.map((ingredient) => ({
    label: ingredient.name,
    value: ingredient.id
  })), [ingredients])

  return <LSelect {...rest} showSearch={true} style={{ minWidth }} options={ingredientOptions}/>
}

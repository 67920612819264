import { MenuProps } from 'antd'
import React from 'react'
import * as Icons from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { routes } from './routes'

export const menu: NonNullable<MenuProps['items']> = [
  { key: routes.recipe.key, icon: <Icons.HomeOutlined/>, label: <Link to={routes.recipe.list}>Receptek</Link> },
  {
    key: 'left-menu2',
    icon: <Icons.CoffeeOutlined/>,
    label: 'Rendszer',
    children: [
      { key: routes.ingredient.key, label: <Link to={routes.ingredient.list}>Alapanyagok</Link> },
      // {key: routes.recipe_suggestion.key', label: <Link to={routes.recipe_suggestion.list}>Recept ajánló</Link>, disabled: true},
      { key: routes.ingredient_group.key, label: <Link to={routes.ingredient_group.list}>Alapanyag fajták</Link> },
      { key: routes.allergen.key, label: <Link to={routes.allergen.list}>Allergének</Link> },
      // {key: routes.intolerance_group.key, label: <Link to={routes.intolerance_group.list}>Intoleranciák</Link>, disabled: true},
      // { key: routes.intolerance_group.key, label: <Link to={routes.intolerance_group.list}>Intoleranciák</Link> },
      { key: routes.unit.key, label: <Link to={routes.unit.list}>Mértékegységek</Link> },
      { key: routes.tag_group.key, label: <Link to={routes.tag_group.list}>Címkecsoportok</Link> },
      { key: routes.tag.key, label: <Link to={routes.tag.list}>Címkék</Link> },
      { key: routes.substitution_group.key, label: <Link to={routes.substitution_group.list}>Helyettesítési csoportok</Link> }
    ]
  }, {
    key: 'div-1',
    type: 'divider'
  }, {
    key: routes.logout,
    icon: <Icons.LogoutOutlined/>,
    label: <Link to={routes.logout}>Kilépés</Link>
  }
]

import { ValidatorRule } from 'rc-field-form/lib/interface'
import { RecipeEditHelpersQuery } from '../../../generated/graphql'

type ruleType = (ingredients: RecipeEditHelpersQuery['admin_ingredient_list']['items']) => ValidatorRule['validator']
// eslint-disable-next-line @typescript-eslint/require-await
export const IngredientValidationRule: ruleType = (ingredients) => async (rule, value: string) => {
  const matches = value.matchAll(/\{#?(\d*)#?([^}]*)\}/g)
  for (const match of Array.from(matches)) {
    if (!match[1]) {
      throw new Error('Jelöletlen összetevő, kell a sorszám hozzá: ' + (match[0]))
    }
    const relIng = ingredients.find(ing => +ing.id === +match[1])
    if (!relIng) {
      throw new Error('Ismeretlen összetevő: ' + (match[0]))
    }
  }
  const badIngredientBrackets = value.replace(/\{#?(\d*)#?([^}]*)}/g, '').match(/[{}].*/)
  if (badIngredientBrackets) {
    throw new Error('Érvénytelen, vagy felesleges jelölő: ' + (badIngredientBrackets[0]))
  }
}

import { Modal, Upload, UploadFile, message } from 'antd'
import { RcFile } from 'antd/es/upload'
import { InboxOutlined } from '@ant-design/icons'
import React, { useCallback, useState } from 'react'
import { UploadProps } from 'antd/lib/upload/interface'
import { MediaInput } from '../generated/graphql'
import ImgCrop from 'antd-img-crop'

const convertBase64: ((file: MyFile) => Promise< string>) = async (file: MyFile) => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      if (typeof fileReader?.result === 'string') {
        resolve(fileReader?.result)
      } else {
        reject(new Error('bad type'))
      }
    }
    fileReader.onerror = reject
  })
}

interface MediaDraggerInputProps {
  aspect: number
  rules?: Rules
  value?: MediaInput[]
  onChange?: (value: MediaInput[]) => void
}

export interface Rules {
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  maxHeight?: number
  minSizeKb?: number
  maxSizeKb?: number
}

export const defaultRules: Rules = {
  minWidth: 100,
  minHeight: 100,
  minSizeKb: 1,
  maxSizeKb: 20000
}

interface MyFile extends RcFile {
  url?: string
  base64?: string
}

const validateImage = async (file: MyFile, rules: Rules) => await new Promise<boolean>((resolve, reject) => {
  const sizeKb = file.size / 1000
  if (rules.minSizeKb !== undefined && sizeKb < rules.minSizeKb) {
    void message.error(`A kiválasztott kép túl kicsi! (min: ${rules.minSizeKb} kb, adott: ${sizeKb} kb)`)
    resolve(false)
  }
  if (rules.maxSizeKb !== undefined && sizeKb > rules.maxSizeKb) {
    void message.error(`A kiválasztott kép túl nagy! (max: ${rules.maxSizeKb} kb, adott: ${sizeKb} kb)`)
    resolve(false)
  }

  const image = new Image()
  image.onerror = function (err) {
    void message.error('A kiválasztott kép érvénytelen!')
    console.error(err)
    resolve(false)
  }

  image.onload = function () {
    if (
      (rules.minWidth !== undefined && image.width < rules.minWidth) ||
      (rules.maxWidth !== undefined && image.width > rules.maxWidth) ||
      (rules.minHeight !== undefined && image.height < rules.minHeight) ||
      (rules.maxHeight !== undefined && image.height > rules.maxHeight)
    ) {
      void message.error(`A kiválasztott dimenziója nem megfelelő!
           (${rules.minWidth ?? '*'} < ${image.width} < ${rules.maxWidth ?? '*'}  X  ${rules.minHeight ?? '*'} < ${image.height} < ${rules.maxHeight ?? '*'} )`)
      resolve(false)
    }
    if (image.width / image.height < 0.2 || image.height / image.width < 0.2) {
      void message.error(`A kiválasztott kép képaránya nem megfelelő! (${image.width / image.height})`)
      resolve(false)
    }

    // success
    resolve(true)
  }
  image.src = (window.URL || window.webkitURL).createObjectURL(file)
})

export const MediaDraggerInput: React.FC<MediaDraggerInputProps> = ({ aspect, rules = defaultRules, value = [], onChange, ...rest }) => {
  const [preview, onPreview] = useState<MyFile | null>(null)
  const [fileList, setFileList] = useState<UploadFile[]>(value.map((el: MediaInput): UploadFile => {
    return {
      uid: el.uid,
      url: el.url as string,
      name: el.name,
      size: el.size,
      type: el.type
    }
  }))
  const propsUpload: UploadProps<MyFile> = {
    onRemove: useCallback((file: UploadFile<MyFile>) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      onChange?.(newFileList as MediaInput[])

      return setFileList(newFileList)
    }, [fileList, setFileList]),
    beforeUpload: useCallback(async (file: MyFile) => {
      if (await validateImage(file, rules)) {
        file.base64 = await convertBase64(file)
        const newFileList = [...fileList, file]
        setFileList(newFileList)

        const listFiles: MediaInput[] = []
        for (const el of newFileList) {
          listFiles.push({
            uid: el.uid,
            base64: el && !el.url ? await convertBase64(el as MyFile) : undefined,
            url: el?.url,
            name: el.name,
            size: el.size ?? 0,
            type: el.type ?? 'n/a'
          })
        }
        onChange?.(listFiles)
      }
      return false
    }, [fileList, setFileList, onChange]),
    fileList,
    onPreview: (file) => onPreview(file as MyFile),
    multiple: true,
    listType: 'picture',
    accept: '.png,.jpg,.jpeg,.webp',
    iconRender: (elTmp) => {
      const el = elTmp as MyFile
      return <img src={el?.base64 ?? el?.url}/>
    },
    ...rest
  }
  return <>
    <ImgCrop aspect={aspect} quality={0.95} zoom={true} maxZoom={4} beforeCrop={async (file) => { return await validateImage(file as MyFile, rules) }}
             modalTitle={'Kép szerkesztése-méretezése'} modalWidth={800} modalCancel={'Mégsem'} >

      <Upload.Dragger {...propsUpload}>
            <p className="ant-upload-drag-icon"><InboxOutlined/></p>
            <p className="ant-upload-text">Feltöltéshez húzd ide a fájlt!</p>
        </Upload.Dragger>
    </ImgCrop>

    <Modal open={preview !== null} title={preview?.name} footer={null} onCancel={() => onPreview(null)}>
          <img style={{ width: '100%' }} src={preview?.base64 ?? preview?.url} alt={preview?.name}/>
    </Modal>
  </>
}

import { Alert } from 'antd'
import React from 'react'
import { kv } from '../../../utils/Types'
import { GroupIngredientInput } from '../../../generated/graphql'
import { getDynamicComponents } from '../RecipeEditHelpers'

interface RenderRecipeWarningsType {
  params: kv
  paramlist: GroupIngredientInput[]
  nameDynamic: string | undefined
}

export const RenderRecipeWarnings: React.FC<RenderRecipeWarningsType> = ({ params, paramlist, nameDynamic }) => {
  const paramlistsWithImages = paramlist.filter(el => el.subgroups?.some(sg => (sg.media?.length ?? 0) > 0))
  const missingDynamicParts: string[] = typeof nameDynamic === 'string' ? getDynamicComponents(paramlist).filter(part => !nameDynamic.includes(part)) : []
  return <>
    {paramlistsWithImages.length > 1 && <Alert message="Kép megadva több paraméterhez is" type="warning" description={<>
      Egy paraméteren belül érdemes minden paraméter-variánshoz megadni a képeket, de több paraméterhez nem érdemes képeket feltölteni, mert ezek felül fogják írni egymást: <b>{paramlistsWithImages.map(el => el.name ?? '???').join(', ')}</b></>}/>}
    {(paramlistsWithImages.length ?? 0) === 1 && !paramlistsWithImages[0].subgroups?.every(sg => (sg.media?.length ?? 0) > 0) && <Alert type="warning" message="Kép megadva egy paraméternél nem minden paraméter-variánshoz."/>}
    {missingDynamicParts.length > 0 && <Alert type="warning" message="Dinamikus név nincs kitöltve vagy hiányos" description={missingDynamicParts.join(', ')}/>}
    </>
}

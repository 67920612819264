import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { GroupSublist, IngredientListMiniQuery, RecipeEditHelpersQuery, UnitListMiniQuery } from '../../../generated/graphql'
import { FormListFieldData } from 'antd/es/form'
import React from 'react'
import { Badge, Card, Form, Input, Tabs } from 'antd'
import { SubgroupBlockIngredients } from './SubgroupBlockIngredients'
import { SubgroupBlockTags } from './SubgroupBlockTags'
import { HelpBtn } from '../../../components/HelpBtn'
import { MediaDraggerInput } from '../../../components/MediaDraggerInput'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface SubgroupBlockType{
  nameA: number | string
  nameB: number | string
  menu: ItemType[]
  groupMover: JSX.Element
  groupTitle: JSX.Element
  ingredients: IngredientListMiniQuery['admin_ingredient_list']['items']
  units: UnitListMiniQuery['admin_unit_list']['items']
  tagCategories: RecipeEditHelpersQuery['admin_tag_group_list']['items']
  restField: Pick<FormListFieldData, 'fieldKey'>
}

export const SubgroupBlock: React.FC<SubgroupBlockType> = ({ groupTitle, groupMover, restField, units, nameB, nameA, ingredients, tagCategories, menu }) => {
  const form = useFormInstance()

  const val = form.getFieldValue(['dynamicIngredients', nameA, 'subgroups', nameB]) as GroupSublist

  return <div className="card-container"><Card style={{ marginBottom: 24 }}>
    <Form.Item{...restField} name={[nameB, 'key']}
              hidden={true}><Input/></Form.Item>

    <Tabs tabBarExtraContent={{ left: groupTitle, right: groupMover }} items={[
      {
        label: <Badge color="blue" size="small" count={val?.ingredients?.length || 0}>Alapanyagok</Badge>,
        key: 'item-1',
        forceRender: true,
        children: <SubgroupBlockIngredients
          nameA={nameA}
          nameB={nameB}
          ingredients={ingredients}
          units={units}
        />
      },
      {
        label: <Badge color="blue" size="small" count={val?.tagGroups?.length || 0}>Címkék</Badge>,
        key: 'item-2',
        forceRender: true,
        children: <SubgroupBlockTags
          nameA={nameA}
          nameB={nameB}
          tagCategories={tagCategories}
          menu={menu}
        />
      },
      {
        label: <Badge color="blue" size="small" count={val?.media?.length || 0}>Képek</Badge>,
        key: 'item-3',
        forceRender: true,
        children: <>
          <HelpBtn>Ha meg van adva, akkor az alaprecept képét felülírja, és ezt mutatja helyette. Ha több variációnál is meg van adva, akkor az utolsó képet mutatja csak.</HelpBtn>
          <Form.Item label="Média" name={[nameB, 'media']}>

            <MediaDraggerInput aspect={4 / 3}/>
          </Form.Item>
        </>
      }
    ]} />

  </Card></div>
}

import React from 'react'
import KajaTablePage, { KajaTableRequestType } from '../../components/KajaTablePage'
import { ProColumns } from '@ant-design/pro-table'
import * as graphql from '../../generated/graphql'
import { DirectionEnum, useAdmin_Unit_ListLazyQuery } from '../../generated/graphql'
import { routes } from '../../logic/navigation/routes'
import lodash from 'lodash'

type ListType = graphql.Admin_Unit_ListQuery['admin_unit_list']['items'][number]

const UnitList: React.FC = (props) => {
  const [fetch] = useAdmin_Unit_ListLazyQuery()

  const request: KajaTableRequestType<ListType> = async (params, sort, filter) => {
    const { data, error } = await fetch({
      variables: {
        tableQuery: {
          page: params.current ? params.current - 1 : 0,
          limit: params.pageSize,
          search: params.search,
          sort: lodash.map(sort, (el, key) => {
            return { column: key, direction: el === 'ascend' ? DirectionEnum.Asc : DirectionEnum.Desc }
          })
        }
      },
      fetchPolicy: 'network-only'
    })

    if (error !== undefined) throw error
    if (data === undefined) throw new Error('No data')

    return {
      data: data.admin_unit_list.items,
      success: true,
      total: data.admin_unit_list.total
    }
  }
  const columns: Array<ProColumns<ListType>> = [
    {
      title: 'Mértékegység neve',
      width: 140,
      dataIndex: 'name',
      sorter: 'Name'
    },
    {
      title: 'Grammként',
      dataIndex: 'asGram',
      align: 'right',
      sorter: 'AsGram',
      width: 100,
      render: (dom: React.ReactNode, entity: ListType) => entity.asGram ? <><b>{entity.asGram}</b> g</> : '—'
    }
  ]

  return (<KajaTablePage<ListType, { search: string }>
        columns={columns}
        labels="Mértékegységek"
        label="Mértékegység"
        request={request}
        withMetaColumns={false}
        route={routes.unit}
    />)
}

export default UnitList
